import { ref } from "@vue/composition-api";
import { PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";

import { FinancialListRows } from "@/helpers/estimateNoOfListRows";

export function useFinancialOutstandingBalancesStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    paying: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      status: SelectOption[];
      events: SelectOption[];
      unassignedEvent: any;
      profile: string;
      event: string;
      date: string;
      balance: string;
      minDate: string;
      maxDate: string;
      minBalance: string;
      maxBalance: string;
      t: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
      totalAmount: string;
    };
    selectedIds: any;
    selectedToPay: any;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
  }>({
    isLoading: true,
    paying: false,
    skip: 0,
    take: FinancialListRows("OutstandingBalances"),
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "1",
      direction: context.root.$route.query.direction || "2"
    },
    filter: {
      status: [
        {
          id: "1",
          text: "Registered"
        },
        // {
        //   id: "2",
        //   text: "Paid"
        // },
        {
          id: "3",
          text: "Payment Plan"
        },
        {
          id: "4",
          text: "Undefined"
        }
      ],
      events: [],
      unassignedEvent: null,
      profile: context.root.$route.query.profile || "",
      event: context.root.$route.query.event || "",
      date:
        (context.root.$route.query.minDate || "") +
        "-" +
        (context.root.$route.query.maxDate || ""),
      minDate: context.root.$route.query.minDate || "",
      maxDate: context.root.$route.query.maxDate || "",
      minBalance: context.root.$route.query.minBalance || "",
      maxBalance: context.root.$route.query.maxBalance || "",
      balance:
        (context.root.$route.query.minBalance || "") +
        "-" +
        (context.root.$route.query.maxBalance || ""),
      t: context.root.$route.query.t || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: [],
      totalAmount: ""
    },
    selectedIds: [],
    selectedToPay: [],
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.event || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(item => ({
        ...item,
        selected: eventIds.includes(item.id)
      }));
      pageData.value.searchEventsLoading = false;
    }
  });

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          event:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          status:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.status
            ) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          profile: pageData.value.filter.profile || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined,
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          t: pageData.value.filter.t || undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    try {
      pageData.value.pager.page = context.root.$route.query.page
        ? parseInt(context.root.$route.query.page)
        : 1;
      ApiHelper.setDataLoading(true);
      pageData.value.skip =
        (pageData.value.pager.page - 1) * pageData.value.take;

      const filterValues = {
        profile: context.root.$route.query.profile || "",
        event: context.root.$route.query.event || "",
        status: context.root.$route.query.status || "",
        minDate: pageData.value.filter.minDate,
        maxDate: pageData.value.filter.maxDate,
        minBalance: pageData.value.filter.minBalance,
        maxBalance: pageData.value.filter.maxBalance
      };

      const result = await ApiHelper.callApi(
        "get",
        `/balances/outstanding`,
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          ...filterValues,
          getInfo: "totalUnPaid"
        }
      );
      if (result.status == 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = result.data.totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items;
        pageData.value.pager.totalAmount =
          result.data.totalUnpaidBlancesFormatted || "";
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.filter.t = "";
    pageData.value.pager.page = 1;
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "event":
        pageData.value.filter.event = value;
        break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate =
          dateArr[0] !== undefined ? dateArr[0] : "";
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        pageData.value.filter.t = ApiHelper.randomFormId().toString();
        break;
      }
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("-");
        pageData.value.filter.minBalance =
          balanceArr[0] !== undefined ? balanceArr[0] : "";
        pageData.value.filter.maxBalance =
          balanceArr[1] !== undefined ? balanceArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const selectAllEvents = () => {
    if (pageData.value.filter.unassignedEvent) {
      pageData.value.filter.unassignedEvent.selected = true;
    }
    pageData.value.filter.events.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };

  const resetEvents = () => {
    if (pageData.value.filter.unassignedEvent) {
      pageData.value.filter.unassignedEvent.selected = false;
    }
    pageData.value.filter.events.map((item: any) => {
      item.selected = false;
    });
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const selectItem = (item: any) => {
    // const existedItem = selectedIds.value.find(
    //   (value: any) => value.id == item.id
    // );
    // if (item.isChecked) {
    //   pageData.value.items.map(value => {
    //     if (value.id == item.id) {
    //       value.isChecked = true;
    //     }
    //   });
    //   if (!existedItem) {
    //     selectedIds.value.push(item);
    //   }
    // } else {
    //   pageData.value.items.map(value => {
    //     if (value.id == item.id) {
    //       value.isChecked = false;
    //     }
    //   });
    //   selectedIds.value = selectedIds.value.filter(
    //     value => value.id != item.id
    //   );
    // }
  };

  const inSelectedIds = (item: any) => {
    const existedItem = pageData.value.selectedIds.find(
      (id: any) => id == item.participantId
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const updateSelectedToPay = (item: any) => {
    const inSelectedToPay = pageData.value.selectedToPay.find(
      (s: any) => s.participantId == item.participantId
    );
    if (inSelectedIds(item)) {
      if (!inSelectedToPay) {
        pageData.value.selectedToPay.push(item);
      }
    } else {
      if (inSelectedToPay) {
        pageData.value.selectedToPay = pageData.value.selectedToPay.filter(
          (s: any) => s.participantId != item.participantId
        );
      }
    }
  };

  const resetFilters = () => {
    (pageData.value.filter.profile = ""),
      (pageData.value.filter.event = ""),
      (pageData.value.filter.date = ""),
      (pageData.value.filter.minDate = ""),
      (pageData.value.filter.maxDate = ""),
      (pageData.value.filter.minBalance = ""),
      (pageData.value.filter.maxBalance = ""),
      (pageData.value.filter.balance = "");
  };

  // init data
  (async () => {
    loadData(1);
  })();

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.event) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  (async () => {
    const statusIds = (context.root.$route.query.status || "").split(",");
    pageData.value.filter.status.forEach((item: any) => {
      if (statusIds.includes(item.id)) {
        item.selected = true;
      }
    });
  })();

  const getFiltersData = () => {
    const filters = [];
    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          if (pageData.value.filter.unassignedEvent) {
            pageData.value.filter.unassignedEvent.selected = false;
          }
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PARTICIPANT",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate !== ""
    ) {
      let date = "";
      if (pageData.value.filter.minDate && pageData.value.filter.maxDate) {
        date =
          pageData.value.filter.minDate + "-" + pageData.value.filter.maxDate;
      } else if (pageData.value.filter.minDate) {
        date = pageData.value.filter.minDate + "-";
      } else if (pageData.value.filter.maxDate) {
        date = pageData.value.filter.maxDate + "-";
      }
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");
      filters.push({
        label: "DATE",
        key: date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minBalance !== "" ||
      pageData.value.filter.maxBalance !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          updateRouters();
        }
      });
    }

    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  return {
    loadData,
    // headerData,
    pageData,
    // pager
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    setActiveFilterTab,
    closeFilterTab,
    selectAllEvents,
    resetEvents,
    updateFilterValue,
    updateSortValue,
    selectItem,
    inSelectedIds,
    updateSelectedToPay,
    resetFilters,
    updateRouters,
    getFiltersData
  };
}
