export function ProfileListRows(page = "") {
  const winHeight = window.innerHeight;
  let reduceHeight = 335;
  if (page == "EventDetails") {
    reduceHeight = 471;
  }
  const remainingSpaceForTableRows = winHeight - reduceHeight;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 78), 3);
}

export function ReportListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 80), 3);
}

export function EventListRows(from = "") {
  const winHeight = window.innerHeight;

  let headingHeight = 335;
  if (from == "ProfileEvents") {
    headingHeight += 150;
  }
  const remainingSpaceForTableRows = winHeight - headingHeight;

  if (remainingSpaceForTableRows <= 0) {
    return 2;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 109), 2);
}

export function MessagingListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 86), 3);
}

export function FinancialListRows(page = "") {
  const winHeight = window.innerHeight;
  let reduceHeight = 335;
  if (page == "FinancialTransactions") {
    reduceHeight = 379;
  } else if (page == "OutstandingBalances" || page == "FinancialPlans") {
    reduceHeight = 397;
  }

  const remainingSpaceForTableRows = winHeight - reduceHeight;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 70), 3);
}

export function SettingsListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 76), 3);
}

export function FamilyDetailsRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 83), 3);
}

export function ParticipantTypesListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 76), 3);
}

export function TimelinesListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 435;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 78), 3);
}

export function TodosListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 76), 3);
}

export function roommatesListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 335;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 78), 3);
}

export function PosListRows() {
  const winHeight = window.innerHeight;

  const remainingSpaceForTableRows = winHeight - 250;

  if (remainingSpaceForTableRows <= 0) {
    return 3;
  }

  return Math.max(Math.floor(remainingSpaceForTableRows / 78), 3);
}
