import { ref } from "@vue/composition-api";
import { AlertMessageError } from "@/components/VAlertMessage.vue";
import { ApiHelper } from "@/helpers";
import { FormText, PagerItem, SelectOption } from "@/types";
import FormTextarea from "@/types/FormTextarea";
import moment from "moment";

export function useProfileMessagesStore(context: any) {
  const selectedProfileId = parseInt(
    context.root.$route.params.profileId || "0"
  );
  const selectedId = ref(0);
  const headerData = ref({
    title: "Messages",
    subTitle: "",
    status: 0
  });

  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    groups: {
      id: number;
      isChecked: boolean;
      message: string;
      name: string;
      date: string;
    }[];
    items: {
      id: number;
      isChecked: boolean;
      message: string;
      name: string;
      date: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      event: SelectOption[];
      type: SelectOption[];
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: 8,
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      event: [],
      type: []
    },
    groups: [],
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const errors = ref<AlertMessageError[]>([]);

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "SettingsEventApplications",
        query: {
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadData = (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    (async () => {
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/messages",
          {},
          {
            profileId: selectedProfileId
          }
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          const list = result.data.messages.map((item: any) => {
            return {
              id: parseInt(item.id),
              isChecked: false,
              message: item.message || "",
              name: item.isAdmin ? "Me" : item.profileName || "",
              ts: moment(item.ts).calendar() || "",
              tsFormatted: item.tsFormatted || "",
              isAdmin: item.isAdmin || false,
              participantId: item.participantId || 0,
              eventName: item.eventName || "",
              participantTypeName: item.participantTypeName || "",
              formId: item.formId || 0,
              formName: item.formName || ""
            };
          });
          pageData.value.items = list;
          setTimeout(() => {
            const container: any = $(".chat__body");
            if (container && container.find(".end-messages").length > 0) {
              container.animate(
                {
                  scrollTop:
                    container.scrollTop() -
                    container.offset().top +
                    container.find(".end-messages").offset().top
                },
                {
                  duration: "medium",
                  easing: "swing"
                }
              );
            }
          }, 300);
        }
      } catch (err) {
        pageData.value.items = [];
        ApiHelper.setDataLoading(false);

        errors.value = [err];
      }
    })();

    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        const fullName = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
        headerData.value.subTitle = fullName;
        headerData.value.title = fullName;
        headerData.value.status = result.data.status || 0;
      }
    })();
  };

  // pager
  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };
  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };
  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedEvents = pageData.value.filter.event
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.event.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes = pageData.value.filter.type
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.filter.type.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  // init data
  (async () => {
    loadData(1);
  })();

  const formData = ref<{
    isLoading: boolean;
    controls: {
      message: FormTextarea;
      participant: FormText;
      form: FormText;
    };
  }>({
    isLoading: true,
    controls: {
      message: {
        error: "",
        type: "textarea",
        value: ""
      },
      participant: {
        error: "",
        label: "",
        value: ""
      },
      form: {
        error: "",
        label: "",
        value: ""
      }
    }
  });

  const popupNewItem = ref<{
    show: boolean;
    isProcessing: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    isProcessing: false,
    formData: formData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async ($root: any) => {
      let hasError = false;
      if (formData.value.controls.message.value === "") {
        hasError = true;
        formData.value.controls.message.error = "Message is required!";
      } else {
        formData.value.controls.message.error = "";
      }

      if (!hasError) {
        popupNewItem.value.isProcessing = true;
        const result = await ApiHelper.callApi(
          "post",
          "/messages",
          {
            profileId: selectedProfileId,
            participantId: formData.value.controls.participant.value,
            formId: formData.value.controls.form.value,
            subject: "",
            message: ApiHelper.removeHtml(formData.value.controls.message.value)
          },
          {}
        );
        if (result.status == 1) {
          popupNewItem.value.isProcessing = false;
          popupNewItem.value.show = false;
          formData.value.controls.message.value = "";
          loadData(1);
          const children: any = $root.$children || [];
          const layout = children.length > 0 ? children[0] : undefined;
          if (layout && layout.$children.length > 0) {
            layout.$children[0].loadData();
          }
        } else {
          popupNewItem.value.isProcessing = false;
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  const onKeydown = async ($evemt: any, $root: any) => {
    if (
      $evemt.code === "Enter" &&
      popupNewItem.value.formData.controls.message.value.trim() !== ""
    ) {
      await popupNewItem.value.onSubmit($root);
    }
    return false;
  };

  const replyQuestion = (item: any) => {
    formData.value.controls.participant.value = item.participantId;
    formData.value.controls.form.value = item.formId;
    formData.value.controls.participant.label =
      item.eventName +
      " / " +
      item.participantTypeName +
      (item.formName ? " - " + item.formName : "");
  };

  return {
    replyQuestion,
    selectedId,
    onKeydown,
    popupNewItem,
    // sort
    updateSortValue,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab
  };
}
