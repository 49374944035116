import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useRevenueSpendCategoryStore(context: any) {
  const headerData = ref({
    title: "Posting/GL Codes",
    subTitle: "Accounting"
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
      desc: string;
      gl: string;
      type: string;
      status: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      key: context.root.$route.query.key || "",
      desc: context.root.$route.query.desc || "",
      gl: context.root.$route.query.gl || "",
      type: context.root.$route.query.type || "",
      status: context.root.$route.query.status || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: ""
  });

  const updateRouters = () => {
    ApiHelper.routerReplace(context, "RevenueSpendCategory", {
      page: pageData.value.pager.page + "",
      order: pageData.value.sort.order,
      direction: pageData.value.sort.direction,
      key: pageData.value.filter.key || undefined
    });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/revenueSpendCategory",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: context.root.$route.query.key || ""
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items;
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "key": {
        pageData.value.filter.key = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.key !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  // init data
  (async () => {
    loadData(1);
  })();

  const updateItemStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    if (statusOption.value === 0 || statusOption.value === 2) {
      const actionName = statusOption.value === 0 ? "archive" : "delete";
      const isConfirm = await Vue.swal({
        html: `Are you sure you want to ${actionName} this category?`,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
    }

    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/accounting/revenueSpendCategory/" + item.revenueCategoryId + "/status",
      {
        status: statusOption.value
      },
      {}
    );
    if (result.status === 1) {
      ApiHelper.setDataLoading(false);
      if (pageData.value.pager.page === 1 || statusOption.value !== 2) {
        loadList();
      } else {
        pageData.value.pager.page = 1;
        updateRouters();
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    updateRouters,
    updateItemStatus,
    // page
    headerData,
    pageData,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems
  };
}
