import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText, PagerItem, SelectOption } from "@/types";
import moment from "moment";
import FormDate from "@/types/FormDate";
import { MedicalIncidentType } from "@/helpers/ApiHelper";

export function useMedicalDashboardStore(context: any) {
  const query = context.root.$route.query;
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any;
    activeTab: string;
    arrTags: SelectOption[];
    arrProfiles: SelectOption[];
    arrBalances: SelectOption[];
    openItemId: number;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      profile: string;
      unassignedEvent: SelectOption | null;
      events: SelectOption[];
      incidentType: SelectOption[];
      name: string;
      type: string;
      summary: string;
      followUpNotes: string;
      followupMedication: string;
      date: string;
      minDate: string;
      maxDate: string;
      followupPName: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    // events
    searchEvents: Function;
    searchEventsValue: {
      value: string;
    };
    searchEventsLoading: boolean;
    selectedEvents: SelectOption[];
    // participants events
    searchPEventsValue: {
      value: string;
    };
    searchPEventsLoading: boolean;
    selectedPEvents: SelectOption[];
    medicalLogs: {
      skip: number;
      take: number;
      items: any[];
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        route: SelectOption[];
        frequency: SelectOption[];
        incidentType: SelectOption[];
        name: string;
        type: string;
        summary: string;
        medicalLogsSummary: string;
        symptoms: SelectOption[];
        medicalLogsProfile: string;
        date: string;
        minDate: string;
        maxDate: string;
      };
      showHeadActions: boolean;
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    searchSymptomsLoading: boolean;
    searchSymptomsValue: {
      value: string;
    };
    selectedSymptoms: SelectOption[];
    participants: {
      skip: number;
      take: number;
      items: any[];
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        date: string;
        minDate: string;
        maxDate: string;
        age: string;
        minAge: string;
        maxAge: string;
        pProfileName: string;
        family: SelectOption[];
        gender: SelectOption[];
        events: SelectOption[];
        cabin: SelectOption[];
        allergies: string;
        minAllergies: string;
        maxAllergies: string;
        medications: string;
        minMedications: string;
        maxMedications: string;
        status: SelectOption[];
        selectedEventId: string;
      };
      showHeadActions: boolean;
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    searchFamiliesValue: {
      value: string;
    };
    searchFamiliesLoading: boolean;
    selectedFamilies: SelectOption[];
    searchCabinsValue: {
      value: string;
    };
    searchCabinsLoading: boolean;
    selectedCabins: SelectOption[];
    selectedProfileIds: number[];
    followupInfoVisible: boolean;
    selectedMedicalFollowUpId: number;
    resolved: boolean;
    followupDate: FormDate;
    followupNotes: FormText;
    followUpLogs: any;
    med: FormText;
    dispenseMedicationVisible: boolean;
  }>({
    isLoading: false,
    skip: 0,
    take: 8,
    activeTab: "",
    arrTags: [],
    arrProfiles: [],
    arrBalances: [],
    openItemId: 0,
    sort: {
      // followupDate ASC
      order: "6",
      direction: "1"
    },
    filter: {
      profile: context.root.$route.query.profile || "",
      events: [],
      unassignedEvent: null,
      incidentType: ApiHelper.getIncidentTypeOptions(),
      name: context.root.$route.query.name || "",
      type: context.root.$route.query.type || "1",
      summary: "",
      followUpNotes: "",
      followupMedication: query.followupMedication || "",
      date: "",
      minDate: "",
      maxDate: "",
      followupPName: context.root.$route.query.followupPName || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.eventIds || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    searchEventsValue: {
      value: query.searchEventsValue || ""
    },
    searchEventsLoading: false,
    selectedEvents: [],
    searchPEventsValue: {
      value: query.searchPEventsValue || ""
    },
    searchPEventsLoading: false,
    selectedPEvents: [],
    medicalLogs: {
      skip: 0,
      take: 8,
      activeTab: "",
      sort: {
        // default to showing most recent log
        order: context.root.$route.query.order || "11",
        direction: context.root.$route.query.direction || "2"
      },
      filter: {
        route: [],
        frequency: [],
        incidentType: [],
        name: context.root.$route.query.name || "",
        type: context.root.$route.query.type || "1",
        summary: "",
        medicalLogsSummary: "",
        symptoms: [],
        medicalLogsProfile: "",
        date: "",
        minDate: "",
        maxDate: ""
      },
      items: [],
      showHeadActions: false,
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    searchSymptomsLoading: false,
    searchSymptomsValue: {
      value: query.searchSymptomsValue || ""
    },
    selectedSymptoms: [],
    participants: {
      skip: 0,
      take: 8,
      activeTab: "",
      sort: {
        order: query.pOrder || "1",
        direction: query.pDirection || "1"
      },
      filter: {
        date: "",
        minDate: "",
        maxDate: "",
        age: (query.minAge || "") + "-" + (query.maxAge || ""),
        minAge: query.minAge || "",
        maxAge: query.maxAge || "",
        pProfileName: query.pProfileName || "",
        family: [],
        gender: ApiHelper.getGenderOptions(),
        events: [],
        cabin: [],
        allergies:
          (query.minAllergies || "") + "-" + (query.maxAllergies || ""),
        minAllergies: query.minAllergies || "",
        maxAllergies: query.maxAllergies || "",
        medications:
          (query.minMedications || "") + "-" + (query.maxMedications || ""),
        minMedications: query.minMedications || "",
        maxMedications: query.maxMedications || "",
        status: [],
        selectedEventId: ""
      },
      items: [],
      showHeadActions: false,
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    searchFamiliesValue: {
      value: ""
    },
    searchFamiliesLoading: false,
    selectedFamilies: [],
    searchCabinsValue: {
      value: query.searchCabinsValue || ""
    },
    searchCabinsLoading: false,
    selectedCabins: [],
    selectedProfileIds: [],
    followupInfoVisible: false,
    selectedMedicalFollowUpId: 0,
    resolved: false,
    followupDate: {
      error: "",
      format: "MM/DD/YYYY hh:mm A",
      placeholder: "MM/DD/YYYY hh:mm",
      label: "Follow Up Date & Time",
      type: "datetime",
      value: undefined,
      style: "custom",
      required: true
    },
    followupNotes: {
      error: "",
      label: "Notes",
      placeholder: "Follow up notes",
      type: "text",
      value: "",
      style: "custom",
      maxlength: 250,
      required: true
    },
    followUpLogs: [],
    med: {
      error: "",
      label: "Med",
      placeholder: "",
      type: "text",
      value: "",
      style: "custom",
      maxlength: 150
    },
    dispenseMedicationVisible: false
  });

  // const errors = ref<AlertMessageError[]>([]);

  const updateRouters = async () => {
    context.root.$router
      .replace({
        name: "MedicalDashboard",
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          followupPName: pageData.value.filter.followupPName,
          followUpNotes: pageData.value.filter.followUpNotes,
          followupMedication: pageData.value.filter.followupMedication,
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || "",
          eventIds: pageData.value.selectedEvents
            .map((item: any) => item.id)
            .join(","),
          searchEventsValue: pageData.value.searchEventsValue.value,
          // medical logs params
          mlpage: pageData.value.medicalLogs.pager.page + "",
          mlorder: pageData.value.medicalLogs.sort.order,
          mldirection: pageData.value.medicalLogs.sort.direction,
          incidentType:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.incidentType
            ) || undefined,
          medicalLogsSummary:
            pageData.value.medicalLogs.filter.medicalLogsSummary,
          selectedSymptomIds: pageData.value.selectedSymptoms
            .map((item: any) => item.id)
            .join(","),
          searchSymptomsValue: pageData.value.searchSymptomsValue.value,
          medicalLogsProfile:
            pageData.value.medicalLogs.filter.medicalLogsProfile,
          minDateMedicalLogs: pageData.value.medicalLogs.filter.minDate || "",
          maxDateMedicalLogs: pageData.value.medicalLogs.filter.maxDate || ""
          // participants list
          // pPage: pageData.value.participants.pager.page + "",
          // pOrder: pageData.value.participants.sort.order,
          // pDirection: pageData.value.participants.sort.direction,
          // pProfileName: pageData.value.participants.filter.pProfileName,
          // familyIds: pageData.value.selectedFamilies
          //   .map((item: any) => item.id)
          //   .join(","),
          // minAge: pageData.value.participants.filter.minAge,
          // maxAge: pageData.value.participants.filter.maxAge,
          // peventIds: pageData.value.selectedPEvents
          //   .map((item: any) => item.id)
          //   .join(","),
          // searchPEventsValue: pageData.value.searchPEventsValue.value,
          // gender:
          //   ApiHelper.convertSelectedOptionsToString(
          //     pageData.value.participants.filter.gender
          //   ) || undefined,
          // selectedCabinIds: pageData.value.selectedCabins
          //   .map((item: any) => item.id)
          //   .join(","),
          // searchCabinsValue: pageData.value.searchCabinsValue.value,
          // minAllergies: pageData.value.participants.filter.minAllergies,
          // maxAllergies: pageData.value.participants.filter.maxAllergies,
          // minMedications: pageData.value.participants.filter.minMedications,
          // maxMedications: pageData.value.participants.filter.maxMedications
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const getFollowUp = async (page: number) => {
    pageData.value.pager.page = page || 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      `/medicals/followup`,
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        followupPName: pageData.value.filter.followupPName || "",
        followUpNotes: pageData.value.filter.followUpNotes || "",
        followupMedication: pageData.value.filter.followupMedication || "",
        minDate: pageData.value.filter.minDate || "",
        maxDate: pageData.value.filter.maxDate || "",
        eventIds: pageData.value.selectedEvents
          .map((item: any) => item.id)
          .join(",")
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const items = result.data.items;
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = items;
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops..");
    }
  };

  const getMedicalLogs = async (page: number) => {
    pageData.value.medicalLogs.pager.page = page || 1;
    ApiHelper.setDataLoading(true);
    pageData.value.medicalLogs.skip =
      (pageData.value.medicalLogs.pager.page - 1) *
      pageData.value.medicalLogs.take;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals",
      {},
      {
        skip: pageData.value.medicalLogs.skip,
        take: pageData.value.medicalLogs.take,
        order: parseInt(pageData.value.medicalLogs.sort.order),
        direction: parseInt(pageData.value.medicalLogs.sort.direction),
        type: 2,
        key: pageData.value.medicalLogs.filter.name || "",
        incidentType:
          ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.incidentType
          ) || undefined,
        medicalLogsSummary:
          pageData.value.medicalLogs.filter.medicalLogsSummary,
        symptom: pageData.value.selectedSymptoms
          .map((item: any) => item.id)
          .join(","),
        profile: pageData.value.medicalLogs.filter.medicalLogsProfile,
        exportDate: pageData.value.medicalLogs.filter.minDate,
        exportToDate: pageData.value.medicalLogs.filter.maxDate
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.medicalLogs.pager.totalPages = Math.ceil(
        totalCount / pageData.value.medicalLogs.take
      );
      pageData.value.medicalLogs.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.medicalLogs.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.medicalLogs.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.medicalLogs.pager.items = pagerList;
      pageData.value.medicalLogs.items = result.data.items || [];
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const getParticipants = async (page: number) => {
    pageData.value.participants.pager.page = page || 1;
    ApiHelper.setDataLoading(true);
    pageData.value.participants.skip =
      (pageData.value.participants.pager.page - 1) *
      pageData.value.participants.take;

    const result = await ApiHelper.callApi(
      "get",
      "/medicals/participants",
      {},
      {
        skip: pageData.value.participants.skip,
        take: pageData.value.participants.take,
        order: parseInt(pageData.value.participants.sort.order),
        direction: parseInt(pageData.value.participants.sort.direction),
        eventIds: pageData.value.selectedPEvents.map(item => item.id).join(","),
        key: pageData.value.participants.filter.pProfileName || "",
        gender:
          ApiHelper.convertSelectedOptionsToString(
            pageData.value.participants.filter.gender
          ) || undefined,
        cabin: pageData.value.selectedCabins.map(item => item.id).join(","),
        minAge: pageData.value.participants.filter.minAge || "",
        maxAge: pageData.value.participants.filter.maxAge || "",
        minAllergies: pageData.value.participants.filter.minAllergies || "",
        maxAllergies: pageData.value.participants.filter.maxAllergies || "",
        minMedications: pageData.value.participants.filter.minMedications || "",
        maxMedications: pageData.value.participants.filter.maxMedications || ""
        // viewtype: Number.parseInt(context.root.$route.query.tab, 10) || 1,
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.participants.pager.totalPages = Math.ceil(
        totalCount / pageData.value.participants.take
      );
      pageData.value.participants.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.participants.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.participants.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.participants.pager.items = pagerList;
      pageData.value.participants.items = result.data.participants || [];
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const gotoPage = (page: string, type = "") => {
    if (type == "") {
      pageData.value.pager.page = parseInt(page);
      updateRouters();
      getFollowUp(pageData.value.pager.page);
    } else if (type == "medicalLogs") {
      pageData.value.medicalLogs.pager.page = parseInt(page);
      updateRouters();
      getMedicalLogs(pageData.value.medicalLogs.pager.page);
    } else if (type == "participants") {
      pageData.value.participants.pager.page = parseInt(page);
      updateRouters();
      getParticipants(pageData.value.participants.pager.page);
    }
  };

  const onClickPrev = (type = "") => {
    if (type == "") {
      if (pageData.value.pager.page > 1) {
        pageData.value.pager.page -= 1;
        updateRouters();
        getFollowUp(pageData.value.pager.page);
      }
    } else if (type == "medicalLogs") {
      if (pageData.value.medicalLogs.pager.page > 1) {
        pageData.value.medicalLogs.pager.page -= 1;
        updateRouters();
        getMedicalLogs(pageData.value.medicalLogs.pager.page);
      }
    } else if (type == "participants") {
      if (pageData.value.participants.pager.page > 1) {
        pageData.value.participants.pager.page -= 1;
        updateRouters();
        getParticipants(pageData.value.participants.pager.page);
      }
    }
  };

  const onClickNext = (type = "") => {
    if (type == "") {
      if (pageData.value.pager.page < pageData.value.pager.totalPages) {
        pageData.value.pager.page += 1;
        updateRouters();
        getFollowUp(pageData.value.pager.page);
      }
    } else if (type == "medicalLogs") {
      if (
        pageData.value.medicalLogs.pager.page <
        pageData.value.medicalLogs.pager.totalPages
      ) {
        pageData.value.medicalLogs.pager.page += 1;
        updateRouters();
        getMedicalLogs(pageData.value.medicalLogs.pager.page);
      }
    } else if (type == "participants") {
      if (
        pageData.value.participants.pager.page <
        pageData.value.participants.pager.totalPages
      ) {
        pageData.value.participants.pager.page += 1;
        updateRouters();
        getParticipants(pageData.value.participants.pager.page);
      }
    }
  };

  const closeFilterTab = () => {
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "Symptom") {
      if (pageData.value.searchSymptomsValue.value != "") return;
      pageData.value.searchSymptomsLoading = true;
      pageData.value.medicalLogs.filter.symptoms = [];
      pageData.value.medicalLogs.filter.symptoms = await ApiHelper.getSymptomOptions(
        {
          getAll: 1
        }
      );
      const selectedSymptomIds = (
        context.root.$route.query.selectedSymptomIds || ""
      )
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
        item => ({
          ...item,
          selected: selectedSymptomIds.includes(item.id)
        })
      );
      pageData.value.searchSymptomsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedSymptoms();
    }

    if (pageData.value.activeTab == "followUpEvents") {
      if (pageData.value.searchEventsValue.value != "") return;
      pageData.value.searchEventsLoading = true;
      pageData.value.filter.events = [];
      await pageData.value.searchEvents("");
      pageData.value.searchEventsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedEvents();
    }

    if (pageData.value.activeTab == "family") {
      if (pageData.value.searchFamiliesValue.value != "") return;
      pageData.value.searchFamiliesLoading = true;
      pageData.value.participants.filter.family = await ApiHelper.getProfileFamilyOptions(
        {
          getAll: 1
        }
      );
      const familyIds = (context.root.$route.query.familyIds || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.participants.filter.family = pageData.value.participants.filter.family.map(
        item => ({
          ...item,
          selected: familyIds.includes(item.id)
        })
      );
      pageData.value.searchFamiliesLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedFamilies();
    }

    if (pageData.value.activeTab == "pEvents") {
      if (pageData.value.searchPEventsValue.value != "") return;
      pageData.value.searchPEventsLoading = true;
      pageData.value.participants.filter.events = [];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await searchPEvents("");
      pageData.value.searchPEventsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedEvents();
    }

    if (pageData.value.activeTab == "cabin") {
      if (pageData.value.searchCabinsValue.value != "") return;
      pageData.value.searchCabinsLoading = true;
      pageData.value.participants.filter.cabin = [];
      pageData.value.participants.filter.cabin = await ApiHelper.getCabinsOptions(
        0,
        {
          getAll: 1,
          includeUnassignedOption: false
        }
      );
      const selectedCabinIds = (
        context.root.$route.query.selectedCabinIds || ""
      )
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.participants.filter.cabin = pageData.value.participants.filter.cabin.map(
        item => ({
          ...item,
          selected: selectedCabinIds.includes(item.id)
        })
      );
      pageData.value.searchCabinsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedCabins();
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.followupPName !== "") {
      filters.push({
        label: "FOLLOW UP PARTICIPANT",
        key: pageData.value.filter.followupPName + Math.random(),
        value: pageData.value.filter.followupPName,
        reset: () => {
          pageData.value.filter.followupPName = "";
          updateRouters();
          getFollowUp(1);
        }
      });
    }
    if (pageData.value.filter.followUpNotes !== "") {
      filters.push({
        label: "NOTES",
        key: pageData.value.filter.followUpNotes + Math.random(),
        value: pageData.value.filter.followUpNotes,
        reset: () => {
          pageData.value.filter.followUpNotes = "";
          updateRouters();
          getFollowUp(1);
        }
      });
    }
    if (pageData.value.filter.followupMedication !== "") {
      filters.push({
        label: "MEDICATION",
        key: pageData.value.filter.followupMedication + Math.random(),
        value: pageData.value.filter.followupMedication,
        reset: () => {
          pageData.value.filter.followupMedication = "";
          updateRouters();
          getFollowUp(1);
        }
      });
    }
    const selectedEvents = pageData.value.selectedEvents.map((item: any) => {
      return item.text;
    });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.searchEventsValue.value = "";
          pageData.value.selectedEvents = [];
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getFollowUp(1);
        }
      });
    }
    if (
      pageData.value.filter.date !== "" &&
      pageData.value.filter.date !== "-"
    ) {
      const arr = pageData.value.filter.date.split("-");
      let displayValue = "";
      if (arr.length === 2) {
        displayValue =
          (pageData.value.filter.minDate != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[0])
            : "") +
          " - " +
          (pageData.value.filter.maxDate != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[1])
            : "");
      }

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
          getFollowUp(1);
        }
      });
    }
    const selectedIncidentTypes = pageData.value.filter.incidentType
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedIncidentTypes.length > 0) {
      filters.push({
        label: "INCIDENT TYPE",
        key: selectedIncidentTypes.join("-") + Math.random(),
        value: selectedIncidentTypes.join(", "),
        reset: () => {
          pageData.value.filter.incidentType.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const medicalLogsProfile =
      pageData.value.medicalLogs.filter.medicalLogsProfile || "";
    if (medicalLogsProfile !== "") {
      filters.push({
        label: "NAME",
        key: medicalLogsProfile + Math.random(),
        value: medicalLogsProfile,
        reset: () => {
          pageData.value.medicalLogs.filter.medicalLogsProfile = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const medicalLogsSummary =
      pageData.value.medicalLogs.filter.medicalLogsSummary || "";
    if (medicalLogsSummary !== "") {
      filters.push({
        label: "MEDICAL SUMMARY",
        key: medicalLogsSummary + Math.random(),
        value: medicalLogsSummary,
        reset: () => {
          pageData.value.medicalLogs.filter.medicalLogsSummary = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const selectedSymptoms = pageData.value.selectedSymptoms.map(
      (item: any) => {
        return item.text;
      }
    );
    if (selectedSymptoms.length > 0) {
      filters.push({
        label: "SYMPTOMS",
        key: selectedSymptoms.join("-") + Math.random(),
        value: selectedSymptoms.join(", "),
        reset: () => {
          pageData.value.searchSymptomsValue.value = "";
          pageData.value.selectedSymptoms = [];
          pageData.value.medicalLogs.filter.symptoms.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    if (
      pageData.value.medicalLogs.filter.date !== "" &&
      pageData.value.medicalLogs.filter.date !== "-"
    ) {
      const arr = pageData.value.medicalLogs.filter.date.split("-");
      let displayValue = "";
      if (arr.length === 2) {
        displayValue =
          (arr[0] != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[0])
            : "") +
          " - " +
          (arr[1] != "" ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[1]) : "");
      }
      filters.push({
        label: "MEDICAL DATE",
        key: pageData.value.medicalLogs.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.medicalLogs.filter.date = "";
          pageData.value.medicalLogs.filter.minDate = "";
          pageData.value.medicalLogs.filter.maxDate = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    // participants
    const pProfileName = pageData.value.participants.filter.pProfileName || "";
    if (pProfileName !== "") {
      filters.push({
        label: "PARTICIPANT",
        key: pProfileName + Math.random(),
        value: pProfileName,
        reset: () => {
          pageData.value.participants.filter.pProfileName = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    const selectedFamilies = pageData.value.selectedFamilies.map(
      (item: any) => {
        return item.text;
      }
    );
    if (selectedFamilies.length > 0) {
      filters.push({
        label: "FAMILY",
        key: selectedFamilies.join("-") + Math.random(),
        value: selectedFamilies.join(", "),
        reset: () => {
          pageData.value.selectedFamilies = [];
          pageData.value.participants.filter.family.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    if (
      pageData.value.participants.filter.minAge !== "" ||
      pageData.value.participants.filter.maxAge !== ""
    ) {
      let ageRange = "";
      if (
        pageData.value.participants.filter.minAge &&
        pageData.value.participants.filter.maxAge
      ) {
        ageRange =
          pageData.value.participants.filter.minAge +
          "-" +
          pageData.value.participants.filter.maxAge;
      } else if (pageData.value.participants.filter.minAge) {
        ageRange = pageData.value.participants.filter.minAge + "+";
      } else if (pageData.value.participants.filter.maxAge) {
        ageRange = pageData.value.participants.filter.maxAge + "-";
      }
      filters.push({
        label: "AGE",
        key: pageData.value.participants.filter.age + Math.random(),
        value: ageRange,
        reset: () => {
          pageData.value.participants.filter.age = "";
          pageData.value.participants.filter.minAge = "";
          pageData.value.participants.filter.maxAge = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    const selectedPEvents = pageData.value.selectedPEvents.map((item: any) => {
      return item.text;
    });
    if (selectedPEvents.length > 0) {
      filters.push({
        label: "PARTICIPANTS EVENT",
        key: selectedPEvents.join("-") + Math.random(),
        value: selectedPEvents.join(", "),
        reset: () => {
          pageData.value.selectedPEvents = [];
          pageData.value.participants.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    const selectedGenders = pageData.value.participants.filter.gender
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedGenders.length > 0) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.participants.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    const selectedCabins = pageData.value.selectedCabins.map((item: any) => {
      return item.text;
    });
    if (selectedCabins.length > 0) {
      filters.push({
        label: "CABIN",
        key: selectedCabins.join("-") + Math.random(),
        value: selectedCabins.join(", "),
        reset: () => {
          pageData.value.selectedCabins = [];
          pageData.value.participants.filter.cabin.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    if (
      pageData.value.participants.filter.minAllergies !== "" ||
      pageData.value.participants.filter.maxAllergies !== ""
    ) {
      let range = "";
      if (
        pageData.value.participants.filter.minAllergies &&
        pageData.value.participants.filter.maxAllergies
      ) {
        range =
          pageData.value.participants.filter.minAllergies +
          "-" +
          pageData.value.participants.filter.maxAllergies;
      } else if (pageData.value.participants.filter.minAllergies) {
        range = pageData.value.participants.filter.minAllergies + "+";
      } else if (pageData.value.participants.filter.maxAllergies) {
        range = pageData.value.participants.filter.maxAllergies + "-";
      }
      filters.push({
        label: "ALLERGIES",
        key: pageData.value.participants.filter.allergies + Math.random(),
        value: range,
        reset: () => {
          pageData.value.participants.filter.allergies = "";
          pageData.value.participants.filter.minAllergies = "";
          pageData.value.participants.filter.maxAllergies = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }
    if (
      pageData.value.participants.filter.minMedications !== "" ||
      pageData.value.participants.filter.maxMedications !== ""
    ) {
      let range = "";
      if (
        pageData.value.participants.filter.minMedications &&
        pageData.value.participants.filter.maxMedications
      ) {
        range =
          pageData.value.participants.filter.minMedications +
          "-" +
          pageData.value.participants.filter.maxMedications;
      } else if (pageData.value.participants.filter.minMedications) {
        range = pageData.value.participants.filter.minMedications + "+";
      } else if (pageData.value.participants.filter.maxMedications) {
        range = pageData.value.participants.filter.maxMedications + "-";
      }
      filters.push({
        label: "MEDICATIONS",
        key: pageData.value.participants.filter.medications + Math.random(),
        value: range,
        reset: () => {
          pageData.value.participants.filter.medications = "";
          pageData.value.participants.filter.minMedications = "";
          pageData.value.participants.filter.maxMedications = "";
          updateRouters();
          getParticipants(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "followupPName":
        pageData.value.pager.page = 1;
        pageData.value.filter.followupPName = value;
        break;
      case "followUpNotes":
        pageData.value.pager.page = 1;
        pageData.value.filter.followUpNotes = value;
        break;
      case "followupMedication":
        pageData.value.pager.page = 1;
        pageData.value.filter.followupMedication = value;
        break;
      case "followUpDate": {
        pageData.value.pager.page = 1;
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        let minDate = dateArr[0];
        if (minDate) {
          minDate = `${minDate.substring(0, 4)}-${minDate.substring(
            4,
            6
          )}-${minDate.substring(6, 8)}`;
        }
        pageData.value.filter.minDate = minDate;
        let maxDate = dateArr[1] !== undefined ? dateArr[1] : "";
        if (maxDate) {
          maxDate = `${maxDate.substring(0, 4)}-${maxDate.substring(
            4,
            6
          )}-${maxDate.substring(6, 8)}`;
        }
        pageData.value.filter.maxDate = maxDate;
        break;
      }
      case "medicalLogsSummary":
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.medicalLogsSummary = value;
        break;
      case "medicalLogsProfile":
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.medicalLogsProfile = value;
        break;
      case "medicalLogsDate": {
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.date = value;
        const dateArr = value.split("-");
        let minDate = dateArr[0];
        if (minDate) {
          minDate = `${minDate.substring(0, 4)}-${minDate.substring(
            4,
            6
          )}-${minDate.substring(6, 8)}`;
        }
        pageData.value.medicalLogs.filter.minDate = minDate;
        let maxDate = dateArr[1] !== undefined ? dateArr[1] : "";
        if (maxDate) {
          maxDate = `${maxDate.substring(0, 4)}-${maxDate.substring(
            4,
            6
          )}-${maxDate.substring(6, 8)}`;
        }
        pageData.value.medicalLogs.filter.maxDate = maxDate;
        break;
      }
      case "participantsName": {
        pageData.value.participants.pager.page = 1;
        pageData.value.participants.filter.pProfileName = value;
        break;
      }
      case "age": {
        pageData.value.participants.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.participants.filter.minAge =
          ageArr[0] != undefined ? ageArr[0] : "";
        pageData.value.participants.filter.maxAge =
          ageArr[1] != undefined ? ageArr[1] : "";
        break;
      }
      case "allergies": {
        pageData.value.participants.filter.allergies = value;
        const ageArr = value.split("-");
        pageData.value.participants.filter.minAllergies =
          ageArr[0] != undefined ? ageArr[0] : "";
        pageData.value.participants.filter.maxAllergies =
          ageArr[1] != undefined ? ageArr[1] : "";
        break;
      }
      case "medications": {
        pageData.value.participants.filter.medications = value;
        const ageArr = value.split("-");
        pageData.value.participants.filter.minMedications =
          ageArr[0] != undefined ? ageArr[0] : "";
        pageData.value.participants.filter.maxMedications =
          ageArr[1] != undefined ? ageArr[1] : "";
        break;
      }
    }
    updateRouters();
  };

  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  const selectAllSymptoms = () => {
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedSymptoms = [
      ...pageData.value.medicalLogs.filter.symptoms
    ].filter(item => item.selected);
    updateRouters();
    getMedicalLogs(1);
  };

  const resetSymptoms = () => {
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.selectedSymptoms = [];
    pageData.value.medicalLogs.pager.page = 1;
    updateRouters();
    getMedicalLogs(1);
  };

  const searchSymptoms = async (searchValue: string) => {
    pageData.value.searchSymptomsLoading = true;
    // if (searchValue == "") {
    //   pageData.value.medicalLogs.filter.symptoms = await ApiHelper.getSymptomOptions(
    //     {
    //       getAll: 1
    //     }
    //   );
    // } else {
    //   pageData.value.medicalLogs.filter.symptoms = await ApiHelper.getSymptomOptions(
    //     {
    //       symptomName: searchValue
    //     }
    //   );
    // }
    let list: any = [];
    if (!pageData.value.medicalLogs.filter.symptoms.length) {
      list = await ApiHelper.getSymptomOptions({
        getAll: 1
      });
    } else {
      list = [...pageData.value.medicalLogs.filter.symptoms];
    }

    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.medicalLogs.filter.symptoms = list;

    const selectedSymptomIds = (
      context.root.$route.query.selectedSymptomIds || ""
    )
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      item => ({
        ...item,
        selected: selectedSymptomIds.includes(item.id)
      })
    );
    pageData.value.searchSymptomsLoading = false;
  };

  const updateSelectedSymptoms = async (init = false) => {
    if (init) {
      // pageData.value.searchSymptomsLoading = true;
      // const searchSymptomsValue =
      //   context.root.$route.query.searchSymptomsValue || "";
      // if (searchSymptomsValue != "") {
      //   pageData.value.searchSymptomsValue.value = searchSymptomsValue;
      //   pageData.value.medicalLogs.filter.symptoms = await ApiHelper.getSymptomOptions(
      //     {
      //       symptomName: searchSymptomsValue,
      //       getAll: 1
      //     }
      //   );
      // }
      // const selectedSymptomIds = (
      //   context.root.$route.query.selectedSymptomIds || ""
      // )
      //   .split(",")
      //   .map((id: string) => parseInt(id));
      // if (selectedSymptomIds.length) {
      //   pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      //     item => ({
      //       ...item,
      //       selected: selectedSymptomIds.includes(item.id)
      //     })
      //   );
      //   pageData.value.selectedSymptoms = [
      //     ...pageData.value.medicalLogs.filter.symptoms
      //   ].filter(item => item.selected);
      // }
      // pageData.value.searchSymptomsLoading = false;
      const searchValue = context.root.$route.query.searchSymptomsValue || "";
      if (searchValue != "") {
        await searchSymptoms(searchValue);
      }
    }
    pageData.value.selectedSymptoms = [
      ...pageData.value.medicalLogs.filter.symptoms
    ].filter(item => item.selected);
  };

  const selectAllEvents = () => {
    if (pageData.value.filter.unassignedEvent) {
      pageData.value.filter.unassignedEvent.selected = true;
    }
    pageData.value.filter.events = pageData.value.filter.events.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedEvents = [
      pageData.value.filter.unassignedEvent || {
        id: 0,
        text: "",
        selected: false
      },
      ...pageData.value.filter.events
    ].filter(item => item.selected);
    updateRouters();
    getFollowUp(1);
  };

  const resetEvents = () => {
    pageData.value.filter.events = [];
    pageData.value.filter.unassignedEvent = null;
    pageData.value.selectedEvents = [];
    pageData.value.pager.page = 1;
    updateRouters();
    getFollowUp(1);
  };

  const searchEvents = async (searchValue: string) => {
    pageData.value.searchEventsLoading = true;
    // if (searchValue == "") {
    //   pageData.value.filter.events = await ApiHelper.getEventOptions({
    //     getAll: 1
    //   });
    // } else {
    //   pageData.value.filter.events = await ApiHelper.getEventOptions({
    //     key: searchValue
    //   });
    // }
    let list: any = [];
    if (!pageData.value.filter.events.length) {
      list = await ApiHelper.getEventOptions({
        getAll: 1
      });
    } else {
      list = [...pageData.value.filter.events];
    }

    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.filter.events = list;

    const eventIds = (context.root.$route.query.eventIds || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.filter.events = pageData.value.filter.events.map(item => ({
      ...item,
      selected: eventIds.includes(item.id)
    }));
    pageData.value.searchEventsLoading = false;
  };

  const updateSelectedEvents = async (init = false) => {
    if (init) {
      // pageData.value.searchEventsLoading = true;
      // const searchEventsValue =
      //   context.root.$route.query.searchEventsValue || "";
      // if (searchEventsValue != "") {
      //   pageData.value.searchEventsValue.value = searchEventsValue;
      //   pageData.value.filter.events = await ApiHelper.getEventOptions({
      //     key: searchEventsValue,
      //     getAll: 1
      //   });
      // }
      // const eventIds = (context.root.$route.query.eventIds || "")
      //   .split(",")
      //   .map((id: string) => parseInt(id));
      // if (eventIds.length) {
      //   pageData.value.filter.events = pageData.value.filter.events.map(
      //     item => ({
      //       ...item,
      //       selected: eventIds.includes(item.id)
      //     })
      //   );
      //   pageData.value.selectedEvents = [
      //     ...pageData.value.filter.events
      //   ].filter(item => item.selected);
      // }
      // pageData.value.searchEventsLoading = false;

      const searchValue = context.root.$route.query.searchEventsValue || "";
      if (searchValue != "") {
        await pageData.value.searchEvents(searchValue);
      }
    }
    pageData.value.selectedEvents = [
      pageData.value.filter.unassignedEvent || {
        id: 0,
        text: "",
        selected: false
      },
      ...pageData.value.filter.events
    ].filter(item => item.selected);
  };

  const selectAllFamilies = () => {
    pageData.value.participants.filter.family = pageData.value.participants.filter.family.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    updateRouters();
    getParticipants(1);
  };

  const resetFamilies = () => {
    pageData.value.participants.filter.family = pageData.value.participants.filter.family.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.participants.pager.page = 1;
    updateRouters();
    getParticipants(1);
  };

  const updateSortValue = async (
    sort: string,
    direction: string,
    type = ""
  ) => {
    if (type == "") {
      pageData.value.sort.order = sort;
      pageData.value.sort.direction = direction;
      updateRouters();
      await getFollowUp(pageData.value.pager.page);
    } else if (type == "medicalLogs") {
      pageData.value.medicalLogs.sort.order = sort;
      pageData.value.medicalLogs.sort.direction = direction;
      updateRouters();
      await getMedicalLogs(pageData.value.medicalLogs.pager.page);
    } else if (type == "participants") {
      pageData.value.participants.sort.order = sort;
      pageData.value.participants.sort.direction = direction;
      updateRouters();
      await getParticipants(pageData.value.participants.pager.page);
    }
  };

  const searchFamilies = async (searchValue: string) => {
    pageData.value.searchFamiliesLoading = true;
    if (searchValue == "") {
      pageData.value.participants.filter.family = await ApiHelper.getProfileFamilyOptions(
        {
          getAll: 1
        }
      );
    } else {
      pageData.value.participants.filter.family = await ApiHelper.getProfileFamilyOptions(
        {
          familyName: searchValue
        }
      );
    }
    const familyIds = (context.root.$route.query.family || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.participants.filter.family = pageData.value.participants.filter.family.map(
      item => ({
        ...item,
        selected: familyIds.includes(item.id)
      })
    );
    pageData.value.searchFamiliesLoading = false;
  };

  const updateSelectedFamilies = async (init = false) => {
    if (init) {
      pageData.value.searchFamiliesLoading = true;
      const searchFamiliesValue =
        context.root.$route.query.searchFamilies || "";
      if (searchFamiliesValue != "") {
        pageData.value.searchFamiliesValue.value = searchFamiliesValue;
        pageData.value.participants.filter.family = await ApiHelper.getProfileFamilyOptions(
          {
            familyName: searchFamiliesValue
          }
        );
      }
      const familyIds = (context.root.$route.query.family || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (familyIds.length) {
        pageData.value.participants.filter.family = pageData.value.participants.filter.family.map(
          item => ({
            ...item,
            selected: familyIds.includes(item.id)
          })
        );
        pageData.value.selectedFamilies = [
          ...pageData.value.participants.filter.family
        ].filter(item => item.selected);
      }
      pageData.value.searchFamiliesLoading = false;
    } else {
      pageData.value.selectedFamilies = [
        ...pageData.value.participants.filter.family
      ].filter(item => item.selected);
    }
  };

  const selectAllGenders = () => {
    pageData.value.participants.filter.gender = pageData.value.participants.filter.gender.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.pager.page = 1;
    updateRouters();
    getParticipants(1);
  };

  const resetGenders = () => {
    pageData.value.participants.filter.gender = pageData.value.participants.filter.gender.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    updateRouters();
    getParticipants(1);
  };

  const selectAllPEvents = () => {
    pageData.value.participants.filter.events = pageData.value.participants.filter.events.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedPEvents = [
      ...pageData.value.participants.filter.events
    ].filter(item => item.selected);
    updateRouters();
    getParticipants(1);
  };

  const resetPEvents = () => {
    pageData.value.participants.filter.events = pageData.value.participants.filter.events.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.selectedPEvents = [];
    pageData.value.participants.pager.page = 1;
    updateRouters();
    getParticipants(1);
  };

  const searchPEvents = async (searchValue: string) => {
    pageData.value.searchPEventsLoading = true;
    // if (searchValue == "") {
    //   pageData.value.participants.filter.events = await ApiHelper.getEventOptions(
    //     {
    //       getAll: 1
    //     }
    //   );
    // } else {
    //   pageData.value.participants.filter.events = await ApiHelper.getEventOptions(
    //     {
    //       key: searchValue
    //     }
    //   );
    // }
    let list: any = [];
    if (!pageData.value.participants.filter.events.length) {
      list = await ApiHelper.getEventOptions({
        getAll: 1
      });
    } else {
      list = [...pageData.value.participants.filter.events];
    }
    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.participants.filter.events = list;

    const eventIds = (context.root.$route.query.peventIds || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.participants.filter.events = pageData.value.participants.filter.events.map(
      item => ({
        ...item,
        selected: eventIds.includes(item.id)
      })
    );
    pageData.value.searchPEventsLoading = false;
  };

  const updateSelectedPEvents = async (init = false) => {
    if (init) {
      // pageData.value.searchPEventsLoading = true;
      // const searchPEventsValue =
      //   context.root.$route.query.searchPEventsValue || "";
      // if (searchPEventsValue != "") {
      //   pageData.value.searchPEventsValue.value = searchPEventsValue;
      //   pageData.value.participants.filter.events = await ApiHelper.getEventOptions(
      //     {
      //       key: searchPEventsValue,
      //       getAll: 1
      //     }
      //   );
      // }
      // const eventIds = (context.root.$route.query.peventIds || "")
      //   .split(",")
      //   .map((id: string) => parseInt(id));
      // if (eventIds.length) {
      //   pageData.value.participants.filter.events = pageData.value.participants.filter.events.map(
      //     item => ({
      //       ...item,
      //       selected: eventIds.includes(item.id)
      //     })
      //   );
      //   pageData.value.selectedPEvents = [
      //     ...pageData.value.participants.filter.events
      //   ].filter(item => item.selected);
      // }
      // pageData.value.searchPEventsLoading = false;

      const searchValue = context.root.$route.query.searchPEventsValue || "";
      if (searchValue != "") {
        await searchPEvents(searchValue);
      }
    }
    pageData.value.selectedPEvents = [
      ...pageData.value.participants.filter.events
    ].filter(item => item.selected);
  };

  const selectAllCabins = () => {
    pageData.value.participants.filter.cabin = pageData.value.participants.filter.cabin.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedCabins = [
      ...pageData.value.participants.filter.cabin
    ].filter(item => item.selected);
    updateRouters();
    getParticipants(1);
  };

  const resetCabins = () => {
    pageData.value.participants.filter.cabin = pageData.value.participants.filter.cabin.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.selectedCabins = [];
    pageData.value.participants.pager.page = 1;
    updateRouters();
    getParticipants(1);
  };

  const searchCabins = async (searchValue: string) => {
    pageData.value.searchCabinsLoading = true;
    let list: any = [];
    if (!pageData.value.participants.filter.cabin.length) {
      list = await ApiHelper.getCabinsOptions(0, {
        getAll: 1,
        includeUnassignedOption: false
      });
    } else {
      list = [...pageData.value.participants.filter.cabin];
    }

    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.participants.filter.cabin = list;

    const selectedCabinIds = (context.root.$route.query.selectedCabinIds || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.participants.filter.cabin = pageData.value.participants.filter.cabin.map(
      item => ({
        ...item,
        selected: selectedCabinIds.includes(item.id)
      })
    );
    pageData.value.searchCabinsLoading = false;
  };

  const updateSelectedCabins = async (init = false) => {
    if (init) {
      const searchValue = context.root.$route.query.searchCabinsValue || "";
      if (searchValue != "") {
        await searchCabins(searchValue);
      }
    }
    pageData.value.selectedCabins = [
      ...pageData.value.participants.filter.cabin
    ].filter(item => item.selected);
  };

  const inSelectedProfileIds = (item: any) => {
    const selectedId = item.profileId || 0;
    const existedItem = pageData.value.selectedProfileIds.find(
      (id: any) => id == selectedId
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const exportMedicalLogs = async () => {
    ApiHelper.setDataLoading(true);
    const selectedProfileIds = pageData.value.selectedProfileIds;
    const selectedEventIds = pageData.value.selectedPEvents.map(
      item => item.id
    );
    const result = await ApiHelper.callApi(
      "get",
      "/medicals",
      {},
      {
        getAll: 1,
        order: 9,
        direction: 2,
        type: 2,
        profileIds: selectedProfileIds.join(","),
        eventIds: selectedEventIds.join(",")
      }
    );
    if (result.status === 1) {
      const resultData = result.data || {};
      const medicalLogs = resultData.items || [];
      const exportData = medicalLogs.map((item: any) => {
        return [
          ApiHelper.getFullName(item.firstName, item.lastName),
          item.notes || "",
          ApiHelper.getSymptomTexts(item.symptomsList || []),
          item.eventName || "",
          item.startDateFormatted
        ];
      });
      const headers = [
        "PROFILE NAME",
        "SUMMARY",
        "SYMPTOM",
        "EVENT",
        "DATE & TIME"
      ];
      const fileName = [`profiles${selectedProfileIds.join("-")}-medical-logs`];
      fileName.push(moment().format("MMDDYYYY_HHMMSS"));
      await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));
      pageData.value.selectedProfileIds = [];
    }
    ApiHelper.setDataLoading(false);
  };

  const autoFillMed = (item: any) => {
    const isMedicalLog = 1;
    const isMedicationDispensed =
      item.incidentType == MedicalIncidentType.MedicationDispensed;
    if (isMedicalLog && isMedicationDispensed) {
      const latestLog = pageData.value.followUpLogs[0] || null;
      const latestMed = latestLog?.med || "";
      let medicationName = "";
      if (latestMed) {
        medicationName = latestMed;
      } else {
        medicationName = item.medicationName || "";
      }
      pageData.value.med.value = medicationName;
    }
  };

  const showFollowUpModal = async (item: any) => {
    // const followupDate = moment(item.followupDate).isValid()
    //   ? moment(item.followupDate.replace("+00:00", "")).toDate()
    //   : undefined;
    pageData.value.selectedMedicalFollowUpId = item.medicalId || 0;
    // pageData.value.followupDate.value = followupDate;
    pageData.value.followupDate.value = undefined;
    pageData.value.followupDate.error = "";
    // pageData.value.followupNotes.value = item.followupNotes || "";
    pageData.value.followupNotes.value = "";
    pageData.value.followupNotes.error = "";
    pageData.value.resolved = false;
    pageData.value.med.value = "";
    pageData.value.followupNotes.required = true;
    pageData.value.followupInfoVisible = true;

    // get followup logs
    try {
      ApiHelper.setDataLoading(true);
      pageData.value.followUpLogs = [];
      const result = await ApiHelper.callApi(
        "get",
        `/medicals/followupLogs`,
        {},
        {
          medicalId: item.medicalId || 0
        }
      );
      if (result.status === 1) {
        pageData.value.followUpLogs = result.data.followUpLogs;

        // init a value for followup date based on latest followup
        if (pageData.value.followUpLogs.length) {
          const latest = pageData.value.followUpLogs[0];
          const followupDate = moment(latest.followupDate).isValid()
            ? moment(latest.followupDate.replace("+00:00", "")).toDate()
            : undefined;
          pageData.value.followupDate.value = followupDate;
        }

        autoFillMed(item);
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const saveFollowUp = async () => {
    // validate
    let isValid = true;
    const newDate = pageData.value.followupDate.value;
    if (newDate == undefined) {
      pageData.value.followupDate.error = "Follow up date & time is required!";
      isValid = false;
    } else if (pageData.value.followUpLogs.length) {
      // new date should be greater than current date
      const latest = pageData.value.followUpLogs[0];
      const currentDate = latest.followupDate
        ? moment(latest.followupDate.replace(/\.000Z|\+00:00/, "")).toDate()
        : undefined;
      if (
        currentDate != undefined &&
        moment(currentDate).isValid() &&
        moment(newDate).isValid() &&
        moment(newDate).isBefore(moment(currentDate))
      ) {
        pageData.value.followupDate.error =
          "Follow up date & time should be greater than the latest follow up date & time!";
        isValid = false;
      }
    }

    // notes is required
    const notes = pageData.value.followupNotes.value;
    if (pageData.value.followupNotes.required && notes == "") {
      pageData.value.followupNotes.error = "Follow up notes is required!";
      isValid = false;
    }

    if (isValid) {
      ApiHelper.setDataLoading(true);
      let followupDate: any = pageData.value.followupDate.value;
      followupDate = moment(followupDate).isValid()
        ? moment(followupDate).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
        : "";
      const requestObject = {
        medicalId: pageData.value.selectedMedicalFollowUpId,
        followupDate,
        followupNotes: pageData.value.followupNotes.value,
        resolved: pageData.value.resolved,
        med: pageData.value.med.value
      };
      const result = await ApiHelper.callApi(
        "put",
        "/medicals/followup/",
        requestObject
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        pageData.value.followupInfoVisible = false;
        ApiHelper.showSuccessMessage("Saved follow up successfully!");
        await getFollowUp(
          pageData.value.resolved ? 1 : pageData.value.pager.page
        );
      }
    }
  };

  const changeResolved = () => {
    const required = pageData.value.resolved ? false : true;
    pageData.value.followupNotes.required = required;
    if (!required) {
      pageData.value.followupNotes.error = "";
    }
  };

  const reloadData = (type: string) => {
    if (type == "dispense") {
      getMedicalLogs(1);
    }
  };

  // init data
  (async () => {
    const query = context.root.$route.query;
    // follow up
    if (query.page != undefined) {
      pageData.value.pager.page = parseInt(query.page) || 1;
    }
    if (query.order != undefined) {
      pageData.value.sort.order = query.order;
    }
    if (query.direction != undefined) {
      pageData.value.sort.direction = query.direction;
    }
    if (query.followUpNotes != undefined) {
      pageData.value.filter.followUpNotes = query.followUpNotes;
    }
    if (query.minDate != undefined) {
      pageData.value.filter.minDate = query.minDate;
    }
    if (query.maxDate != undefined) {
      pageData.value.filter.maxDate = query.maxDate;
    }
    if (query.eventIds) {
      pageData.value.selectedEvents = query.eventIds
        .split(",")
        .filter((id: string) => parseInt(id) > 0)
        .map((id: number) => ({
          id,
          text: ""
        }));
    }
    // medical logs
    if (query.mlpage != undefined) {
      pageData.value.medicalLogs.pager.page = parseInt(query.mlpage) || 1;
    }
    if (query.mlorder != undefined) {
      pageData.value.medicalLogs.sort.order = query.mlorder;
    }
    if (query.mldirection != undefined) {
      pageData.value.medicalLogs.sort.direction = query.mldirection;
    }
    if ((query.incidentType || "") != "") {
      const incidentTypeIds = (query.incidentType || "").split(",");
      pageData.value.filter.incidentType = pageData.value.filter.incidentType.map(
        item => ({
          ...item,
          selected: incidentTypeIds.includes(item.id)
        })
      );
    }
    if (query.medicalLogsSummary != "") {
      pageData.value.medicalLogs.filter.medicalLogsSummary =
        query.medicalLogsSummary;
    }
    if (query.medicalLogsProfile != "") {
      pageData.value.medicalLogs.filter.medicalLogsProfile =
        query.medicalLogsProfile;
    }
    if (query.selectedSymptomIds) {
      pageData.value.selectedSymptoms = query.selectedSymptomIds
        .split(",")
        .filter((id: string) => parseInt(id) > 0)
        .map((id: number) => ({
          id,
          text: ""
        }));
    }
    if (query.minDateMedicalLogs != undefined) {
      pageData.value.medicalLogs.filter.minDate = query.minDateMedicalLogs;
      pageData.value.medicalLogs.filter.date = query.minDateMedicalLogs.replace(
        /-/g,
        ""
      );
    }
    pageData.value.medicalLogs.filter.date += "-";
    if (query.maxDateMedicalLogs != undefined) {
      pageData.value.medicalLogs.filter.maxDate = query.maxDateMedicalLogs;
      pageData.value.medicalLogs.filter.date += query.maxDateMedicalLogs.replace(
        /-/g,
        ""
      );
    }
    if (pageData.value.medicalLogs.filter.date == "-") {
      pageData.value.medicalLogs.filter.date = "";
    }
    // participants list
    // if (query.pPage != undefined) {
    //   pageData.value.participants.pager.page = parseInt(query.pPage) || 1;
    // }
    // if (query.pOrder != undefined) {
    //   pageData.value.participants.sort.order = query.pOrder;
    // }
    // if (query.pDirection != undefined) {
    //   pageData.value.participants.sort.direction = query.pDirection;
    // }
    // const genderIds = (context.root.$route.query.gender || "").split(",");
    // for (const item of pageData.value.participants.filter.gender) {
    //   if (genderIds.indexOf(item.id) != -1) {
    //     item.selected = true;
    //   }
    // }
    // if (query.peventIds) {
    //   pageData.value.selectedPEvents = query.peventIds
    //     .split(",")
    //     .filter((id: string) => parseInt(id) > 0)
    //     .map((id: number) => ({
    //       id,
    //       text: ""
    //     }));
    // }
    // if (query.selectedCabinIds) {
    //   pageData.value.selectedCabins = query.selectedCabinIds
    //     .split(",")
    //     .filter((id: string) => parseInt(id) > 0)
    //     .map((id: number) => ({
    //       id,
    //       text: ""
    //     }));
    // }

    const threads = [
      getFollowUp(pageData.value.pager.page),
      getMedicalLogs(pageData.value.medicalLogs.pager.page)
      // getParticipants(pageData.value.participants.pager.page)
    ];
    await Promise.all(threads);
    updateSelectedSymptoms(true);
    updateSelectedEvents(true);
    updateSelectedFamilies(true);
    // updateSelectedPEvents(true);
    updateSelectedCabins(true);
  })();

  return {
    updateRouters,
    // sort
    updateSortValue,
    // errors,
    pageData,
    showStatusDropdown,
    // filters
    // pager
    gotoPage,
    onClickPrev,
    onClickNext,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    updateFilterValue,
    // popups
    getFollowUp,
    getMedicalLogs,
    selectAllSymptoms,
    resetSymptoms,
    searchSymptoms,
    updateSelectedSymptoms,
    selectAllEvents,
    resetEvents,
    searchEvents,
    updateSelectedEvents,
    selectAllFamilies,
    resetFamilies,
    searchFamilies,
    updateSelectedFamilies,
    selectAllGenders,
    resetGenders,
    getParticipants,
    selectAllPEvents,
    resetPEvents,
    searchPEvents,
    updateSelectedPEvents,
    selectAllCabins,
    resetCabins,
    searchCabins,
    updateSelectedCabins,
    inSelectedProfileIds,
    exportMedicalLogs,
    showFollowUpModal,
    saveFollowUp,
    changeResolved,
    reloadData
  };
}
