



































































































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FormTagAutoComplete from "@/components/Form/FormTagAutoComplete.vue";
import { ApiHelper } from "@/helpers";
import { TagTypeUse } from "@/helpers/ApiHelper";

export default defineComponent({
  name: "HeaderProfiles",
  props: {
    total: Number,
    title: String,
    filters: Object,
    tagFilter: Object,
    userSettings: {
      type: Object,
      default: () => ({
        profilesCustomView: []
      })
    },
    hideTagFilter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    HeaderTabs,
    HeaderActions,
    Select2,
    FormTagAutoComplete
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const foundTags = ref<any[]>([]);
    const isShowFilters = ref<boolean>(false);
    const customizeVisible = ref<boolean>(false);

    const hideFilters = () => {
      isShowFilters.value = false;
    };
    const toggleFilters = () => {
      /*if (props.filters && props.filters.list.length > 0) {
        isShowFilters.value = !isShowFilters.value;
      } else {
        isShowFilters.value = false;
      }*/
      isShowFilters.value = !isShowFilters.value;
    };

    const suggestTags = async (key: string) => {
      const notInIds = props.tagFilter
        ? props.tagFilter.options.map((item: any) => item.id).join(",")
        : "";
      foundTags.value = [];
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          key: key,
          notInIds: notInIds,
          typeUse: TagTypeUse.PROFILE
        }
      );
      if (result.status === 1) {
        const tags = result.data.tags || [];
        if (tags.length) {
          foundTags.value = tags.map((item: any) => ({
            id: item.id,
            html: `${item.text}`,
            text: item.text,
            data: item
          }));
        }
      }
      isACILoading.value = false;
    };
    const selectTag = (data: any) => {
      if (props.tagFilter) {
        props.tagFilter.options.push({
          id: data.id,
          text: data.text,
          selected: true
        });
        props.tagFilter.onChange();
      }
    };
    const removeTag = (data: any) => {
      if (props.tagFilter) {
        props.tagFilter.options = props.tagFilter.options.filter(
          (item: any) => {
            return data.id !== item.id;
          }
        );
        props.tagFilter.onChange();
      }
    };

    const toggleCustomize = () => {
      customizeVisible.value = !customizeVisible.value;
    };

    const hideCustomize = () => {
      customizeVisible.value = false;
    };

    return {
      foundTags,
      suggestTags,
      removeTag,
      selectTag,
      isACILoading,
      isShowFilters,
      hideFilters,
      toggleFilters,
      toggleCustomize,
      customizeVisible,
      hideCustomize
    };
  }
});
