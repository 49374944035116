import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import { dollarFormat } from "../../helpers/ApiHelper";

export function useProfileRoommatesStore(context: any) {
  const query = context.root.$route.query;
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    title: "Roommates",
    subTitle: ""
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    roommates: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
    paying: boolean;
    transferVisible: boolean;
    searchProfilesLoading: boolean;
    participantInfo: any;
    roommateStatusEditVisible: boolean;
    isProcessingRoommateStatus: boolean;
    roommateStatusList: {
      required: boolean;
      label: string;
      placeholder: string;
      error: string;
      type: string;
      value: string;
      options: any;
    };
    selectedRoommateRequest: any;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: {
      key: ""
    },
    roommates: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: "",
    paying: false,
    transferVisible: false,
    searchProfilesLoading: false,
    participantInfo: {},
    roommateStatusEditVisible: false,
    isProcessingRoommateStatus: false,
    roommateStatusList: {
      required: false,
      label: "Select status",
      placeholder: "- Select status -",
      error: "",
      type: "select",
      value: "",
      options: [
        {
          id: 0,
          text: ApiHelper.getRoommateStatusText(0)
        },
        {
          id: 1,
          text: ApiHelper.getRoommateStatusText(1)
        },
        {
          id: 2,
          text: ApiHelper.getRoommateStatusText(2)
        }
      ]
    },
    selectedRoommateRequest: {}
  });

  // const isACILoading = ref<boolean>(false);

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        params: {
          profileId: selectedProfileId.toString()
        },
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.key || undefined,
          id: query.id,
          eventId: query.eventId,
          pTypeId: query.pTypeId
        }
      })
      .catch((err: any) => {
        // nothing
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = query.page ? parseInt(query.page) : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      `/participants/roommates`,
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        profileId: selectedProfileId,
        eventId: query.eventId,
        pTypeId: query.pTypeId,
        participantId: query.id
          ? Number.parseInt(query.id + "", 10)
          : Number.parseInt(query.participantId + "", 10)
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      // const list = result.data.roommates.map((item: any) => {
      //   const status = item.joinedGroupStatus.split(",");
      //   const statusText = status.map((s: any) =>
      //     ApiHelper.getCabinStatusText(parseInt(s))
      //   );
      //   item.joinedGroupStatusText = statusText.join(", ");
      //   return {
      //     id: item.profileId,
      //     isChecked: false,
      //     profileId: item.profileId || 0,
      //     gender: item.gender || 0,
      //     age: item.age || 0,
      //     logo: item.logo || "",
      //     firstName: item.firstName || "",
      //     lastName: item.lastName || "",
      //     eventName: item.eventName || "",
      //     joinedGroup: item.joinedGroup || "",
      //     joinedGroupStatus: item.joinedGroupStatus || "",
      //     joinedGroupStatusText: statusText.join(", "),
      //     eventId: item.eventId || 0,
      //     extension: (item.filePath || "").split(".").pop()
      //   };
      // });
      pageData.value.roommates = result.data.roommates;
    } else if (result.status === -1) {
      ApiHelper.gotoPage(context, {
        name: "Login",
        query: { destination: "/settings/users" }
      });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops..");
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.key !== "") {
      filters.push({
        label: "KEYWORD",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const pairParticipantPopup = ref<{
    show: boolean;
    selectedPair: any;
    // confirmModal: {
    //   show: boolean;
    //   message: string;
    // };
    controls: {
      pair: {
        isInline: boolean;
        required: boolean;
        label: string;
        style: string;
        value: string;
        error: string;
        options: object[];
      };
    };
    actions: any;
  }>({
    show: false,
    selectedPair: undefined,
    // confirmModal: {
    //   show: false,
    //   message: ""
    // },
    controls: {
      pair: {
        isInline: true,
        required: false,
        label: "Participant",
        style: "custom",
        value: "",
        error: "",
        options: []
      }
    },
    actions: {
      onSubmit: async () => {
        let hasError = false;
        if (
          !pairParticipantPopup.value.selectedPair ||
          pairParticipantPopup.value.selectedPair.text == undefined
        ) {
          pairParticipantPopup.value.controls.pair.error =
            "Please enter a participant name";
          hasError = true;
        }
        if (!hasError) {
          // check if participant has dorm
          const selectedParticipantId =
            pairParticipantPopup.value.selectedPair.data.id || 0;
          let confirmed = true;
          if (selectedParticipantId) {
            const validateData = await ApiHelper.callApi(
              "post",
              "/profiles/checkDorm",
              {
                // pairProfileId: pairParticipantPopup.value.selectedPair.id,
                // eventId: sidebarData.value.eventId,
                // pTypeId: pageData.value.participantInfo.participantTypeId || 0,
                // pTypeId: parseInt(query.pTypeId) || 0
                participantId: selectedParticipantId
              }
            );
            if (validateData.status === 1 && validateData.message !== "") {
              // show confirm modal
              // pairParticipantPopup.value.confirmModal.show = true;
              // pairParticipantPopup.value.confirmModal.message =
              //   validateData.message;

              const confirm = await context.root.$swal({
                html: validateData.message,
                showCancelButton: true
                // customClass: {
                //   container: "swal2-actions-p0",
                //   denyButton: "swal2-blue-btn"
                // }
              });
              if (!confirm.isConfirmed) {
                confirmed = false;
              }
            }
          }

          if (confirmed) {
            ApiHelper.setDataLoading(true);
            const result = await ApiHelper.callApi("post", "/profiles/pair", {
              profileId: sidebarData.value.profileId,
              pairProfileId: pairParticipantPopup.value.selectedPair.id,
              eventId: sidebarData.value.eventId,
              pTypeId: pageData.value.participantInfo.participantTypeId || 0
              // pTypeId: parseInt(query.pTypeId) || 0
            });
            ApiHelper.setDataLoading(false);
            ApiHelper.showSuccessMessage(result.message, "Pair Participant");
            pairParticipantPopup.value.actions.onReset();
            loadData(1);
          }
        }
      },
      // onConfirmed: async () => {
      //   // console.log("confirmed");
      //   ApiHelper.setDataLoading(true);
      //   const result = await ApiHelper.callApi("post", "/profiles/pair", {
      //     profileId: sidebarData.value.profileId,
      //     pairProfileId: pairParticipantPopup.value.selectedPair.id,
      //     eventId: sidebarData.value.eventId
      //   });
      //   ApiHelper.setDataLoading(false);
      //   if (result.status === 1) {
      //     ApiHelper.showSuccessMessage(result.message, "Pair Participant");
      //   } else {
      //     ApiHelper.showSuccessMessage(result.message, "Pair Participant");
      //   }
      //   pairParticipantPopup.value.actions.onReset();
      //   loadData(1);
      // },
      onSelectPair: (item: any) => {
        pairParticipantPopup.value.selectedPair = item;
      },
      onReset: () => {
        pairParticipantPopup.value.controls.pair.options = [];
        pairParticipantPopup.value.selectedPair = undefined;
        pairParticipantPopup.value.show = false;
        pairParticipantPopup.value.controls.pair.error = "";
        // pairParticipantPopup.value.confirmModal.show = false;
        // pairParticipantPopup.value.confirmModal.message = "";
      }
    }
  });

  const searchProfiles = async (key: string) => {
    pairParticipantPopup.value.controls.pair.error = "";
    pageData.value.searchProfilesLoading = true;
    const result = await ApiHelper.callApi(
      "get",
      "/participants/availableRoommate",
      {},
      {
        eventId: query.eventId,
        pTypeId: pageData.value.participantInfo.participantTypeId || 0,
        key,
        ignoreProfileId: selectedProfileId
        // pTypeId: query.pTypeId,
        // ignoreProfileIDs: selectedProfileId,
        // ignoreRmOfProfileId: selectedProfileId,
        // ignoreDenied: 1,
        // getAll: 1
      }
    );
    if (result.status === 1) {
      pairParticipantPopup.value.controls.pair.options = result.data.participants.map(
        (item: any) => ({
          id: item.profileId,
          text: ApiHelper.getFullName(item.firstName, item.lastName),
          data: item
        })
      );
    }
    pageData.value.searchProfilesLoading = false;
  };

  const updateRoommateStatus = async () => {
    if (
      pageData.value.selectedRoommateRequest.requestStatus ==
      pageData.value.roommateStatusList.value
    ) {
      return;
    }

    try {
      pageData.value.isProcessingRoommateStatus = true;
      const selected = pageData.value.selectedRoommateRequest;
      const inList = pageData.value.roommates.find(
        (item: any) => item.requestId == selected.requestId
      );
      if (inList) {
        const result = await ApiHelper.callApi(
          "patch",
          "/profiles/roommateStatus",
          {
            requestId: selected.requestId,
            eventId: parseInt(query.eventId),
            ptypeId: parseInt(query.pTypeId),
            invitedProfileId: selected.invitedProfileId,
            profileId: selected.profileId,
            toParticipantId: selected.toParticipantId || 0,
            invitedCode: selected.invitedCode || "",
            requestStatus: pageData.value.roommateStatusList.value
          }
        );
        if (result.status == 1) {
          ApiHelper.showSuccessMessage(
            "Changed roommate request status successfully."
          );
          inList.requestStatus = pageData.value.roommateStatusList.value;
          pageData.value.roommateStatusEditVisible = false;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      pageData.value.isProcessingRoommateStatus = false;
    }
  };

  // init data
  (async () => {
    loadData(1);
    pageData.value.filter.key = context.root.$route.query.key || "";

    const participantId =
      Number.parseInt(context.root.$route.query.id, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        const participant = result.data || {};
        pageData.value.participantInfo = participant;

        headerData.value.subTitle =
          ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          ) +
            " - " +
            result.data.eventName || "";

        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.eventId = result.data.eventId || 0;
        sidebarData.value.eventName = result.data.eventName || "";
        sidebarData.value.pTypeName = result.data.participantTypeName || "";
        sidebarData.value.status = result.data.participantStatus || 0;
        sidebarData.value.paidAmount = result.data.totalPaid || 0;
        sidebarData.value.paidAmountFormatted = dollarFormat(
          sidebarData.value.paidAmount
        );
        sidebarData.value.financials = {
          totalPaid: sidebarData.value.paidAmount
        };

        sidebarData.value.isLoading = false;
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
      }
    }
  })();

  return {
    sidebarData,
    pairParticipantPopup,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    searchProfiles,
    updateRoommateStatus
  };
}
