import { ref } from "@vue/composition-api";
import { PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";

import moment from "moment";
import { FinancialListRows } from "@/helpers/estimateNoOfListRows";

export function useFinancialPlansStore(context: any) {
  const headerData = ref({
    title: "",
    subTitle: "Financial"
  });
  const filterData = ref<{
    key: string;
    status: SelectOption[];
    balance: string;
    minBalance: string;
    maxBalance: string;
    eventId: number;
    profileId: number;
  }>({
    key: context.root.$route.query.key || "",
    status: [
      {
        id: "active",
        text: "Active",
        selected: false
      },
      {
        id: "finished",
        text: "Finished",
        selected: false
      },
      {
        id: "canceled",
        text: "Cancelled",
        selected: false
      }
    ],
    balance:
      (context.root.$route.query.minBalance || "") +
      "-" +
      (context.root.$route.query.maxBalance || ""),
    minBalance: context.root.$route.query.minBalance || "",
    maxBalance: context.root.$route.query.maxBalance || "",
    eventId:
      parseInt(context.root.$route.query.event + "") ||
      parseInt(context.root.$route.query.eventId + "") ||
      0,
    profileId:
      parseInt(context.root.$route.query.profile + "") ||
      parseInt(context.root.$route.query.profileId + "") ||
      0
  });
  const currentRoute = "FinancialPlans";
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
      totalAmount: string;
    };
  }>({
    isLoading: true,
    skip: 0,
    take: FinancialListRows("FinancialPlans"),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: filterData.value,
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: [],
      totalAmount: ""
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: currentRoute,
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.key || undefined,
          status:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.status
            ) || "",
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          profile: (pageData.value.filter.profileId || 0) + "",
          event: (pageData.value.filter.eventId || 0) + ""
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/plans",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          planId: context.root.$route.query.key || "",
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || "",
          status:
            context.root.$route.query.status === undefined
              ? "active"
              : context.root.$route.query.status || "",
          eventId: pageData.value.filter.eventId,
          profileId: pageData.value.filter.profileId,
          getInfo: "totalPlan"
        }
      );

      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items.map((item: any) => {
          item.amountFormatted = ApiHelper.dollarFormat(item.amount);
          item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
          item.transactionTSFormatted = moment(item.transactionTS).format(
            "MMM DD, YYYY"
          );
          return item;
        });
        ApiHelper.setDataLoading(false);
        pageData.value.pager.totalAmount = result.totalPlansFormatted || "";
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    })();
    (async () => {
      if (filterData.value.eventId) {
        const result = await ApiHelper.callApi(
          "get",
          "/events/" + filterData.value.eventId + "/info",
          {},
          {}
        );
        if (result.status === 1) {
          const eventInfo = result.data || {};
          const eventName = eventInfo.name;
          headerData.value.title = eventName + " - Plans";
        }
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // init data
  (async () => {
    loadData(1);
  })();

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.key) {
      filters.push({
        label: "KEY",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          updateRouters();
        }
      });
    }

    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.minBalance || pageData.value.filter.maxBalance) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "key": {
        pageData.value.filter.key = value;
        break;
      }
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("-");
        pageData.value.filter.minBalance = balanceArr[0];
        pageData.value.filter.maxBalance = balanceArr[1];
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const updateStatusFilter = () => {
    const selectedStatusOptions = context.root.$route.query.status
      ? context.root.$route.query.status.split(",")
      : [];
    filterData.value.status.map(item => {
      if (selectedStatusOptions.includes(item.id)) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      if (
        item.id == "active" &&
        context.root.$route.query.status === undefined
      ) {
        item.selected = true;
      }
    });
  };

  (async () => {
    updateStatusFilter();
  })();

  return {
    updateStatusFilter,
    loadData,
    headerData,
    pageData,
    updateRouters,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    // pager
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems
  };
}
