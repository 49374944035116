import { render, staticRenderFns } from "./NavProfileEvents.vue?vue&type=template&id=a9982014&scoped=true&v-show=showSidebar&"
import script from "./NavProfileEvents.vue?vue&type=script&lang=ts&"
export * from "./NavProfileEvents.vue?vue&type=script&lang=ts&"
import style0 from "./NavProfileEvents.vue?vue&type=style&index=0&id=a9982014&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9982014",
  null
  
)

export default component.exports