import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import moment from "moment";
import Vue from "vue";
import LeaderLine from "leader-line-vue";
import { Genders } from "@/helpers/ApiHelper";

export function useEventCabinDetailsStore(context: any) {
  const selectedGroupId = parseInt(context.root.$route.params.cabinId) || 0;
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const headerData = ref({
    title: "",
    subTitle: ""
  });
  const cabinData = ref<{
    ageGroup1: number;
    ageGroup2: number;
    ageGroup3: number;
  }>({
    ageGroup1: 0,
    ageGroup2: 0,
    ageGroup3: 0
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      participant: {
        label?: string;
        error: string;
        type: string;
        value: string;
        options: any[];
      };
      comment: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      status: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
    };
  }>({
    isLoading: true,
    controls: {
      participant: {
        error: "",
        type: "text",
        value: "",
        options: []
      },
      comment: {
        error: "",
        type: "text",
        value: ""
      },
      status: {
        error: "",
        type: "text",
        value: "0"
      }
    }
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    arrGenders: SelectOption[];
    arrCabins: SelectOption[];
    arrTribes: SelectOption[];
    openItemId: number;
    items: {
      id: number;
      isChecked: boolean;
      logo: string;
      firstName: string;
      lastName: string;
      age: string;
      gender: string;
      balance: string;
      events: string;
      familyName: string;
      roommatesIds: number[];
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      status: SelectOption[];
      profile: string;
      balance: string;
      gender: SelectOption[];
      cabin: SelectOption[];
      tribe: string;
      age: string;
      minAge: string;
      maxAge: string;
      minBalance: string;
      maxBalance: string;
      date: string;
      minDate: string;
      maxDate: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    selectedParticipants: object[];
    availableParticipants: object[];
    cabinStatus: number;
    roommateRequests: any;
    linkedEntityCabin: any;
  }>({
    isLoading: true,
    skip: 0,
    take: 8,
    activeTab: "",
    arrGenders: [],
    arrCabins: [],
    arrTribes: [],
    openItemId: 0,
    sort: {
      order: "2", // profile name
      direction: "1"
    },
    filter: {
      status: [],
      profile: "",
      balance: "",
      gender: [],
      cabin: [],
      tribe: "",
      age: "",
      minAge: "",
      maxAge: "",
      minBalance: "",
      maxBalance: "",
      date: "",
      minDate: "",
      maxDate: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    selectedParticipants: [],
    availableParticipants: [],
    cabinStatus: 0,
    roommateRequests: [],
    linkedEntityCabin: {}
  });
  const lines = ref<
    {
      key: string;
      line: any;
    }[]
  >([]);
  const LINE_DEFAULT_OPTIONS = {
    color: "#c2c0c0",
    size: 2,
    dash: { animation: false },
    startPlug: "behind",
    endPlug: "behind",
    startSocketGravity: [-100, 0],
    endSocketGravity: [-100, 0]
  };
  const LINE_HOVER_OPTION = {
    color: "#670478",
    size: 2,
    dash: { animation: true, duration: 500 },
    startPlug: "behind",
    endPlug: "behind",
    startSocketGravity: [-100, 0],
    endSocketGravity: [-100, 0]
  };

  const loadData = async (page: number, redrawLines = true) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    try {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hideLines();
      const result = await ApiHelper.callApi(
        "get",
        "/participants",
        {},
        {
          // skip: pageData.value.skip,
          // take: pageData.value.take,
          getAll: 1,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          eventId: context.root.$route.params.eventId
            ? parseInt(context.root.$route.params.eventId)
            : 0,
          statusIds: pageData.value.filter.status
            .filter(tmp => tmp.selected)
            .map(tmp => tmp.id)
            .join(),
          key:
            pageData.value.filter.profile !== ""
              ? pageData.value.filter.profile
              : "",
          gender: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.gender
          ),
          cabinId: (context.root.$route.params.cabinId || "").toString(),
          ageRange: pageData.value.filter.age,
          balanceRange: pageData.value.filter.balance || "",
          minAge: pageData.value.filter.minAge || "",
          maxAge: pageData.value.filter.maxAge || "",
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || "",
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || "",
          getInfo: "roommateRequests"
        }
      );

      const totalCount = result.data.totalCount || 0;
      // pageData.value.pager.totalPages = Math.ceil(
      //   totalCount / pageData.value.take
      // );
      pageData.value.pager.total = totalCount;
      // const pagerList = [];
      // for (let i = 0; i < pageData.value.pager.totalPages; i++) {
      //   const pagerItem: PagerItem = {
      //     label: i + 1 + "",
      //     value: i + 1,
      //     active: i + 1 === page
      //   };
      //   pagerList.push(pagerItem);
      // }
      // pageData.value.pager.items = pagerList;
      pageData.value.roommateRequests = result.data.roommateRequests || [];
      const list = result.data.participants.map((item: any, key: number) => {
        const participantId = item.id || 0;
        const roommates = pageData.value.roommateRequests.filter(
          (t: any) =>
            t.toParticipantId == participantId ||
            t.invitedParticipantId == participantId
        );
        const roommatesIds = roommates.map((rm: any) =>
          rm.toParticipantId == participantId
            ? rm.invitedParticipantId
            : rm.toParticipantId
        );

        return {
          id: parseInt(item.id),
          isChecked: false,
          percent: "90%",
          profileId: item.profileId || 0,
          firstName: item.firstName,
          lastName: item.lastName,
          // family: family,
          gender: ApiHelper.getGenderName(item.gender),
          age: item.age,
          cabin: item.cabin || "",
          dateAssignToCabin:
            moment(item.dateAssignToCabin).format("MM/DD/YY") || "-",
          balance: ApiHelper.dollarFormat(item.balance),
          status: item.linkStatus,
          dateAssignToCabinFormatted: item.dateAssignToCabinFormatted,
          statusText: ApiHelper.getParticipantStatusText(item.status),
          linkNote: item.linkNote || "",
          linkStatus: item.linkStatus || 0,
          logo: item.logo || "",
          roommatesIds
        };
      });
      pageData.value.items = list;

      if (redrawLines) {
        await Promise.all([context.root.$forceUpdate()]);
        // draw lines for roommates
        await ApiHelper.sleep(700);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        drawLines();
      }

      ApiHelper.setDataLoading(false);
    } catch (err) {
      pageData.value.items = [];
      ApiHelper.setDataLoading(false);
    }
  };
  // pager
  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };
  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };
  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("-");
        pageData.value.filter.minBalance = balanceArr[0];
        pageData.value.filter.maxBalance =
          balanceArr[1] !== undefined ? balanceArr[1] : "";
        break;
      }
      case "tribe":
        pageData.value.filter.tribe = value;
        break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        break;
      }
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PROFILE",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    const selectedGenders = pageData.value.filter.gender
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedGenders.length > 0) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    const selectedCabins = pageData.value.filter.cabin
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedCabins.length > 0) {
      filters.push({
        label: "CABIN",
        key: selectedCabins.join("-") + Math.random(),
        value: selectedCabins.join(", "),
        reset: () => {
          pageData.value.filter.cabin.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.age !== "") {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.minAge = "";
          pageData.value.filter.maxAge = "";
          pageData.value.filter.age = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.tribe !== "") {
      filters.push({
        label: "TRIBE",
        key: pageData.value.filter.tribe + Math.random(),
        value: pageData.value.filter.tribe,
        reset: () => {
          pageData.value.filter.tribe = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.balance !== "") {
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: pageData.value.filter.balance,
        reset: () => {
          pageData.value.filter.balance = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.date !== "") {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  // pageData.value.filter.status = ApiHelper.getParticipantStatusOptions();

  pageData.value.filter.status = ApiHelper.getCabinStatusOptions();
  const selectAllStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };

  // pageData.value.filter.gender = ApiHelper.getGenderOptions();
  const selectAllGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };
  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  // (async () => {
  //   pageData.value.filter.cabin = await ApiHelper.getCabinsOptions();
  // })();
  const selectAllCabins = () => {
    pageData.value.filter.cabin.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetCabins = () => {
    pageData.value.filter.cabin.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };

  const onRemoveFromGroup = async (item: any) => {
    const isAgreed = await Vue.swal({
      text: "Are you sure you want to remove this participant out of cabin?",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });

    if (isAgreed) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "delete",
        "/cabins/" + selectedGroupId + "/participant",
        {
          participantId: item.id || 0,
          profileId: item.profileId || 0,
          eventId: selectedEventId
        },
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Removed");
        await loadData(1);
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const loadAvailableParticipants = async (
    key = "",
    gender: SelectOption[] = []
  ) => {
    const result = await ApiHelper.callApi(
      "get",
      "/cabins/participants",
      {},
      {
        eventId: selectedEventId,
        cabinId: selectedGroupId,
        key: key,
        gender: ApiHelper.convertSelectedOptionsToString(
          gender.filter(item => item.selected)
        )
      }
    );
    if (result.status === 1) {
      pageData.value.availableParticipants = (
        result.data.participants || []
      ).map((item: any) => {
        return {
          ...item,
          genderName: ApiHelper.getGenderName(item.gender),
          checked: false
        };
      });
    }
  };

  const popupNewItem = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
    activeTab: string;
    filter: {
      participant: string;
      gender: SelectOption[];
    };
  }>({
    show: false,
    formData: formData,
    activeTab: "",
    filter: {
      participant: "",
      gender: ApiHelper.getGenderOptions()
    },
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      // validate if this cabin ties to a specific gender
      const gender = pageData.value.linkedEntityCabin?.gender || 0;
      if ([Genders.Male, Genders.Female].includes(gender)) {
        const genderName = ApiHelper.getGenderName(gender);
        const participants: any = pageData.value.availableParticipants.filter(
          (item: any) => {
            return item.checked || false;
          }
        );
        if (participants.length) {
          let valid = true;
          for (const item of participants) {
            if ((item.genderName || "") != genderName) {
              valid = false;
            }
          }
          if (!valid) {
            await Vue.swal({
              html: `This cabin ties to ${genderName.toLowerCase()} only. Please check gender of selected participants.`,
              showCloseButton: true,
              closeButtonHtml:
                '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">',
              timer: 5000
            }).then(result => {
              setTimeout(function() {
                $(".swal2-backdrop-hide").addClass("d-none");
              }, 200);
              return result.isConfirmed;
            });
          }
        }
      }

      const participantIDs = pageData.value.availableParticipants
        .filter((item: any) => {
          return item.checked || false;
        })
        .map((item: any) => {
          return `${item.participantId}-${item.profileId}`;
        });
      let confirmMessage =
        "Are you sure you want to add selected participants to this cabin? ";
      // console.log(pageData.value.availableParticipants);
      pageData.value.availableParticipants
        .filter((item: any) => {
          return item.checked || false;
        })
        .forEach((i: any, index) => {
          if (i.pairParticipantId) {
            confirmMessage =
              "You have choice participant who has pair request " +
              "</br>Are you sure you want to add selected participants to this cabin?";
          }
        });
      if (participantIDs.length > 0) {
        const isAgreed = await Vue.swal({
          html: confirmMessage,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });
        if (!isAgreed) {
          return;
        }
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/cabins/" + selectedGroupId + "/participants",
          {
            participantsList: participantIDs.join(","),
            eventId: selectedEventId,
            groupId: selectedGroupId,
            notes: formData.value.controls.comment.value,
            outOfCurrentCabin: true
          },
          {}
        );
        ApiHelper.setDataLoading(false);

        if (result.status == 1) {
          popupNewItem.value.show = false;
          ApiHelper.showSuccessMessage("Added");
          loadData(1);
          loadAvailableParticipants();
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } else {
        ApiHelper.showErrorMessage("Please select a participant");
      }
      pageData.value.selectedParticipants = [];
    }
  });

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const getParticipantHtml = (item: any) => {
    const ret = `
      ${ApiHelper.getFullName(item.firstName, item.lastName)}
      <span class="participant-moreinfo">(${
        item.participantTypeName
      } - ${ApiHelper.getParticipantStatusText(item.status)} - ${moment(
      item.pdatetime
    ).format("MM/DD/YY")})</span>
    `;

    return ret;
  };

  const searchParticipants = async (key: string, eventID: string) => {
    const result = await ApiHelper.callApi(
      "get",
      "/participants/search",
      {},
      {
        eventId: parseInt(eventID),
        key: key,
        notInCabin: context.root.$route.params.cabinId.toString() || "",
        ignoreProfileIDs: pageData.value.selectedParticipants
          .map((item: any) => item.data.profileId)
          .join(",")
      }
    );
    if (result.status === 1) {
      formData.value.controls.participant.options = result.data.participants.map(
        (item: any) => ({
          id: item.id,
          text: ApiHelper.getFullName(item.firstName, item.lastName),
          html: getParticipantHtml(item),
          data: item
        })
      );
    }
  };

  const selectParticipant = (item: any, type = "add") => {
    if (type == "remove") {
      context.root.$delete(
        pageData.value.selectedParticipants,
        pageData.value.selectedParticipants.findIndex(
          (participant: any) => participant.id == item.id
        )
      );
    } else {
      const participantID = item.id.toString() || "";
      const profileID = item.data.profileId || "";
      formData.value.controls.participant.value =
        participantID + "-" + profileID;
      pageData.value.selectedParticipants.push(item);
    }
  };

  const hideLines = () => {
    for (const item of lines.value) {
      item.line?.hide();
    }
  };

  const deleteLines = () => {
    for (const item of lines.value) {
      item.line?.remove();
    }
    lines.value = [];
  };

  const drawLines = () => {
    deleteLines();
    for (const p of pageData.value.items) {
      const participantId = p.id;
      for (const roommatePId of p.roommatesIds) {
        const relatedLine = lines.value.find(item =>
          [
            `${participantId}-${roommatePId}`,
            `${roommatePId}-${participantId}`
          ].includes(item.key)
        );
        if (!relatedLine) {
          const start = $(`#participant${participantId} .achor-point`).get(0);
          const end = $(`#participant${roommatePId} .achor-point`).get(0);
          if (start && end) {
            const key = `${participantId}-${roommatePId}`;
            const line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
            lines.value.push({ key, line });
          }
        }
      }
    }
    $(".leader-line").addClass("lines-in-cabin-detail");
  };

  const resetLines = async (sleep = true) => {
    if (sleep) {
      await ApiHelper.sleep(700);
    }

    // re-draw lines
    const newLines = [];
    for (const item of lines.value) {
      const participantIds = item.key.split("-");
      const fromId = participantIds[0];
      const toId = participantIds[1];
      const start = $(`#participant${fromId} .achor-point`).get(0);
      const end = $(`#participant${toId} .achor-point`).get(0);
      item.line?.remove();
      if (start && end) {
        item.line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
        newLines.push(item);
      }
    }
    $(".leader-line").addClass("lines-in-cabin-detail");
    lines.value = newLines;
  };

  const hilightRelatedLines = (participantId: number, hilight: boolean) => {
    if (!participantId) return;

    // get requests that this participant has sent
    const sentRequest = pageData.value.roommateRequests.filter(
      (item: any) => item.invitedParticipantId == participantId
    );
    if (sentRequest.length) {
      const relatedLineKeys = sentRequest
        .map(
          (item: any) =>
            `${item.invitedParticipantId}-${item.toParticipantId},${item.toParticipantId}-${item.invitedParticipantId}`
        )
        .join(",")
        .split(",");
      const relatedLines = lines.value.filter(item =>
        relatedLineKeys.includes(item.key)
      );

      for (const item of relatedLines) {
        item.line?.setOptions(
          hilight ? LINE_HOVER_OPTION : LINE_DEFAULT_OPTIONS
        );
      }
    }
  };

  const onScroll = async () => {
    // reposition for roommates lines
    for (const item of lines.value) {
      const participantIds = item.key.split("-");
      const fromId = participantIds[0];
      const toId = participantIds[1];
      const start = $(`#participant${fromId} .achor-point`).get(0);
      const end = $(`#participant${toId} .achor-point`).get(0);
      if (start && end) {
        item.line?.position();
      }
    }
  };

  const loadCabinStats = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/cabins/" + context.root.$route.params.cabinId + "/stats",
      {},
      {}
    );
    if (result.status === 1) {
      const groupstats = result.data.groupstats || {};
      cabinData.value.ageGroup1 = groupstats.ageGroup1 || 0;
      cabinData.value.ageGroup2 = groupstats.ageGroup2 || 0;
      cabinData.value.ageGroup3 = groupstats.ageGroup3 || 0;
    }
  };
  const openParticipantsPopup = async () => {
    popupNewItem.value.filter.participant = "";
    ApiHelper.setDataLoading(true);
    await loadCabinStats();
    await loadAvailableParticipants();
    popupNewItem.value.activeTab = "";
    popupNewItem.value.show = true;
    ApiHelper.setDataLoading(false);
  };

  const updateItemStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    if (statusOption.value === 2) {
      const isConfirm = await Vue.swal({
        html: "Are you sure you want to change? ",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/cabins/" + selectedGroupId + "/participant/status",
      {
        participantId: item.id || 0,
        profileId: item.profileId || 0,
        eventId: selectedEventId,
        status: statusOption.value
      },
      {}
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      if (callBack) {
        callBack();
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  // init data
  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      `/cabins/${context.root.$route.params.cabinId}/info?eventId=${selectedEventId}`,
      {},
      {}
    );
    if (result.status === 1) {
      const cabinData = result.data || {};
      headerData.value.title = (cabinData.name || "") + " - " + "Participants";
      pageData.value.cabinStatus = result.data.cabinStatus || 0;
      pageData.value.linkedEntityCabin = result.data.linkedEntityCabin || {};
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      const errorCode = result.errorCode || "";
      if (errorCode == "not_found") {
        context.root.$router.push({
          name: "EventCabins",
          params: {
            eventId: `${selectedEventId}`
          }
        });
      }
    }
  })();
  (async () => {
    pageData.value.arrGenders = ApiHelper.getGenderOptions();
    pageData.value.filter.status = ApiHelper.getCabinStatusOptions();
    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    loadData(1);
  })();

  return {
    loadAvailableParticipants,
    cabinData,
    updateItemStatus,
    openParticipantsPopup,
    // sort
    updateSortValue,
    popupNewItem,
    // actions
    onRemoveFromGroup,
    // page
    headerData,
    pageData,
    showStatusDropdown,
    // filters
    selectAllStatuses,
    resetStatuses,
    selectAllGenders,
    resetGenders,
    selectAllCabins,
    resetCabins,
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    searchParticipants,
    selectParticipant,
    lines,
    hideLines,
    resetLines,
    hilightRelatedLines,
    onScroll,
    drawLines
  };
}
