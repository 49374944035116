import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useSettingsTagsStore(context: any) {
  const headerData = ref({
    title: "Tags",
    subTitle: "Settings"
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
      types: SelectOption[];
      status: string;
      tagCode: string;
      tagDesc: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    searchTypesLoading: boolean;
    searchTypesValue: {
      value: string;
    };
    searchTypes: Function;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      key: context.root.$route.query.key || "",
      types: [],
      status: context.root.$route.query.status || "",
      tagCode: context.root.$route.query.tagCode || "",
      tagDesc: context.root.$route.query.tagDesc || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    searchTypesLoading: false,
    searchTypesValue: {
      value: ""
    },
    searchTypes: async (searchValue: string) => {
      pageData.value.searchTypesLoading = true;
      const typeIds = (context.root.$route.query.types || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.types = await ApiHelper.getTagTypeOptions({
          getAll: 1
        });
      } else {
        pageData.value.filter.types = await ApiHelper.getTagTypeOptions({
          key: searchValue
        });
      }
      pageData.value.filter.types = pageData.value.filter.types.map(
        (item: any) => ({
          ...item,
          selected: typeIds.includes(item.id)
        })
      );
      pageData.value.searchTypesLoading = false;
    }
  });

  const updateRouters = () => {
    ApiHelper.routerReplace(context, "SettingsTags", {
      page: pageData.value.pager.page + "",
      order: pageData.value.sort.order,
      direction: pageData.value.sort.direction,
      key: pageData.value.filter.key || undefined,
      types:
        ApiHelper.convertSelectedOptionsToString(pageData.value.filter.types) ||
        undefined,
      tagCode: pageData.value.filter.tagCode || undefined,
      tagDesc: pageData.value.filter.tagDesc || undefined
    });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/tags",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: context.root.$route.query.key || "",
          types:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.types
            ) || undefined,
          tagCode: context.root.$route.query.tagCode || "",
          tagDesc: context.root.$route.query.tagDesc || ""
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;

        const list = result.data.tags.map((item: any) => {
          return {
            id: item.tagId,
            isChecked: false,
            name: item.tagName || "",
            typeId: item.typeId || 0,
            typeName: item.typeName || "",
            tagCode: item.tagCode || "",
            tagDesc: item.tagDesc || "",
            status: item.isActive || 0,
            statusText: ApiHelper.getStatusText(item.isActive || 0),
            total: item.total || 0
          };
        });

        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/tags" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "types") {
      if (pageData.value.filter.types.length === 0) {
        await pageData.value.searchTypes(pageData.value.searchTypesValue.value);
      }
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "key": {
        pageData.value.filter.key = value;
        break;
      }
      case "tagCode": {
        pageData.value.filter.tagCode = value;
        break;
      }
      case "tagDesc": {
        pageData.value.filter.tagDesc = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.key !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.tagCode !== "") {
      filters.push({
        label: "TAG CODE",
        key: pageData.value.filter.tagCode + Math.random(),
        value: pageData.value.filter.tagCode,
        reset: () => {
          pageData.value.filter.tagCode = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.tagDesc !== "") {
      filters.push({
        label: "DESCRIPTION",
        key: pageData.value.filter.tagDesc + Math.random(),
        value: pageData.value.filter.tagDesc,
        reset: () => {
          pageData.value.filter.tagDesc = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedEvents = pageData.value.filter.types
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.types.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const deleteItem = async (item: any) => {
    Vue.swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then((result: any) => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      if (result.isConfirmed) {
        (async () => {
          ApiHelper.setDataLoading(true);
          const result = await ApiHelper.callApi(
            "delete",
            "/tags/" + item.id,
            {},
            {}
          );
          ApiHelper.setDataLoading(false);
          if (result.status == 1) {
            gotoPage("1");
            ApiHelper.showSuccessMessage("Tag has been deleted.", "Deleted");
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        })();
      }
    });
  };

  const updateItemStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    if (statusOption.value === 0 || statusOption.value === 2) {
      const actionName = statusOption.value === 0 ? "archive" : "delete";
      const isConfirm = await Vue.swal({
        html: `Are you sure you want to ${actionName} this tag?`,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
    }

    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/tags/" + item.id + "/active",
      {
        active: statusOption.value
      },
      {}
    );
    if (result.status === 1) {
      ApiHelper.setDataLoading(false);
      if (pageData.value.pager.page === 1 || statusOption.value !== 2) {
        loadList();
      } else {
        pageData.value.pager.page = 1;
        updateRouters();
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData(1);
  })();

  (async () => {
    pageData.value.searchTypesValue.value =
      context.root.$route.query.searchTypes || "";
    if (context.root.$route.query.types) {
      await pageData.value.searchTypes(pageData.value.searchTypesValue.value);
    }
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    updateRouters,
    updateItemStatus,
    deleteItem,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue
  };
}
