import { ref } from "@vue/composition-api";
import { AlertMessageError } from "@/components/VAlertMessage.vue";
import { ApiHelper, EventHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import FormSelect from "@/types/FormSelect";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import { v4 as uuidv4 } from "uuid";
import Vue from "vue";

export function useSettingsParticipantFormsStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const linkEvents = ref<any[]>([]);
  const headerData = ref({
    title: "To Dos",
    subTitle: "Settings"
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      form: FormSelect;
      events: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
      participantTypes: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
    };
  }>({
    isLoading: true,
    controls: {
      form: {
        error: "",
        type: "select",
        label: "To Do",
        placeholder: "Select To Do",
        value: "",
        options: []
      },
      events: {
        error: "",
        type: "select2",
        value: [],
        options: []
      },
      participantTypes: {
        error: "",
        type: "select2",
        value: [],
        options: []
      }
    }
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      name: string;
      events: SelectOption[];
      unassignedEvent: any;
      types: SelectOption[];
      unassignedType: any;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
    searchPtypesValue: {
      value: string;
    };
    searchPtypes: Function;
    searchPtypesLoading: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "2",
      direction: context.root.$route.query.direction || "1"
    },
    filter: {
      name: context.root.$route.query.name || "",
      events: [],
      unassignedEvent: null,
      types: [],
      unassignedType: null
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: "",
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.events || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    searchPtypesLoading: false,
    searchPtypesValue: {
      value: ""
    },
    searchPtypes: async (searchValue: string) => {
      pageData.value.searchPtypesLoading = true;
      const typeIds = (context.root.$route.query.types || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.types = await ApiHelper.getPtypeOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedType = {
          id: "0",
          text: "UN-ASSIGNED TO PARTICIPANTS",
          selected: typeIds.includes(0)
        };
      } else {
        pageData.value.filter.types = await ApiHelper.getPtypeOptions({
          key: searchValue
        });
        if (typeIds.includes(0)) {
          pageData.value.filter.unassignedType = {
            id: "0",
            text: "UN-ASSIGNED TO PARTICIPANTS",
            selected: typeIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedType = null;
        }
      }
      pageData.value.filter.types = pageData.value.filter.types.map(
        (item: any) => ({
          ...item,
          selected: typeIds.includes(item.id)
        })
      );
      pageData.value.searchPtypesLoading = false;
    }
  });

  const errors = ref<AlertMessageError[]>([]);

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/todos",
          {},
          {
            skip: pageData.value.skip,
            take: pageData.value.take,
            order: parseInt(pageData.value.sort.order),
            direction: parseInt(pageData.value.sort.direction),
            name: context.root.$route.query.name || undefined,
            events: context.root.$route.query.events || undefined,
            types: context.root.$route.query.types || undefined
          }
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          const totalCount = result.data.totalCount || 0;
          pageData.value.pager.totalPages = Math.ceil(
            totalCount / pageData.value.take
          );
          pageData.value.pager.total = totalCount;
          const pagerList = [];
          for (let i = 0; i < pageData.value.pager.totalPages; i++) {
            const pagerItem: PagerItem = {
              label: i + 1 + "",
              value: i + 1,
              active: i + 1 === pageData.value.pager.page
            };
            pagerList.push(pagerItem);
          }
          pageData.value.pager.items = pagerList;
          const list = result.data.todos.map((item: any) => {
            return {
              id: parseInt(item.id),
              isChecked: false,
              name: item.name || "<< unknown >>",
              events: item.events || [],
              participantTypes: item.participantTypes || [],
              allEventsRequired: item.allEventsRequired ? "Yes" : "No",
              isVerify: item.isVerify ? "Yes" : "No",
              jsonData: item.appJson || ""
            };
          });
          pageData.value.items = list;
          formData.value.controls.form.options = list.map((item: any) => {
            return {
              id: item.id.toString(),
              text: item.name
            };
          });
        }
      } catch (err) {
        pageData.value.items = [];
        ApiHelper.setDataLoading(false);
        // if (!isApolloError(err)) {
        //   errors.value = [err];
        //   return;
        // }

        // if (
        //   err.graphQLErrors &&
        //   err.graphQLErrors.findIndex(
        //     gerr =>
        //       gerr.message ===
        //       "Access denied! You don't have permission for this action!"
        //   ) !== -1
        // ) {
        //   context.root.$router.push({ name: "Login" });
        //   return;
        // }

        errors.value = [err];
      }
    })();
  };

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    const typeOptions = [];
    if (pageData.value.filter.unassignedType) {
      typeOptions.push(pageData.value.filter.unassignedType);
    }
    pageData.value.filter.types.map((item: any) => {
      typeOptions.push(item);
    });
    context.root.$router
      .replace({
        name: "SettingsParticipantForms",
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          name: pageData.value.filter.name || undefined,
          events:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          types:
            ApiHelper.convertSelectedOptionsToString(typeOptions) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          searchPtypes: pageData.value.searchPtypesValue.value || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadData = (page: number) => {
    if (pageData.value.pager.page !== page) {
      pageData.value.pager.page = page;
      updateRouters();
    } else {
      loadList();
    }
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
    if (pageData.value.activeTab == "types") {
      if (pageData.value.filter.types.length === 0) {
        await pageData.value.searchPtypes(
          pageData.value.searchPtypesValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.searchEventsValue.value = "";
          pageData.value.filter.events = [];
          if (pageData.value.filter.unassignedEvent) {
            pageData.value.filter.unassignedEvent.selected = false;
          }
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes: any[] = [];
    if (
      pageData.value.filter.unassignedType &&
      pageData.value.filter.unassignedType.selected
    ) {
      selectedTypes.push(pageData.value.filter.unassignedType.text);
    }
    pageData.value.filter.types
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedTypes.push(item.text);
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.searchPtypesValue.value = "";
          if (pageData.value.filter.unassignedType) {
            pageData.value.filter.unassignedType.selected = false;
          }
          pageData.value.filter.types = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  // init data
  (async () => {
    loadData(1);
  })();

  const onChangeApplication = (application: string) => {
    const selectedApplication = pageData.value.items.find(
      (item: any) => item.id.toString() === application
    );
    formData.value.controls.events.value = [];
    formData.value.controls.participantTypes.value = [];
    if (selectedApplication) {
      formData.value.controls.events.value = selectedApplication.events.map(
        (event: any) => {
          return event.id.toString();
        }
      );
      formData.value.controls.participantTypes.value = selectedApplication.participantTypes.map(
        (event: any) => {
          return event.id.toString();
        }
      );
    }
  };
  const popupNewItem = ref<{
    show: boolean;
    detectFuncion: any;
    formData: any;
    onSubmit: any;
    onChangeApplication: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formData,
    detectFuncion: "",
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      if (formData.value.controls.form.value === "") {
        hasError = true;
        formData.value.controls.form.error = "To Do is required!";
      } else {
        formData.value.controls.form.error = "";
      }
      if (!hasError) {
        // const result = await await ApiHelper.handleApiResponse(
        //   apolloClient.mutate({
        //     mutation: gql`
        //       mutation($id: Float!, $data: AttachParticipantFormInput!) {
        //         attachParticipantForm(id: $id, data: $data) {
        //           status
        //           message
        //         }
        //       }
        //     `,
        //     variables: {
        //       id: Number.parseInt(formData.value.controls.form.value, 10),
        //       data: {
        //         eventIds: formData.value.controls.events.value.join(","),
        //         participantTypeIds: formData.value.controls.participantTypes.value.join(
        //           ","
        //         )
        //       }
        //     }
        //   })
        // );
        //
        // ApiHelper.setDataLoading(false);
        // if (result.status == 1) {
        //   popupNewItem.value.show = false;
        //   formData.value.controls.form.value = "";
        //   formData.value.controls.events.value = [];
        //   formData.value.controls.participantTypes.value = [];
        //   ApiHelper.showSuccessMessage("Attached");
        //   loadData(1);
        // } else {
        //   ApiHelper.showErrorMessage(result.message);
        // }
      }
    },
    onChangeApplication: onChangeApplication
  });

  const removePType = async (pType: any) => {
    const newLines = linkEvents.value.map((item: any) => {
      const tmp = item;
      if (item.uuid == pType.uuid) {
        tmp.isDeleted = 1;
      }
      return tmp;
    });
    linkEvents.value = newLines;
  };

  const modalLinkEvent = ref<{
    foundEvents: object[];
    isProcessing: boolean;
    selectedAppName?: string;
    show: boolean;
    formData: any;
    onSubmit: any;
    suggestEvents: any;
    selectEvent: any;
    removePType: any;
  }>({
    foundEvents: [],
    isProcessing: false,
    show: false,
    formData: formData.value,
    onSubmit: async () => {
      const applicationId = parseInt(formData.value.controls.form.value) || 0;
      const selectedPTypeIDs = linkEvents.value.filter(
        (item: any) => item.eventID != ""
      );
      // validate
      let hasError = false;
      let message = "";

      if (selectedPTypeIDs.length == 0) {
        hasError = true;
        message = "Participant types/events are required";
        formData.value.controls.events.error =
          "Participant types/events are required";
      }
      if (applicationId == 0) {
        hasError = true;
        message = "Please select a To Do";
        formData.value.controls.form.error = "Please select a To Do";
      }

      if (!hasError) {
        // You have not added a participant or event type would you like to continue?
        const activeTypes = selectedPTypeIDs.filter(item => {
          return !item.isDeleted;
        });
        if (activeTypes.length === 0) {
          const isAgreed = await Vue.swal({
            text:
              "You have not added a participant or event type would you like to continue?",
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then(result => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            return result.isConfirmed;
          });
          if (!isAgreed) {
            return false;
          }
        }

        try {
          const result = await ApiHelper.callApi(
            "put",
            "/todos/" + formData.value.controls.form.value + "/links",
            {
              pTypes: JSON.stringify(selectedPTypeIDs)
            },
            {}
          );
          if (result.status == 1) {
            modalLinkEvent.value.show = false;
            linkEvents.value = [];
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        } catch (error) {
          ApiHelper.showErrorMessage(JSON.stringify(error));
        }
        loadData(1);
      } else if (selectedPTypeIDs.length == 0 && applicationId) {
        ApiHelper.showErrorMessage(message, "Oops");
      }
    },
    suggestEvents: async (key: string) => {
      formData.value.controls.events.error = "";
      modalLinkEvent.value.foundEvents = [];
      const selectedPTypeIDs = linkEvents.value
        .filter(
          (item: any) =>
            item.participantTypeID != "" && (item.isDeleted || 0) == 0
        )
        .map((item: any) => `${item.eventID}-${item.participantTypeID}`);
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/todos/eventparticipants",
        {},
        {
          key: key,
          notInPTypeIDs: selectedPTypeIDs.join(",")
        }
      );
      if (result.status === 1) {
        const allTypeEventIds = linkEvents.value
          .filter((value: any) => {
            return value.participantTypeID === 0 && !value.isDeleted;
          })
          .map((value: any) => {
            return value.eventID;
          });
        const data = result.data.items || []; // JSON.parse(result.data.getEventsAndPTypes.jsonData);
        if (data.length) {
          modalLinkEvent.value.foundEvents = data
            .filter((item: any) => {
              const findItem = linkEvents.value.find((value: any) => {
                return (
                  value.eventID === item.eventID &&
                  value.participantTypeID === item.participant_typeid &&
                  !value.isDeleted
                );
              });
              return (
                !findItem &&
                !allTypeEventIds.find((eventId: number) => {
                  return eventId === item.eventID;
                })
              );
            })
            .map((item: any) => ({
              id: item.LINK_Event_Participant,
              html: `${item.ev_name} - ${item.participant_typename}`,
              text: item.ev_name,
              data: item
            }));
        }
      }
      isACILoading.value = false;
    },
    selectEvent: (data: any) => {
      formData.value.controls.events.error = "";
      if (data.participant_typeid === 0) {
        linkEvents.value.find((item: any) => {
          if (item.eventID === data.eventID) {
            item.isDeleted = 1;
          }
        });
      }
      linkEvents.value.push({
        uuid: uuidv4(),
        eventID: data.eventID || 0,
        eventName: data.ev_name || "",
        participantTypeID: data.participant_typeid || 0,
        participantTypeName: data.participant_typename || ""
      });
      // item.eventID = data.eventID || "";
      // item.eventName = data.ev_name || "";
      // item.participantTypeID = data.participant_typeid || "";
      // item.participantTypeName = data.participant_typename || "";
    },
    removePType: async (pType: any) => {
      await removePType(pType);
    }
  });
  const suggestApps = async (key: string, onFocus?: boolean) => {
    // reset
    // formData.value.foundPTypes = [];
    // item.participantTypeName = key;
    if (onFocus && key) {
      // ignore search
      return;
    }
    formData.value.controls.form.value = "";
    linkEvents.value = [];
    isACILoading.value = true;
    pageData.value.endItemMessage =
      key == ""
        ? "Top 5 application are listed, search to see more application"
        : "";
    try {
      const result = await ApiHelper.callApi(
        "get",
        "/todos/search",
        {},
        {
          key: key,
          take: key == "" ? 5 : 25
        }
      );
      if (result.status === 1) {
        pageData.value.foundApps = result.data.todos.map((item: any) => ({
          id: item.id,
          text: item.name,
          data: item
        }));
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      isACILoading.value = false;
    }
  };

  const selectApp = async (data: any) => {
    formData.value.controls.form.error = "";
    formData.value.controls.events.error = "";

    if (popupNewItem.value.detectFuncion == "") {
      formData.value.controls.form.value = data.id || "";

      const result = await ApiHelper.callApi(
        "get",
        "/todos/" + data.id + "/links",
        {},
        {}
      );
      if (result.status === 1) {
        const linkEventParticipants = result.data.links || [];
        linkEvents.value = linkEventParticipants.map((item: any) => {
          return {
            uuid: item.id.toString(), // uuidv4(),
            eventID: item.eventId,
            eventName: item.eventName,
            participantTypeID: item.participantTypeId,
            participantTypeName: item.participantTypeName
          };
        });
      }
    } else if (popupNewItem.value.detectFuncion == "eventAttachApp") {
      formData.value.controls.form.value = data.id || "";
      formData.value.controls.events.value = [context.root.$route.query.event];
    }
  };

  const removeForm = async (item: any) => {
    if (item.events.length > 0) return;
    const confirm = await context.root.$swal({
      // title: "Are you sure?",
      html: "Are you sure you want to delete this To Do?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!"
    });
    if (!confirm.isConfirmed) {
      return false;
    }

    // remove To Do
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi("delete", "/todos/" + item.id);
      if (result.status == 1) {
        loadData(1);
        ApiHelper.showSuccessMessage("To Do has been deleted.", "Deleted");
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.events) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  (async () => {
    pageData.value.searchPtypesValue.value =
      context.root.$route.query.searchPtypes || "";
    if (context.root.$route.query.types) {
      await pageData.value.searchPtypes(pageData.value.searchPtypesValue.value);
    }
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    updateRouters,
    formData,
    linkEvents,
    modalLinkEvent,
    popupNewItem,
    suggestApps,
    selectApp,
    // sort
    updateSortValue,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    isACILoading,
    removeForm
  };
}
