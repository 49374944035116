import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { ApiType } from "@/helpers/ApiHelper";

export function useMessagingCampaignsDetailsStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const pageData = ref({
    isLoading: true,
    tab: "details",
    details: {
      id: "",
      name: "",
      status: "",
      created: "",
      template: "",
      sendTo: "",
      emails: []
    },
    content: {
      subject: "",
      body: ""
    }
  });
  const loadPageData = async () => {
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/campaigns/" + selectedId,
      {},
      {},
      ApiType.CAMPAIGN
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      const campaignData = result.data.campaignList;
      pageData.value.details.name = campaignData.campaignName;
      pageData.value.details.status = campaignData.status;
      pageData.value.details.sendTo = campaignData.sendTo;
      pageData.value.details.emails = campaignData.sendTo
        ? campaignData.sendTo.split(",")
        : [];
      pageData.value.details.created = campaignData.createdTsFormatted;
      pageData.value.details.template = campaignData.templateName;
      pageData.value.content.subject = campaignData.Subject;
      pageData.value.content.body = campaignData.templateContent;
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.gotoPage(context, { name: "MessagingCampaigns" });
    }
  };
  return {
    loadPageData,
    pageData
  };
}
