import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper, FamilyHelper } from "@/helpers";
import {
  AlertMessageError,
  FormCheckbox,
  FormEmail,
  FormText,
  PagerItem,
  SelectOption
} from "@/types";
import FormSelect from "@/types/FormSelect";
import { FamilyDetailsRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

type profilePanel = {
  isShow: boolean;
  data: {
    id: number;
    logo: string;
    firstName: string;
    lastName: string;
    photo: string;
    subProfiles: {
      id: number;
      typeId: number;
      firstName: string;
      lastName: string;
      age: number;
      logo: string;
    }[];
  };
};

type Profile = {
  id: number;
  status: number;
  isChecked: boolean;
  logo: string;
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  balance: string;
  events: string;
  familyName: string;
  newRelationType: string;
};

type PageData = {
  isLoading: boolean;
  skip: number;
  take: number;
  items: Profile[];
  activeTab: string;
  arrTags: SelectOption[];
  arrProfiles: SelectOption[];
  arrBalances: SelectOption[];
  openItemId: number;
  sort: {
    order: string;
    direction: string;
  };
  filter: {
    profile: string;
    family: SelectOption[];
    age: string;
    gender: SelectOption[];
    events: SelectOption[];
    balance: string;
    minAge: string;
    maxAge: string;
    minBalance: string;
    maxBalance: string;
    unassignedEvent: any;
  };
  showHeadActions: boolean;
  pager: {
    showPagerItems: boolean;
    page: number;
    totalPages: number;
    total: number;
    items: PagerItem[];
  };
  removeDisabled: boolean;
};

type PopupTags = {
  show: boolean;
  data: {
    items: {
      id: string;
      text: string;
      checked: boolean;
    }[];
  };
};

export function useFamilyDetailsStore(context: any) {
  const ownerProfileId = ref(0);
  const selectedFamilyId = parseInt(context.root.$route.params.Id) || 0;
  const familyTypeOptions = ref<any[]>([]);
  const popupTags = ref<PopupTags>({
    show: false,
    data: {
      items: []
    }
  });
  const popupArchives = ref<{
    show: boolean;
  }>({
    show: false
  });

  const profilePanel = ref<profilePanel>({
    isShow: false,
    data: {
      id: 0,
      logo: "",
      firstName: "",
      lastName: "",
      photo: "",
      subProfiles: []
    }
  });

  const headerData = ref({
    title: "Members",
    subTitle: ""
  });

  const pageData = ref<PageData>({
    isLoading: true,
    skip: 0,
    take: FamilyDetailsRows(),
    activeTab: "",
    arrTags: [],
    arrProfiles: [],
    arrBalances: [],
    openItemId: 0,
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      profile: "",
      family: [],
      age: "",
      gender: [],
      events: [],
      balance: "",
      minAge: "",
      maxAge: "",
      minBalance: "",
      maxBalance: "",
      unassignedEvent: null
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    removeDisabled: false
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      profile: FormSelect;
      email: FormEmail;
      remove: FormCheckbox;
      firstName: FormText;
      lastName: FormText;
      type: FormSelect;
    };
    relationTypeError: string;
  }>({
    isLoading: true,
    controls: {
      profile: {
        label: "Profile",
        placeholder: "Select a profile",
        error: "",
        type: "select",
        value: "",
        options: []
      },
      email: {
        label: "Email",
        error: "",
        type: "email",
        value: ""
      },
      remove: {
        label: "Remove from current family",
        error: "",
        type: "checkbox",
        value: true
      },
      firstName: {
        label: "First Name",
        error: "",
        type: "text",
        value: ""
      },
      lastName: {
        label: "Last Name",
        error: "",
        type: "text",
        value: ""
      },
      type: {
        required: true,
        label: "Relation type",
        placeholder: "Select...",
        error: "",
        type: "select",
        value: "",
        options: []
      }
    },
    relationTypeError: ""
  });

  const errors = ref<AlertMessageError[]>([]);

  const loadData = async (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;

    try {
      const result = await ApiHelper.callApi(
        "get",
        "/families/" + selectedFamilyId + "/members",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          event: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.events
          ),
          key: pageData.value.filter.profile || "",
          familyId: selectedFamilyId,
          gender: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.gender
          ),
          minAge: pageData.value.filter.minAge || "",
          maxAge: pageData.value.filter.maxAge || "",
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || ""
        }
      );
      pageData.value.isLoading = false;

      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.members.map((item: any) => {
          return {
            id: parseInt(item.id),
            isChecked: false,
            fullName: ApiHelper.getFullName(item.firstName, item.lastName),
            firstName: item.firstName,
            lastName: item.lastName,
            logo: item.logo,
            age: item.age,
            gender: ApiHelper.getGenderName(item.gender),
            balance: item.balance || 0,
            balanceFormatted: ApiHelper.dollarFormat(item.balance, false, {
              useBrackets: true
            }),
            activeEvents: item.activeEvents,
            totalEvents: item.totalEvents,
            newRelationType: "",
            type: item.type || "",
            linkId: item.linkId || 0,
            typeId: item.typeId || 0,
            isParent: item.isParent || 0
          };
        });
        const ownerProfile: any = pageData.value.items.find(
          (item: any) => item.isParent
        );
        if (ownerProfile) {
          ownerProfileId.value = ownerProfile.id;
          formData.value.controls.type.label = `${ownerProfile.fullName}'s`;
        }
      } else {
        const errorCode = result.errorCode || "";
        if (
          ["FAMILY_NOT_FOUND", "NOT_IN_FAMILY"].includes(errorCode) &&
          result.message
        ) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          ApiHelper.gotoPage(context, { name: "ProfilesFamily" });
        }
      }
    } catch (err) {
      console.log(err);
      pageData.value.items = [];
      ApiHelper.setDataLoading(false);
    }
    ApiHelper.setDataLoading(false);
  };

  const loadEvents = () => {
    (async () => {
      try {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1,
          familyId: selectedFamilyId
        });
        // Add un-assigned
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS"
        };
        if (pageData.value.filter.unassignedEvent) {
          pageData.value.filter.events.unshift(
            pageData.value.filter.unassignedEvent
          );
        }
      } catch (err) {
        console.log("err", err);
      }
    })();
  };
  const onClosePanel = async () => {
    profilePanel.value.isShow = false;
  };

  const onUpdateProfileAvatar = async (id: string, newImage: string) => {
    if (newImage !== "") {
      pageData.value.items.forEach(item => {
        if (item.id === parseInt(id)) {
          item.logo = newImage;
        }
      });
    }
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const onViewDetails = async (profileId: number) => {
    ApiHelper.setDataLoading(true);
    try {
      const profileResult = await ApiHelper.callApi(
        "get",
        "/profiles/" + profileId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (profileResult.status === 1) {
        profilePanel.value.data = profileResult.data || {};
        profilePanel.value.isShow = true;
      }
    } catch (err) {
      alert(JSON.stringify(err));
      ApiHelper.setDataLoading(false);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PROFILE",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          loadData(1);
        }
      });
    }

    const selectedFamilies = pageData.value.filter.family
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedFamilies.length > 0) {
      filters.push({
        label: "FAMILY",
        key: selectedFamilies.join("-") + Math.random(),
        value: selectedFamilies.join(", "),
        reset: () => {
          pageData.value.filter.family.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.age !== "") {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.age = "";
          loadData(1);
        }
      });
    }
    const selectedEvents = pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    const selectedGenders = pageData.value.filter.gender
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedGenders.length > 0) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minBalance !== "" ||
      pageData.value.filter.maxBalance !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  const applyFilters = () => {
    loadData(1);
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        break;
      }
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("@");
        pageData.value.filter.minBalance = balanceArr[0];
        pageData.value.filter.maxBalance =
          balanceArr[1] !== undefined ? balanceArr[1] : "";
        break;
      }
    }
    loadData(1);
  };

  const closePopupTags = () => {
    popupTags.value.show = false;
  };

  const closePopupArchives = () => {
    popupArchives.value.show = false;
  };

  const showPopupArchives = () => {
    popupArchives.value.show = true;
  };

  const doDeleteSelectedItems = () => {
    const isAgreed = confirm(
      "Are you sure you want to delete the selected profiles?"
    );
    if (isAgreed) {
      ApiHelper.setDataLoading(true);
      (async () => {
        const selectedProfileIds = getCheckedItems().map(item => {
          return item.id;
        });
        const result = await ProfileHelper.deleteSelectedProfiles(
          selectedProfileIds
        );
        ApiHelper.setDataLoading(false);
        popupArchives.value.show = false;
        if (result.status == 1) {
          loadData(1);
        } else {
          alert(result.message);
        }
      })();
    }
  };

  const doTagSelectedItems = () => {
    popupTags.value.show = true;
  };

  const doMessageSelectedItems = () => {
    alert("doMessageSelectedItems");
  };

  const doFamilyUpSelectedItems = () => {
    alert("doFamilyUpSelectedItems");
  };

  // pageData.value.filter.gender = ApiHelper.getGenderOptions();
  const selectAllGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };

  const selectAllEvents = () => {
    pageData.value.filter.events.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetEvents = () => {
    pageData.value.filter.events.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };

  const selectAllFamilies = () => {
    pageData.value.filter.family.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetFamilies = () => {
    pageData.value.filter.family.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };
  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const linkNewInput = ref({
    profileId: 0,
    subprofileId: 0,
    email: "",
    firstName: "",
    lastName: "",
    typeId: 0,
    familyId: 0,
    profilesRelationTypes: ""
  });

  const formValidateAll = () => {
    let hasError = false;
    let message = "";
    formData.value.controls.profile.error = "";
    formData.value.controls.email.error = "";
    formData.value.controls.firstName.error = "";
    formData.value.controls.lastName.error = "";
    formData.value.controls.type.error = "";

    linkNewInput.value.profileId = ownerProfileId.value;
    linkNewInput.value.familyId = selectedFamilyId;

    if (formData.value.controls.profile.value === "") {
      linkNewInput.value.subprofileId = -1;
    } else {
      linkNewInput.value.subprofileId = parseInt(
        formData.value.controls.profile.value
      );
    }

    linkNewInput.value.email = formData.value.controls.email.value;
    linkNewInput.value.firstName = formData.value.controls.firstName.value;
    linkNewInput.value.lastName = formData.value.controls.lastName.value;
    linkNewInput.value.typeId =
      parseInt(formData.value.controls.type.value) || 0;

    if (linkNewInput.value.subprofileId === -1) {
      hasError = true;
      formData.value.controls.profile.error = message =
        "Please select a person";
    }
    if (linkNewInput.value.subprofileId === 0) {
      if (linkNewInput.value.email === "") {
        hasError = true;
        formData.value.controls.email.error = message = "Email is required";
      } else if (!ApiHelper.validateEmail(linkNewInput.value.email)) {
        hasError = true;
        formData.value.controls.email.error = message = "Email is invalid";
      }
      if (linkNewInput.value.firstName === "") {
        hasError = true;
        formData.value.controls.firstName.error = message =
          "First Name is required";
      }
      if (linkNewInput.value.lastName === "") {
        hasError = true;
        formData.value.controls.lastName.error = message =
          "Last Name is required";
      }
    }

    if (!linkNewInput.value.typeId) {
      hasError = true;
      formData.value.controls.type.error = message = "Type is required";
    } else {
      formData.value.controls.type.error = "";
    }

    return { hasError, message };
  };

  const resetFormData = () => {
    formData.value.controls.profile.value = "";
    formData.value.controls.email.value = "";
    formData.value.controls.firstName.value = "";
    formData.value.controls.lastName.value = "";
    formData.value.controls.type.value = "";
  };

  const popupNewItem = ref<{
    isProcessing: boolean;
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    isProcessing: false,
    show: false,
    formData: formData,
    removeFieldError: (name: string) => {
      switch (name) {
        case "email":
          formData.value.controls.email.error = "";
          break;
        case "firstName":
          formData.value.controls.firstName.error = "";
          break;
      }
    },
    onSubmit: async ($parent: any) => {
      const { hasError, message } = formValidateAll();
      if (!hasError) {
        if (!linkNewInput.value.profileId) {
          ApiHelper.showErrorMessage("Family has no Parent/Owner", "Oops");
          return;
        }
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/profiles/tribes",
          linkNewInput.value,
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          popupNewItem.value.show = false;
          resetFormData();
          ApiHelper.showSuccessMessage("Added");
          await loadData(1);
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
    }
  });

  // init data
  (async () => {
    await loadData(1);
    loadEvents();
    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    familyTypeOptions.value = await ApiHelper.getFamilyTypeOptions();

    const family = await FamilyHelper.getFamilyInfo(selectedFamilyId);
    headerData.value.subTitle = family.familyName || "";
  })();

  const deleteItem = async (item: any) => {
    const isConfirm = await Vue.swal({
      text: "Are you sure you want to delete this member?",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return false;
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "delete",
      "/families/" + selectedFamilyId + "/member",
      {
        profileId: item.id
      },
      {}
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      loadData(1);
      ApiHelper.showSuccessMessage("The link has been deleted.", "Deleted");
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  (async () => {
    formData.value.controls.type.options = await ApiHelper.getFamilyTypeOptions();
  })();

  return {
    loadData,
    deleteItem,
    popupNewItem,
    // sort
    updateSortValue,
    errors,
    profilePanel,
    headerData,
    pageData,
    showStatusDropdown,
    // filters
    selectAllGenders,
    resetGenders,
    selectAllEvents,
    resetEvents,
    selectAllFamilies,
    resetFamilies,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onViewDetails,
    onClosePanel,
    onUpdateProfileAvatar,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    applyFilters,
    updateFilterValue,
    doDeleteSelectedItems,
    doTagSelectedItems,
    doMessageSelectedItems,
    doFamilyUpSelectedItems,
    // popups
    showPopupArchives,
    popupTags,
    closePopupTags,
    popupArchives,
    closePopupArchives,
    familyTypeOptions
  };
}
