


























































































































































































































































































import { defineComponent, watch } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { userAccountingPostingCodesStore } from "@/stores/Financial/Accounting/AccountingPostingCode/AccountingPostingCodesStore";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";

type PageData = {
  isLoading: boolean;
};

export default defineComponent({
  name: "FinancialAccounting",
  components: {
    Pager,
    Header,
    HeaderTabs,
    FilterSearchColumn,
    TableHeaderColumn,
    Loading,
    DropdownParticipantStatus
  },
  setup(props, context) {
    const {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      initData
    } = userAccountingPostingCodesStore(context);

    watch(
      () => context.root.$route.name,
      async newRoute => {
        // reset filter/sort
        pageData.value.filter.key = "";
        pageData.value.filter.desc = "";
        pageData.value.filter.gl = "";
        pageData.value.sort = {
          order: "1",
          direction: "1"
        };
        initData();
      }
    );

    return {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      ApiHelper
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
