import { ref } from "@vue/composition-api";
import { FormText, PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { ReportListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useReportsStore(context: any) {
  const headerData = ref({
    title: "Reports",
    subTitle: ""
  });
  const filterData = ref<{
    participants: SelectOption[];
    events: SelectOption[];
    types: SelectOption[];
    eventId: number;
    profileId: number;
    name: string;
    amount: string;
    minAmount: string;
    maxAmount: string;
    date: string;
    minDate: string;
    maxDate: string;
    lastRun: string;
    minLastRun: string;
    maxLastRun: string;
  }>({
    participants: [],
    types: [],
    events: [],
    eventId: parseInt(context.root.$route.query.event) || 0,
    profileId: parseInt(context.root.$route.query.profile) || 0,
    name: context.root.$route.query.key || "",
    amount:
      (context.root.$route.query.minAmount || "") +
      "-" +
      (context.root.$route.query.maxAmount || ""),
    minAmount: context.root.$route.query.minAmount || "",
    maxAmount: context.root.$route.query.maxAmount || "",
    date:
      (context.root.$route.query.minDate || "") +
      "-" +
      (context.root.$route.query.maxDate || ""),
    minDate: context.root.$route.query.minDate || "",
    maxDate: context.root.$route.query.maxDate || "",
    lastRun:
      (context.root.$route.query.minLastRun || "") +
      "-" +
      (context.root.$route.query.maxLastRun || ""),
    minLastRun: context.root.$route.query.minLastRun || "",
    maxLastRun: context.root.$route.query.maxLastRun || ""
  });
  const currentRoute = "Reports";
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    paying: boolean;
    gettingPreviewData: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: ReportListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: filterData.value,
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    paying: false,
    gettingPreviewData: false
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: currentRoute,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.name || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined,
          minLastRun: pageData.value.filter.minLastRun || undefined,
          maxLastRun: pageData.value.filter.maxLastRun || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/reports",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: pageData.value.filter.name,
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || "",
          minLastRun: pageData.value.filter.minLastRun || "",
          maxLastRun: pageData.value.filter.maxLastRun || ""
        }
      );
      if (result.status === 1) {
        const totalCount = result.data.queryCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.reports;
        // pageData.value.items = result.data.reports.map((item: any) => {
        //   return {
        //     id: item.id,
        //     name: item.name || "",
        //     definition: item.definition || "",
        //     isSystem: item.isSystem || 0,
        //     createdDate: item.reportTsFormatted,
        //     lastRunDate: item.lastRunTsFormatted
        //   };
        // });
        ApiHelper.setDataLoading(false);
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    updateRouters();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // init data
  (async () => {
    loadList();
  })();

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          loadData(1);
        }
      });
    }

    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");
      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          loadData(1);
        }
      });
    }

    if (
      pageData.value.filter.minLastRun !== "" ||
      pageData.value.filter.maxLastRun
    ) {
      const lastRun =
        pageData.value.filter.minLastRun +
        "-" +
        pageData.value.filter.maxLastRun;
      const displayValue =
        (pageData.value.filter.minLastRun != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minLastRun
            )
          : "") +
        " - " +
        (pageData.value.filter.maxLastRun != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxLastRun
            )
          : "");
      filters.push({
        label: "LAST RUN",
        key: lastRun + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.lastRun = "";
          pageData.value.filter.minLastRun = "";
          pageData.value.filter.maxLastRun = "";
          loadData(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "lastRun": {
        pageData.value.filter.lastRun = value;
        const dateArr = value.split("-");
        pageData.value.filter.minLastRun = dateArr[0];
        pageData.value.filter.maxLastRun =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "name": {
        pageData.value.filter.name = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const popupDuplicateReport = ref<{
    show: boolean;
    controls: {
      id: number;
      reportName: string;
      name: FormText;
      definition: FormText;
    };
    actions: {
      onSubmit: Function;
    };
  }>({
    show: false,
    controls: {
      id: 0,
      reportName: "",
      name: {
        value: "",
        error: "",
        required: true,
        label: "New Report Name",
        placeholder: "Report Name"
      },
      definition: {
        value: "",
        error: "",
        placeholder: "Definition"
      }
    },
    actions: {
      onSubmit: async () => {
        const selectedId = popupDuplicateReport.value.controls.id;
        let hasError = false;
        const reportName = popupDuplicateReport.value.controls.reportName;
        if (popupDuplicateReport.value.controls.name.value === "") {
          hasError = true;
          popupDuplicateReport.value.controls.name.error =
            "Report name is required";
        }
        if (hasError) {
          return;
        }
        const isAgreed = await Vue.swal({
          html: `Are you sure you want to duplicate the report "<strong>${reportName}</strong>"?`,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });
        if (!isAgreed) {
          return;
        }
        const result = await ApiHelper.callApi(
          "post",
          "/reports/" + selectedId + "/duplicate",
          {
            reportDefinition:
              popupDuplicateReport.value.controls.definition.value,
            reportName: popupDuplicateReport.value.controls.name.value
          },
          {}
        );
        if (result.status === 1) {
          ApiHelper.showSuccessMessage(`Duplicated report "${reportName}"`);

          pageData.value.sort.order = "2";
          pageData.value.sort.direction = "2";
          popupDuplicateReport.value.show = false;
          if (pageData.value.pager.page == 1) {
            loadList();
          } else {
            pageData.value.pager.page = 1;
            updateRouters();
          }
        }
      }
    }
  });

  const duplicateReport = async (item: any) => {
    popupDuplicateReport.value.controls.id = item.id;
    popupDuplicateReport.value.controls.reportName = item.name;
    popupDuplicateReport.value.controls.name.error = "";
    popupDuplicateReport.value.controls.name.value = item.name + " - Duplicate";
    popupDuplicateReport.value.controls.definition.value = item.definition;
    popupDuplicateReport.value.show = true;
    // const isAgreed = await Vue.swal({
    //   html: `Are you sure to duplicate the report "<strong>${item.name}</strong>"?`,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, do it!"
    // }).then(result => {
    //   return result.isConfirmed;
    // });
    // if (isAgreed) {
    //   const result = await ApiHelper.callApi(
    //     "post",
    //     "/reports/" + item.id + "/duplicate",
    //     {
    //       reportDefinition: item.definition,
    //       reportName: item.name + " - duplicate"
    //     },
    //     {}
    //   );
    //   if (result.status === 1) {
    //     ApiHelper.showSuccessMessage("Duplicated #" + item.id);
    //     pageData.value.pager.page = 1;
    //     loadList();
    //   }
    // }
  };

  const exportReport = async (reportItem: any, type = "csv") => {
    try {
      let headers: any = [];
      let labels = [];
      let reportData = [];

      pageData.value.gettingPreviewData = true;
      const response = await ApiHelper.callApi(
        "get",
        `/reports/${reportItem.id}/preview`,
        {},
        {
          isCoreReport: reportItem.isCoreReport || 0,
          exportCSV: type == "csv" ? 1 : 0
        }
      );
      if (response.status == 1) {
        headers = (response.data.columnHeaders || "").toUpperCase().split(",");
        labels = (response.data.columnLabels || "").split("|");
        reportData = response.data.items || [];

        if (type == "csv") {
          const exportData: any = reportData.map((item: any) => {
            const tmp = [];
            for (const headerItem of headers) {
              tmp.push(item[headerItem]);
            }
            return tmp;
          });
          const fileName = `${reportItem.name
            .toLowerCase()
            .replace(/\s/g, "_")}_${moment().format("MMDDYYYY_HHMMSS")}`;

          await ApiHelper.generateCsv(exportData, labels, fileName);
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      pageData.value.gettingPreviewData = false;
    }
  };

  const deleteReport = async (item: any) => {
    const isAgreed = await Vue.swal({
      html: `Are you sure you want to delete the report "<strong>${item.name}</strong>"?`,
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });

    if (isAgreed) {
      const result = await ApiHelper.callApi(
        "delete",
        "/reports/" + item.id,
        {},
        {}
      );
      if (result.status === 1) {
        ApiHelper.showSuccessMessage("Deleted #" + item.id);
        pageData.value.pager.page = 1;
        loadList();
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  };

  return {
    popupDuplicateReport,
    // actions
    duplicateReport,
    exportReport,
    deleteReport,
    // page
    loadData,
    headerData,
    pageData,
    updateRouters,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    // pager
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems
  };
}
