/* eslint-disable @typescript-eslint/no-use-before-define */
import { ref } from "@vue/composition-api";
import { PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import { FinancialListRows } from "@/helpers/estimateNoOfListRows";
import { dollarFormat, TransactionType } from "@/helpers/ApiHelper";
import moment from "moment";

export function useFinancialTransactionsStore(context: any) {
  const query = context.root.$route.query;
  const headerData = ref({
    title: "Transactions",
    subTitle: "Financial"
  });
  const filterData = ref<{
    key: string;
    profiles: SelectOption[];
    events: SelectOption[];
    // Search posting codes
    postingCodes: SelectOption[];
    // Search users
    users: SelectOption[];
    costCenter: SelectOption[];
    unassignedEvent: any;
    unassignedPostingCodes: any;
    unassignedUsers: any;
    unassignedCostCenter: any;
    types: SelectOption[];
    profile: string;
    amount: string;
    minAmount: string;
    maxAmount: string;
    credit: string;
    minCredit: string;
    maxCredit: string;
    discountAmount: string;
    minDiscountAmount: string;
    maxDiscountAmount: string;
    date: string;
    minDate: string;
    maxDate: string;
    t: string;
    participantId: number;
    addonServiceId: number;
    fullName: string;
    serviceName: string;
    endDate: string;
    minEndDate: string;
    maxEndDate: string;
    paymentTypes: SelectOption[];
  }>({
    key: query.key || "",
    profiles: [],
    types: [],
    events: [],
    postingCodes: [],
    users: [],
    costCenter: [],
    unassignedEvent: null,
    unassignedPostingCodes: null,
    unassignedUsers: null,
    unassignedCostCenter: null,
    profile: query.key || "",
    amount: (query.minAmount || "") + "-" + (query.maxAmount || ""),
    minAmount: query.minAmount || "",
    maxAmount: query.maxAmount || "",
    credit: (query.minCredit || "") + "-" + (query.maxCredit || ""),
    minCredit: query.minCredit || "",
    maxCredit: query.maxCredit || "",
    discountAmount:
      (query.minDiscountAmount || "") + "-" + (query.maxDiscountAmount || ""),
    minDiscountAmount: query.minDiscountAmount || "",
    maxDiscountAmount: query.maxDiscountAmount || "",
    date: (query.minDate || "") + "-" + (query.maxDate || ""),
    minDate: query.minDate || "",
    maxDate: query.maxDate || "",
    t: query.t || "",
    participantId: query.participantId || 0,
    addonServiceId: query.addonServiceId || 0,
    fullName: query.fullName || "",
    serviceName: query.serviceName || "",
    endDate: (query.minEndDate || "") + "-" + (query.maxEndDate || ""),
    minEndDate: query.minEndDate || "",
    maxEndDate: query.maxEndDate || "",
    paymentTypes: []
  });
  const currentRoute = "FinancialTransactions";
  const pageData = ref<{
    isLoading: boolean;
    isGreater: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
      totalAmount: string;
    };
    paying: boolean;
    selectedIds: any;
    selectedTransactionIds: string;
    paymentType: string;
    refundInfo: any;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchParticipantsValue: {
      value: string;
    };
    selectedParticipants: SelectOption[];
    searchParticipants: Function;
    selectedEvents: SelectOption[];
    searchParticipantsLoading: boolean;
    searchTypesValue: {
      value: string;
    };
    searchTypes: Function;
    selectedTypes: SelectOption[];
    searchTypesLoading: boolean;
    searchEventsLoading: boolean;
    detailsVisible: number;
    // Search users
    searchUsersValue: {
      value: string;
    };
    searchUsers: Function;
    selectedUsers: SelectOption[];
    searchUsersLoading: boolean;
    // Search posting code
    searchPostingCodesValue: {
      value: string;
    };
    searchPostingCodes: Function;
    selectedPostingCodes: SelectOption[];
    searchPostingCodesLoading: boolean;
    // Search cost center
    searchCostCenterValue: {
      value: string;
    };
    searchCostCenter: Function;
    selectedCostCenter: SelectOption[];
    searchCostCenterLoading: boolean;
    filterView: number;
    searchPaymentTypesValue: {
      value: string;
    };
    searchPaymentTypes: Function;
    selectedPaymentTypes: SelectOption[];
    searchPaymentTypesLoading: boolean;
    showCostCenter?: boolean;
    showGlCodes?: boolean;
  }>({
    isLoading: true,
    isGreater: false,
    skip: 0,
    take: FinancialListRows("FinancialTransactions"),
    activeTab: "",
    sort: {
      order: parseInt(query.view || 1) == 1 ? "7" : "0",
      direction: parseInt(query.view || 1) == 1 ? "2" : "0"
    },
    filter: filterData.value,
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      totalAmount: "",
      items: []
    },
    paying: false,
    selectedIds: [],
    selectedTransactionIds: "",
    paymentType: "manual",
    refundInfo: [],
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    selectedEvents: [],
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.events || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    searchParticipantsLoading: false,
    searchParticipantsValue: {
      value: ""
    },
    selectedParticipants: [],
    searchParticipants: async (searchValue: string) => {
      pageData.value.searchParticipantsLoading = true;
      if (searchValue == "") {
        pageData.value.filter.profiles = await ApiHelper.getProfileOptions({
          getAll: 1,
          inIds: context.root.$route.query.profiles,
          includeDenied: 1
        });
      } else {
        pageData.value.filter.profiles = await ApiHelper.getProfileOptions({
          key: searchValue,
          includeDenied: 1
        });
      }
      const profileIds = (context.root.$route.query.profiles || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.profiles = pageData.value.filter.profiles.map(
        (item: any) => ({
          ...item,
          selected: profileIds.includes(item.id)
        })
      );
      pageData.value.searchParticipantsLoading = false;
    },
    searchTypesLoading: false,
    searchTypesValue: {
      value: ""
    },
    selectedTypes: [],
    searchTypes: async (searchValue: string) => {
      pageData.value.searchTypesLoading = true;
      if (searchValue == "") {
        // load top 20 types
        pageData.value.filter.types = await ApiHelper.getTransactionTypeOptions(
          {
            take: 20
          }
        );
      } else {
        pageData.value.filter.types = await ApiHelper.getTransactionTypeOptions(
          {
            key: searchValue
          }
        );
      }
      const typeIds = (context.root.$route.query.types || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.types = pageData.value.filter.types.map(
        (item: any) => ({
          ...item,
          selected: typeIds.includes(item.id)
        })
      );
      pageData.value.searchTypesLoading = false;
    },
    detailsVisible: -1,
    // Data default Search posting code
    searchPostingCodesLoading: false,
    searchPostingCodesValue: {
      value: ""
    },
    selectedPostingCodes: [],
    searchPostingCodes: async (searchValue: string) => {
      pageData.value.searchPostingCodesLoading = true;
      const postingCodeIds = (context.root.$route.query.postingCodes || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        const result = await ApiHelper.callApi(
          "get",
          `/accounting/postingcode/search`,
          {},
          { getAll: 1 }
        );
        if (result.status === 1) {
          result.data.postingCodes.map((item: any) => {
            pageData.value.filter.postingCodes.push({
              id: item.postingCode || "",
              text: (item.postingCode || "") + " / " + (item.glCode || "")
            });
          });
        }
        pageData.value.filter.unassignedPostingCodes = {
          id: "0",
          text: "UN-ASSIGNED TO POSTING CODES",
          selected: postingCodeIds.includes(0)
        };
      } else {
        const result = await ApiHelper.callApi(
          "get",
          `/accounting/postingcode/search`,
          {},
          { key: searchValue, glCode: 1, getAll: 1 }
        );
        if (result.status === 1) {
          pageData.value.filter.postingCodes = [];
          result.data.postingCodes.map((item: any) => {
            pageData.value.filter.postingCodes.push({
              id: item.postingCode || "",
              text: (item.postingCode || "") + " / " + (item.glCode || "")
            });
          });
        }
        if (postingCodeIds.includes(0)) {
          pageData.value.filter.unassignedPostingCodes = {
            id: "0",
            text: "UN-ASSIGNED TO POSTING CODES",
            selected: postingCodeIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedPostingCodes = null;
        }
      }
      pageData.value.filter.postingCodes = pageData.value.filter.postingCodes.map(
        (item: any) => ({
          ...item,
          selected: postingCodeIds.includes(item.id)
        })
      );
      pageData.value.searchPostingCodesLoading = false;
    },
    // Data default Search users
    searchUsersLoading: false,
    searchUsersValue: {
      value: ""
    },
    selectedUsers: [],
    searchUsers: async (searchValue: string) => {
      pageData.value.searchUsersLoading = true;
      const userIds = (context.root.$route.query.users || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        const result = await ApiHelper.callApi(
          "get",
          `/transactions/search/user`,
          {},
          {}
        );
        if (result.status === 1) {
          pageData.value.filter.users = [];
          result.data.users.map((item: any) => {
            pageData.value.filter.users.push({
              id: item.id || "",
              text: item.name || ""
            });
          });
        }
        pageData.value.filter.unassignedUsers = {
          id: "0",
          text: "ONLINE",
          selected: userIds.includes(0)
        };
      } else {
        const result = await ApiHelper.callApi(
          "get",
          `/transactions/search/user`,
          {},
          { key: searchValue }
        );
        if (result.status === 1) {
          pageData.value.filter.users = [];
          result.data.users.map((item: any) => {
            pageData.value.filter.users.push({
              id: item.id || "",
              text: item.name || ""
            });
          });
        }
        if (userIds.includes(0)) {
          pageData.value.filter.unassignedUsers = {
            id: "0",
            text: "ONLINE",
            selected: userIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedUsers = null;
        }
      }
      pageData.value.filter.users = pageData.value.filter.users.map(
        (item: any) => ({
          ...item,
          selected: userIds.includes(item.id)
        })
      );
      pageData.value.searchUsersLoading = false;
    },
    searchCostCenterLoading: false,
    searchCostCenterValue: {
      value: ""
    },
    selectedCostCenter: [],
    searchCostCenter: async (searchValue: string) => {
      pageData.value.searchCostCenterLoading = true;
      const costCenterIds = (context.root.$route.query.costCenter || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        const result = await ApiHelper.callApi(
          "get",
          `/transactions/search/costcenter`,
          {},
          { getAll: 1 }
        );
        if (result.status === 1) {
          pageData.value.filter.costCenter = [];
          result.data.items.map((item: any) => {
            pageData.value.filter.costCenter.push({
              id: item.name || "",
              text: item.name || ""
            });
          });
        }
        pageData.value.filter.unassignedCostCenter = {
          id: "0",
          text: "UN-ASSIGNED TO COST CENTER",
          selected: costCenterIds.includes(0)
        };
      } else {
        const result = await ApiHelper.callApi(
          "get",
          `/transactions/search/costcenter`,
          {},
          { key: searchValue, getAll: 1 }
        );
        if (result.status === 1) {
          pageData.value.filter.costCenter = [];
          result.data.items.map((item: any) => {
            pageData.value.filter.costCenter.push({
              id: item.name || "",
              text: item.name || ""
            });
          });
        }
        if (costCenterIds.includes(0)) {
          pageData.value.filter.unassignedCostCenter = {
            id: "0",
            text: "UN-ASSIGNED TO COST CENTER",
            selected: costCenterIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedCostCenter = null;
        }
      }
      pageData.value.filter.costCenter = pageData.value.filter.costCenter.map(
        (item: any) => ({
          ...item,
          selected: costCenterIds.includes(item.id)
        })
      );
      pageData.value.searchCostCenterLoading = false;
    },
    filterView: parseInt(query.view || 1),
    searchPaymentTypesLoading: false,
    searchPaymentTypesValue: {
      value: query.searchPaymentTypes || ""
    },
    selectedPaymentTypes: [],
    searchPaymentTypes: async (searchValue: string) => {
      pageData.value.searchPaymentTypesLoading = true;
      pageData.value.filter.paymentTypes = await ApiHelper.getPaymentTypesOptions(
        {
          key: searchValue,
          getAll: 1
        }
      );
      const paymentTypes = (context.root.$route.query.paymentTypes || "").split(
        ","
      );
      pageData.value.filter.paymentTypes = pageData.value.filter.paymentTypes.map(
        (item: any) => ({
          ...item,
          selected: paymentTypes.includes(item.id)
        })
      );
      pageData.value.searchPaymentTypesLoading = false;
    }
  });
  const userSettings = ref<any>({});
  const filterTransactionsPopup = ref({
    isShow: false,
    controls: {
      startDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "From Date",
        type: "date",
        value: "",
        style: "custom"
      },
      endDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "To Date",
        type: "date",
        value: "",
        style: "custom"
      }
    }
  });

  const updateRouters = () => {
    const queryObject: any = {
      page: pageData.value.pager.page + "" || undefined,
      order:
        parseInt(pageData.value.sort.order) > 0
          ? pageData.value.sort.order
          : undefined,
      direction:
        parseInt(pageData.value.sort.direction) > 0
          ? pageData.value.sort.direction
          : undefined,
      id: pageData.value.filter.id || undefined,
      key: pageData.value.filter.key || undefined,
      profile: pageData.value.filter.profileId
        ? (pageData.value.filter.profileId || 0) + ""
        : undefined,
      event: pageData.value.filter.eventId
        ? (pageData.value.filter.eventId || 0) + ""
        : undefined,
      users:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedUsers
        ) || undefined,
      postingCodes:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedPostingCodes
        ) || undefined,
      costCenter:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedCostCenter
        ) || undefined,
      events:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedEvents
        ) || undefined,
      profiles:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedParticipants
        ) || undefined,
      types:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedTypes
        ) || undefined,
      minAmount: pageData.value.filter.minAmount || undefined,
      maxAmount: pageData.value.filter.maxAmount || undefined,
      minCredit: pageData.value.filter.minCredit || undefined,
      maxCredit: pageData.value.filter.maxCredit || undefined,
      minDiscountAmount: pageData.value.filter.minDiscountAmount || undefined,
      maxDiscountAmount: pageData.value.filter.maxDiscountAmount || undefined,
      minDate: pageData.value.filter.minDate || undefined,
      maxDate: pageData.value.filter.maxDate || undefined,
      searchEvents: pageData.value.searchEventsValue.value || undefined,
      searchParticipants:
        pageData.value.searchParticipantsValue.value || undefined,
      searchTypes: pageData.value.searchTypesValue.value || undefined,
      t: pageData.value.filter.t || undefined,
      minEndDate: pageData.value.filter.minEndDate || undefined,
      maxEndDate: pageData.value.filter.maxEndDate || undefined,
      view: pageData.value.filterView || 1,
      searchPaymentTypes:
        pageData.value.searchPaymentTypesValue.value || undefined,
      paymentTypes:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedPaymentTypes
        ) || undefined
    };

    if (pageData.value.filter.participantId && pageData.value.filter.fullName) {
      queryObject.participantId = pageData.value.filter.participantId;
      queryObject.fullName = pageData.value.filter.fullName;
    }
    if (
      pageData.value.filter.addonServiceId &&
      pageData.value.filter.serviceName
    ) {
      queryObject.addonServiceId = pageData.value.filter.addonServiceId;
      queryObject.serviceName = pageData.value.filter.serviceName;
    }

    context.root.$router
      .replace({
        name: currentRoute,
        query: queryObject
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.filterView = parseInt(context.root.$route.query.view) || 1;
    pageData.value.filter.minDate = context.root.$route.query.minDate || "";
    pageData.value.filter.maxDate = context.root.$route.query.maxDate || "";

    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    pageData.value.detailsVisible = -1;
    const result = await ApiHelper.callApi(
      "get",
      "/transactions",
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        key: context.root.$route.query.key || "",
        minAmount: pageData.value.filter.minAmount || "",
        maxAmount: pageData.value.filter.maxAmount || "",
        minCredit: pageData.value.filter.minCredit || "",
        maxCredit: pageData.value.filter.maxCredit || "",
        minDiscountAmount: pageData.value.filter.minDiscountAmount || "",
        maxDiscountAmount: pageData.value.filter.maxDiscountAmount || "",
        minDate: pageData.value.filter.minDate || "",
        maxDate: pageData.value.filter.maxDate || "",
        profiles: context.root.$route.query.profiles || "",
        events: context.root.$route.query.events || "",
        postingCodes: context.root.$route.query.postingCodes || "",
        users: context.root.$route.query.users || "",
        costCenter: context.root.$route.query.costCenter || "",
        types: context.root.$route.query.types || "",
        participantIds: context.root.$route.query.participantIds || "",
        participantId: context.root.$route.query.participantId || 0,
        addonServiceId: context.root.$route.query.addonServiceId || 0,
        getInfo: "totalPaid",
        minEndDate: pageData.value.filter.minEndDate || "",
        maxEndDate: pageData.value.filter.maxEndDate || "",
        view: pageData.value.filterView || 1,
        paymentTypes: context.root.$route.query.paymentTypes || ""
      }
    );
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.items.map((item: any) => {
        const discountInfo = item.discountInfo || [];
        // Handle for view 2
        if (
          item.discountCode &&
          item.discountId &&
          item.discountName &&
          item.discountAmount &&
          pageData.value.filterView == 2
        ) {
          discountInfo.push({
            discountId: item.discountId,
            discountName: item.discountName,
            discountCode: item.discountCode || "",
            discountAmount: item.discountAmount
          });
        }
        // group by discountId
        const discounts: any = [];
        for (const discount of discountInfo) {
          // for (const t of discount) {
          const inList = discounts.find(
            (d: any) => d.discountId == discount.discountId
          );
          if (!inList) {
            discounts.push({
              discountId: discount.discountId,
              discountName: discount.discountName,
              discountCode: discount.discountCode || "",
              discountAmount: discount.discountAmount,
              discountAmountFormatted: dollarFormat(discount.discountAmount)
            });
          } else {
            inList.discountAmount += discount.discountAmount;
            inList.discountAmount = parseFloat(
              inList.discountAmount.toFixed(2)
            );
            inList.discountAmountFormatted = dollarFormat(
              inList.discountAmount
            );
          }
          // }
        }

        return {
          ...item,
          discountInfo: discounts
        };
      });
      pageData.value.pager.totalAmount = result.totalPaidFormatted || "";
      ApiHelper.setDataLoading(false);
    } else if (result.status === -1) {
      ApiHelper.setDataLoading(false);
      ApiHelper.gotoPage(context, { name: "Login" });
    } else {
      ApiHelper.setDataLoading(false);
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const checkGreater = () => {
    pageData.value.isGreater =
      userSettings.value.transactionsCustomView.filter(
        (item: { checked: boolean }) => item.checked == true
      ).length > 10;
  };

  const loadData = async (page: number) => {
    checkGreater();
    pageData.value.pager.page = page;
    await loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleTransactionDetails = async (item: any, index: number) => {
    if (pageData.value.detailsVisible == index) {
      pageData.value.detailsVisible = -1;
    } else {
      pageData.value.detailsVisible = index;
    }
    if (pageData.value.detailsVisible != -1 && !("details" in item)) {
      // get item details
      ApiHelper.setDataLoading(true);
      pageData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/transactions/details",
        {},
        {
          transactionId: item.transactionId,
          transactionTypeId: item.transactionTypeId || 0,
          // tieEvent: isTieEvent(item.eventId),
          // profileId: parseInt(item.profileId)
          transactionIds: item.transactionIds,
          participantIds: item.participantIds
        }
      );
      pageData.value.isLoading = false;
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        item.details = {
          transactionDetails: result.data.transactionDetails || [],
          transactionLines: result.data.transactionLines || [],
          transactionInfos: result.data.transactionInfos || [],
          refundLines: result.data.refundLines || [],
          events: result.data.events || []
        };
      } else {
        ApiHelper.showErrorMessage("Transaction not found", "Oops");
      }
    }
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
    // Search users
    if (pageData.value.activeTab == "users") {
      if (pageData.value.filter.users.length === 0) {
        await pageData.value.searchUsers(pageData.value.searchUsersValue.value);
      }
    }
    // Search posting codes
    if (pageData.value.activeTab == "postingCodes") {
      if (pageData.value.filter.postingCodes.length === 0) {
        await pageData.value.searchPostingCodes(
          pageData.value.searchPostingCodesValue.value
        );
      }
    }
    // Search posting codes
    if (pageData.value.activeTab == "costCenter") {
      if (pageData.value.filter.costCenter.length === 0) {
        await pageData.value.searchCostCenter(
          pageData.value.searchCostCenterValue.value
        );
      }
    }

    if (pageData.value.activeTab == "profiles") {
      if (pageData.value.filter.profiles.length === 0) {
        await pageData.value.searchParticipants(
          pageData.value.searchParticipantsValue.value
        );
      }
    }

    if (pageData.value.activeTab == "types") {
      if (pageData.value.filter.types.length === 0) {
        await pageData.value.searchTypes(pageData.value.searchTypesValue.value);
      }
    }

    if (pageData.value.activeTab == "paymentTypes") {
      if (pageData.value.filter.paymentTypes.length === 0) {
        await pageData.value.searchPaymentTypes(
          pageData.value.searchPaymentTypesValue.value
        );
      }
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.key) {
      filters.push({
        label: "KEY",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          updateRouters();
        }
      });
    }

    const selectedUsers = pageData.value.selectedUsers
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });

    if (selectedUsers.length > 0) {
      filters.push({
        label: "USERS",
        key: selectedUsers.join("-") + Math.random(),
        value: selectedUsers.join(", "),
        reset: () => {
          pageData.value.searchUsersValue.value = "";
          pageData.value.filter.unassignedUsers = null;
          pageData.value.filter.users = [];
          pageData.value.selectedUsers = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedPostingCodes = pageData.value.selectedPostingCodes
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });

    if (selectedPostingCodes.length > 0) {
      filters.push({
        label: "POSTING CODES",
        key: selectedPostingCodes.join("-") + Math.random(),
        value: selectedPostingCodes.join(", "),
        reset: () => {
          pageData.value.searchPostingCodesValue.value = "";
          pageData.value.filter.unassignedPostingCode = null;
          pageData.value.filter.postingCodes = [];
          pageData.value.selectedPostingCodes = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedCostCenter = pageData.value.selectedCostCenter
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedCostCenter.length > 0) {
      filters.push({
        label: "COST CENTER",
        key: selectedCostCenter.join("-") + Math.random(),
        value: selectedCostCenter.join(", "),
        reset: () => {
          pageData.value.searchCostCenterValue.value = "";
          pageData.value.filter.unassignedCostCenter = null;
          pageData.value.filter.costCenter = [];
          pageData.value.selectedCostCenter = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedParticipants = pageData.value.selectedParticipants
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedParticipants.length > 0) {
      filters.push({
        label: "PARTICIPANT",
        key: selectedParticipants.join("-") + Math.random(),
        value: selectedParticipants.join(", "),
        reset: () => {
          pageData.value.filter.profiles = [];
          pageData.value.activeTab = "";
          pageData.value.selectedParticipants = [];
          updateRouters();
        }
      });
    }

    const selectedEvents = pageData.value.selectedEvents
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.searchEventsValue.value = "";
          pageData.value.filter.unassignedEvent = null;
          pageData.value.filter.events = [];
          pageData.value.selectedEvents = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes = pageData.value.selectedTypes
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.searchTypesValue.value = "";
          pageData.value.filter.types = [];
          pageData.value.selectedTypes = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.minAmount || pageData.value.filter.maxAmount) {
      const displayValue =
        (pageData.value.filter.minAmount != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minAmount, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxAmount != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxAmount, 10),
              false,
              { useBrackets: true }
            )
          : "");

      filters.push({
        label: "AMOUNT",
        key: pageData.value.filter.amount + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.amount = "";
          pageData.value.filter.minAmount = "";
          pageData.value.filter.maxAmount = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.minCredit || pageData.value.filter.maxCredit) {
      const displayValue =
        (pageData.value.filter.minCredit != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minCredit, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxCredit != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxCredit, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "CREDIT",
        key: pageData.value.filter.credit + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.credit = "";
          pageData.value.filter.minCredit = "";
          pageData.value.filter.maxCredit = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minDiscountAmount ||
      pageData.value.filter.maxDiscountAmount
    ) {
      filters.push({
        label: "DISCOUNT",
        key: pageData.value.filter.discountAmount + Math.random(),
        value: pageData.value.filter.discountAmount,
        reset: () => {
          pageData.value.filter.discountAmount = "";
          pageData.value.filter.minDiscountAmount = "";
          pageData.value.filter.maxDiscountAmount = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.date !== "-" &&
      pageData.value.filter.date !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");
      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.participantId && pageData.value.filter.fullName) {
      filters.push({
        label: "PARTICIPANT",
        key: pageData.value.filter.participantId + Math.random(),
        value: pageData.value.filter.fullName,
        reset: () => {
          pageData.value.filter.participantId = 0;
          pageData.value.pager.page = 1;
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.addonServiceId &&
      pageData.value.filter.serviceName
    ) {
      filters.push({
        label: "SERVICE",
        key: pageData.value.filter.addonServiceId + Math.random(),
        value: pageData.value.filter.serviceName,
        reset: () => {
          pageData.value.filter.addonServiceId = 0;
          pageData.value.pager.page = 1;
          updateRouters();
        }
      });
    }

    const selectedPaymentTypes = pageData.value.selectedPaymentTypes
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedPaymentTypes.length > 0) {
      filters.push({
        label: "PAYMENT TYPE",
        key: selectedPaymentTypes.join("-") + Math.random(),
        value: selectedPaymentTypes.join(", "),
        reset: () => {
          pageData.value.searchPaymentTypesValue.value = "";
          pageData.value.filter.paymentTypes = [];
          pageData.value.selectedPaymentTypes = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.filter.t = "";
    switch (name) {
      case "key": {
        pageData.value.filter.key = value;
        break;
      }
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        pageData.value.filter.t = ApiHelper.randomFormId().toString();
        break;
      }
      case "amount": {
        pageData.value.filter.amount = value;
        const amountArr = value.split("-");
        pageData.value.filter.minAmount = amountArr[0];
        pageData.value.filter.maxAmount =
          amountArr[1] !== undefined ? amountArr[1] : "";
        break;
      }
      case "credit": {
        pageData.value.filter.credit = value;
        const creditArr = value.split("-");
        pageData.value.filter.minCredit = creditArr[0];
        pageData.value.filter.maxCredit =
          creditArr[1] !== undefined ? creditArr[1] : "";
        break;
      }
      case "discountAmount": {
        pageData.value.filter.discountAmount = value;
        const amountArr = value.split("-");
        pageData.value.filter.minDiscountAmount = amountArr[0];
        pageData.value.filter.maxDiscountAmount =
          amountArr[1] !== undefined ? amountArr[1] : "";
        break;
      }
      case "endDate": {
        pageData.value.filter.endDate = value;
        const dateArr = value.split("-");
        pageData.value.filter.minEndDate = dateArr[0];
        pageData.value.filter.maxEndDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        // pageData.value.filter.t = ApiHelper.randomFormId().toString();
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const inSelectedIds = (item: any) => {
    const existedItem = pageData.value.selectedIds.find(
      (id: any) =>
        id == item.transactionId &&
        item.transactionIds == pageData.value.selectedTransactionIds
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const updateSelectedIds = (item: any) => {
    // save selected transactionIds of this item
    if (pageData.value.selectedTransactionIds == item.transactionIds) {
      pageData.value.selectedTransactionIds = "";
      pageData.value.selectedIds = [];
    } else {
      pageData.value.selectedTransactionIds = item.transactionIds;
      pageData.value.selectedIds = [item.transactionId];
    }
  };

  const updateRefundInfo = (item: any) => {
    // just allow select an item at a time (see as a radio button)
    // pageData.value.selectedIds = pageData.value.selectedIds.filter(
    //   (id: string) => id == item.transactionId
    // );
    pageData.value.refundInfo = [];
    if (inSelectedIds(item)) {
      pageData.value.refundInfo.push({
        transactionId: item.transactionId,
        transactionAmount: item.transactionAmount,
        transactionAmountFormatted: item.transactionAmountFormatted,
        transactionTypeId: item.transactionTypeId,
        transactionIds: item.transactionIds || "",
        details: []
      });
    }
  };

  const resetSelectedIds = () => {
    pageData.value.selectedIds = [];
    pageData.value.selectedTransactionIds = "";
  };

  const allowRefund = (item: any) => {
    const transactionId = item.transactionId;
    const transactionTypeId = item.transactionTypeId || 0;
    let valid = true;
    // if (!isNaN(parseFloat(transactionId))) {
    //   // no allow if is a number
    //   valid = false;
    // }
    if (
      transactionId.indexOf("re_") != -1 ||
      item.transactionTypeId == TransactionType.Refund
    ) {
      // detect this is a refund id
      valid = false;
    }
    if (
      item.transactionAmount ==
      parseFloat((item.refundedAmount + item.transferAmount).toFixed(2))
    ) {
      // if refund/transfer all the paid amount, so cannot refund more
      valid = false;
    }
    if ((item.scholarshipUsingDiscount || 0) == 1) {
      valid = false;
    }
    if (
      transactionTypeId == TransactionType.WriteOff ||
      transactionTypeId == TransactionType.Transfer
    ) {
      valid = false;
    }

    return valid;
  };

  const getUserSettings = async () => {
    // userSettings
    try {
      userSettings.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/users/userSettings",
        {},
        { customizeType: "transactionsCustomView" },
        "core"
      );
      if (result.status === 1) {
        const transactionsCustomView = result.data.transactionsCustomView || [];
        // default options
        const options = [
          {
            name: "Transaction ID",
            optional: false,
            checked: true
          },
          {
            name: "Registration(s)",
            optional: true,
            checked: true
          },
          {
            name: "Event / Type",
            optional: true,
            checked: true
          },
          {
            name: "User",
            optional: true,
            checked: false
          },
          {
            name: "Posting / GL Code",
            optional: true,
            checked: false
          },
          {
            name: "Cost Center Number",
            optional: true,
            checked: false
          },
          {
            name: "Amount",
            optional: false,
            checked: true
          },
          {
            name: "Discount Name/Amount",
            optional: true,
            checked: true
          },
          {
            name: "Type",
            optional: true,
            checked: true
          },
          {
            name: "Payment Type",
            optional: true,
            checked: true
          },
          {
            name: "Date",
            optional: true,
            checked: true
          },
          {
            name: "Event End Date",
            optional: true,
            checked: false
          },
          {
            name: "Completion Status",
            optional: true,
            checked: false
          }
        ];
        if (transactionsCustomView.length) {
          for (const item of options) {
            const savedData = transactionsCustomView.find(
              (t: any) => t.name == item.name
            );
            if (savedData) {
              Object.assign(item, savedData);
            }
          }
        }

        userSettings.value.transactionsCustomView = options;
      }
    } catch (error) {
      console.log(error);
    } finally {
      userSettings.value.isLoading = false;
    }
  };

  const updateTransactionsCustomView = async () => {
    const transactionsCustomView =
      userSettings.value.transactionsCustomView || [];
    checkGreater();
    if (transactionsCustomView.length == 0) return;

    const result = await ApiHelper.callApi(
      "put",
      "/users/userSettings",
      {
        transactionsCustomView,
        customizeType: "transactionsCustomView"
      },
      {},
      "core"
    );
  };

  const showOption = (item: any) => {
    switch (item.name) {
      case "Posting / GL Code":
        return pageData.value.showGlCodes;
      case "Cost Center Number":
        return pageData.value.showCostCenter;
    }
    return true;
  };

  const showColumn = (itemName: string) => {
    const transactionsCustomView =
      userSettings.value.transactionsCustomView || [];
    const relatedItem: any = transactionsCustomView.find(
      (item: any) => item.name.toLowerCase() == itemName.toLowerCase()
    );
    if (relatedItem) {
      return relatedItem.checked || false;
    }

    return true;
  };

  const isRefundService = (item: any) => {
    if (
      item.transactionId.indexOf("re_") != -1 &&
      (item.serviceNames || "") != ""
    ) {
      return true;
    }

    return false;
  };

  const getDiscountString = (discountInfo: any = []) => {
    const ret = [];
    for (const item of discountInfo) {
      ret.push(`${item.discountName}: ${item.discountAmountFormatted}`);
    }
    return ret.join(", ");
  };

  const exportTransactions = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const view = pageData.value.filterView || 1;
      const result = await ApiHelper.callApi(
        "get",
        "/transactions",
        {},
        {
          getAll: 1,
          getExportData: 1,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: context.root.$route.query.key || "",
          minAmount: pageData.value.filter.minAmount || "",
          maxAmount: pageData.value.filter.maxAmount || "",
          minCredit: pageData.value.filter.minCredit || "",
          maxCredit: pageData.value.filter.maxCredit || "",
          minDiscountAmount: pageData.value.filter.minDiscountAmount || "",
          maxDiscountAmount: pageData.value.filter.maxDiscountAmount || "",
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || "",
          profiles: context.root.$route.query.profiles || "",
          events: context.root.$route.query.events || "",
          types: context.root.$route.query.types || "",
          participantIds: context.root.$route.query.participantIds || "",
          participantId: context.root.$route.query.participantId || 0,
          addonServiceId: context.root.$route.query.addonServiceId || 0,
          view
        }
      );
      if (result.status === 1) {
        // const transactions = result.data.items.map((item: any) => {
        //   const discountInfo = item.discountInfo || [];
        //   // group by discountId
        //   const discounts: any = [];
        //   for (const discount of discountInfo) {
        //     const inList = discounts.find(
        //       (d: any) => d.discountId == discount.discountId
        //     );
        //     if (!inList) {
        //       discounts.push({
        //         discountId: discount.discountId,
        //         discountName: discount.discountName,
        //         discountCode: discount.discountCode || "",
        //         discountAmount: discount.discountAmount,
        //         discountAmountFormatted: dollarFormat(discount.discountAmount)
        //       });
        //     } else {
        //       inList.discountAmount += discount.discountAmount;
        //       inList.discountAmount = parseFloat(
        //         inList.discountAmount.toFixed(2)
        //       );
        //       inList.discountAmountFormatted = dollarFormat(
        //         inList.discountAmount
        //       );
        //     }
        //   }

        //   return {
        //     ...item,
        //     discountInfo: discounts
        //   };
        // });

        // const columns = [
        //   "Transaction ID",
        //   "Registration(s)",
        //   "Event / Type",
        //   "Posting / GL Code",
        //   "Cost Center Number",
        //   "Amount",
        //   "Discount Name/Amount",
        //   "Type",
        //   "Date"
        // ];

        // const exportData = (transactions || []).map((item: any) => {
        //   const itemData = [];
        //   if (showColumn("Transaction ID")) {
        //     itemData.push(`"${item.transactionId}"`);
        //   }
        //   if (showColumn("Registration(s)")) {
        //     itemData.push(`"${item.fullName || ""}"`);
        //   }
        //   if (showColumn("Event / Type")) {
        //     itemData.push(
        //       item.eventId > 0 && !isRefundService(item)
        //         ? `"${item.eventName}"`
        //         : `"${item.description}"`
        //     );
        //   }
        //   if (showColumn("Posting / GL Code")) {
        //     itemData.push(`"${item.finalPostingCode}"`);
        //   }
        //   if (showColumn("Cost Center Number")) {
        //     itemData.push(`"=""${item.finalCostCenter}"""`);
        //   }
        //   if (showColumn("Amount")) {
        //     itemData.push(`"${item.transactionAmountFormatted}"`);
        //   }
        //   if (showColumn("Discount Name/Amount")) {
        //     itemData.push(`"${getDiscountString(item.discountInfo)}"`);
        //   }
        //   if (showColumn("Type")) {
        //     itemData.push(`"${item.typeName || ""}"`);
        //   }
        //   if (showColumn("Date")) {
        //     itemData.push(`"${item.transactionTsFormatted}"`);
        //   }
        //   return itemData;
        // });

        // const headers: any = [];
        // for (const column of columns) {
        //   if (showColumn(column)) {
        //     headers.push(column.toUpperCase());
        //   }
        // }

        // const fileName = ["Transactions"];
        // fileName.push(moment().format("MMDDYYYY_HHMMSS"));
        // await ApiHelper.generateCsv(
        //   exportData,
        //   headers,
        //   fileName.join("_"),
        //   false
        // );

        if (view == 2) {
          // export for Ledger View
          /*
          const headers = [
            "TRANSACTION ID",
            "DESCRIPTION",
            "REGISTRATION",
            "EVENT / TYPE",
            "POSTING / GL CODE",
            "COST CENTER",
            "AMOUNT",
            "TYPE",
            "PAYMENT TYPE",
            "DATE"
          ];
          */
          const headers = [];
          headers.push("TRANSACTION ID");
          headers.push("DESCRIPTION");
          headers.push("REGISTRATION");
          headers.push("EVENT / TYPE");
          if (pageData.value.showGlCodes) {
            headers.push("POSTING / GL CODE");
          }
          if (pageData.value.showCostCenter) {
            headers.push("COST CENTER");
          }
          headers.push("AMOUNT");
          headers.push("TYPE");
          headers.push("PAYMENT TYPE");
          headers.push("DATE");
          const exportData = (result.data.items || []).map((item: any) => {
            const rows = [];
            rows.push(
              `"${item.transactionId || item.type}${
                item.type == "Discount" ? ` (${item.discountName})` : ""
              }"`
            );
            rows.push(`"${item.itemDesc}"`);
            rows.push(`"${item.fullName}"`);
            rows.push(`"${item.eventName} / ${item.pTypeName}"`);
            if (pageData.value.showGlCodes) {
              rows.push(`"${item.finalPostingCode}"`);
            }
            if (pageData.value.showCostCenter) {
              rows.push(`"${item.finalCostCenter}"`);
            }
            rows.push(`"${item.amountFormatted}"`);
            rows.push(`"${item.typeName}"`);
            rows.push(
              `"${item.cardBrand || ""}${
                item.cardFunding && item.cardFunding != "credit"
                  ? `(${item.cardFunding})`
                  : ``
              }"`
            );
            rows.push(`"${item.dateTimeFormatted}"`);
            return rows;
            /*
            return [
              `"${item.transactionId || item.type}${
                item.type == "Discount" ? ` (${item.discountName})` : ""
              }"`,
              `"${item.itemDesc}"`,
              `"${item.fullName}"`,
              `"${item.eventName} / ${item.pTypeName}"`,
              `"${item.finalPostingCode}"`,
              `"${item.finalCostCenter}"`,
              `"${item.amountFormatted}"`,
              `"${item.typeName}"`,
              `"${item.cardBrand || ""}${
                item.cardFunding && item.cardFunding != "credit"
                  ? `(${item.cardFunding})`
                  : ``
              }"`,
              `"${item.dateTimeFormatted}"`
            ];
            */
          });

          const fileName = ["BalanceSheet"];
          fileName.push(moment().format("MMDDYYYY_HHMMSS"));
          await ApiHelper.generateCsv(
            exportData,
            headers,
            fileName.join("_"),
            false
          );
          ApiHelper.setDataLoading(false);
          return;
        }

        // standard view
        // export based on transaction details data
        const headers = [
          "EXTERNAL TRANSACTION ID",
          "TRANSACTION ID",
          "EVENT NAME",
          "USER",
          "PARTICIPANT NAME",
          "POSTING CODE",
          "POSTING CODE NAME",
          "GL CODE",
          "COST CENTER NUMBER",
          "DESCRIPTION",
          "AMOUNT"
        ];

        const exportData = (result.exportData || []).map((item: any) => {
          const itemData = [];
          itemData.push(item.externalTransactionId);
          itemData.push(item.transactionId);
          if (showColumn("Event / Type")) {
            itemData.push(item.eventName);
          }
          if (showColumn("user")) {
            itemData.push(item.userfullName || "Online");
          }
          if (showColumn("Registration(s)")) {
            itemData.push([item.firstName, item.lastName].join(" "));
          }
          if (showColumn("Posting / GL Code")) {
            itemData.push(item.postingCode || "");
          }
          if (showColumn("Posting / GL Code")) {
            itemData.push(item.postingCodeDesc || "");
          }
          if (showColumn("Posting / GL Code")) {
            itemData.push(
              (item.glCode || "") != "" ? `"=""${item.glCode || ""}"""` : `""`
            );
          }
          if (showColumn("Cost Center Number")) {
            itemData.push(
              (item.costCenter || "") != ""
                ? `"=""${item.costCenter || ""}"""`
                : `""`
            );
          }
          if (showColumn("Event / Type")) {
            itemData.push(item.description);
          }
          itemData.push(item.transactionAmountFormatted);
          return itemData;
          // return [
          //   `"${item.externalTransactionId || ""}"`,
          //   `"${item.transactionId}"`,
          //   `"${item.eventName}"`,
          //   `"${item.userfullName || "Online"}"`,
          //   `"${[item.firstName, item.lastName].join(" ")}"`,
          //   `"${item.postingCode || ""}"`,
          //   `"${item.postingCodeDesc || ""}"`,
          //   (item.glCode || "") != "" ? `"=""${item.glCode || ""}"""` : `""`,
          //   (item.costCenter || "") != ""
          //     ? `"=""${item.costCenter || ""}"""`
          //     : `""`,
          //   `"${item.description}"`,
          //   `"${item.transactionAmountFormatted}"`
          // ];
        });

        const columns = [];
        columns.push("EXTERNAL TRANSACTION ID");
        columns.push("TRANSACTION ID");
        if (showColumn("Event / Type")) {
          columns.push("EVENT NAME");
        }
        if (showColumn("user")) {
          columns.push("USER");
        }
        if (showColumn("Registration(s)")) {
          columns.push("PARTICIPANT NAME");
        }
        if (showColumn("Posting / GL Code")) {
          columns.push("POSTING CODE");
        }
        if (showColumn("Posting / GL Code")) {
          columns.push("POSTING CODE NAME");
        }
        if (showColumn("Posting / GL Code")) {
          columns.push("GL CODE");
        }
        if (showColumn("Cost Center Number")) {
          columns.push("COST CENTER NUMBER");
        }
        if (showColumn("Event / Type")) {
          columns.push("DESCRIPTION");
        }
        columns.push("AMOUNT");
        const fileName = ["Transactions"];
        fileName.push(moment().format("MMDDYYYY_HHMMSS"));
        await ApiHelper.generateCsv(
          exportData,
          columns,
          fileName.join("_"),
          false
        );
        ApiHelper.setDataLoading(false);
      } else {
        ApiHelper.setDataLoading(false);
        ApiHelper.showErrorMessage(result.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const getColSpan = () => {
    let colspan = 0;
    const columns = [
      "Transaction ID",
      "Registration(s)",
      "Event / Type",
      "Posting / GL Code",
      "Cost Center Number",
      "Amount",
      "Discount Name/Amount",
      "Type",
      "Payment Type",
      "Date",
      "User",
      "Event End Date",
      "Completion Status"
    ];
    for (const column of columns) {
      if (showColumn(column)) {
        colspan += 1;
      }
    }
    return colspan;
  };

  const filterViewChange = () => {
    // reset filters
    pageData.value.filter = Object.assign(pageData.value.filter, {
      key: "",
      profiles: [],
      types: [],
      events: [],
      postingCodes: [],
      costCenter: [],
      unassignedEvent: null,
      profile: "",
      amount: "",
      minAmount: "",
      maxAmount: "",
      discountAmount: "",
      minDiscountAmount: "",
      maxDiscountAmount: "",
      date: "",
      minDate: "",
      maxDate: "",
      t: "",
      participantId: 0,
      addonServiceId: 0,
      fullName: "",
      serviceName: "",
      credit: "",
      minCredit: "",
      maxCredit: "",
      paymentTypes: []
    });
    // default sort
    pageData.value.sort = {
      order: pageData.value.filterView == 1 ? "7" : "0",
      direction: pageData.value.filterView == 1 ? "2" : "0"
    };
    pageData.value.selectedParticipants = [];
    pageData.value.selectedEvents = [];
    pageData.value.selectedPostingCodes = [];
    pageData.value.selectedCostCenter = [];
    pageData.value.selectedTypes = [];
    pageData.value.selectedUsers = [];
    pageData.value.selectedPaymentTypes = [];
    pageData.value.items = [];

    context.root.$router
      .replace({
        name: currentRoute,
        query: {
          view: pageData.value.filterView || 1
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });

    // loadData(1);
  };

  const doTransactionFilter = () => {
    // validate
    if (
      filterTransactionsPopup.value.controls.startDate.value &&
      filterTransactionsPopup.value.controls.endDate.value &&
      filterTransactionsPopup.value.controls.endDate.value <
        filterTransactionsPopup.value.controls.startDate.value
    ) {
      filterTransactionsPopup.value.controls.endDate.error =
        "To Date is invalid";
      return;
    }
    const start = moment(
      filterTransactionsPopup.value.controls.startDate.value
    );
    const end = moment(filterTransactionsPopup.value.controls.endDate.value);
    ApiHelper.gotoPage(context, {
      name: "FinancialTransactions",
      query: {
        minDate: start.isValid() ? start.format("YYYYMMDD") : undefined,
        maxDate: end.isValid() ? end.format("YYYYMMDD") : undefined,
        view: 2
      }
    });
    filterTransactionsPopup.value.isShow = false;
  };

  const showTransactionPopup = () => {
    filterTransactionsPopup.value.controls.startDate.error = "";
    filterTransactionsPopup.value.controls.startDate.value = "";
    filterTransactionsPopup.value.controls.endDate.error = "";
    filterTransactionsPopup.value.controls.endDate.value = "";
    filterTransactionsPopup.value.isShow = true;
  };

  const isTieEvent = (eventId: string) => {
    let ids = eventId.split(",").filter(id => parseInt(id) > 0);
    ids = [...new Set(ids)];
    if (!ids.length) {
      return 0;
    }

    return 1;
  };

  // init data
  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.events) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
      pageData.value.selectedEvents = pageData.value.filter.events.filter(
        (item: any) => item.selected
      );
    }
  })();
  (async () => {
    pageData.value.searchParticipantsValue.value =
      context.root.$route.query.searchParticipants || "";
    if (context.root.$route.query.profiles) {
      await pageData.value.searchParticipants(
        pageData.value.searchParticipantsValue.value
      );
      pageData.value.selectedParticipants = pageData.value.filter.profiles.filter(
        (item: any) => item.selected
      );
    }
  })();
  (async () => {
    pageData.value.searchTypesValue.value =
      context.root.$route.query.searchTypes || "";
    if (context.root.$route.query.types) {
      await pageData.value.searchTypes(pageData.value.searchTypesValue.value);
      pageData.value.selectedTypes = pageData.value.filter.types.filter(
        (item: any) => item.selected
      );
    }
    if (query.paymentTypes) {
      await pageData.value.searchPaymentTypes(
        pageData.value.searchPaymentTypesValue.value
      );
      pageData.value.selectedPaymentTypes = pageData.value.filter.paymentTypes.filter(
        (item: any) => item.selected
      );
    }
  })();
  (async () => {
    await getUserSettings();
    await loadData(1);
    showTransactionPopup();
  })();

  (async () => {
    if (
      typeof pageData.value.showGlCodes == "undefined" &&
      typeof pageData.value.showCostCenter == "undefined"
    ) {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
    }
  })();

  return {
    showOption,
    loadData,
    headerData,
    pageData,
    updateRouters,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    // pager
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    inSelectedIds,
    updateRefundInfo,
    updateSelectedIds,
    resetSelectedIds,
    allowRefund,
    toggleTransactionDetails,
    userSettings,
    updateTransactionsCustomView,
    showColumn,
    exportTransactions,
    isRefundService,
    getColSpan,
    filterViewChange,
    filterTransactionsPopup,
    doTransactionFilter,
    showTransactionPopup,
    isTieEvent
  };
}
