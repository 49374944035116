import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { AlertMessageError, PagerItem } from "@/types";
import TimelineTableFilters from "@/types/TimelineTableFilters";

export function useEventDashboardStore(context: any) {
  const selectedEventId = parseInt(context.root.$route.params.eventId);
  const pageData = ref<{
    isLoading: boolean;
    applications: any[];
    dashboard: {
      loaded: boolean;
      totalParticipants: number;
      totalPaid7d: number;
      totalPaid7dFormatted: string;
      totalCost7d: number;
      totalCost7dFormatted: string;
      totalOwed7d: number;
      totalOwed7dFormatted: string;
      totalPaid30d: number;
      totalPaid30dFormatted: string;
      totalCost30d: number;
      totalCost30dFormatted: string;
      totalOwed30d: number;
      totalOwed30dFormatted: string;
      startDate: string;
      endDate: string;
    };
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: TimelineTableFilters;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
    searchProfilesValue: {
      value: string;
    };
    searchProfiles: Function;
    searchProfilesLoading: boolean;
  }>({
    isLoading: true,
    applications: [],
    dashboard: {
      loaded: false,
      totalParticipants: 0,
      totalPaid7d: 0,
      totalPaid7dFormatted: "",
      totalCost7d: 0,
      totalCost7dFormatted: "",
      totalOwed7d: 0,
      totalOwed7dFormatted: "",
      totalPaid30d: 0,
      totalPaid30dFormatted: "",
      totalCost30d: 0,
      totalCost30dFormatted: "",
      totalOwed30d: 0,
      totalOwed30dFormatted: "",
      startDate: "",
      endDate: ""
    },
    skip: 0,
    take: 8,
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "4",
      direction: context.root.$route.query.direction || "2"
    },
    filter: {
      type: ApiHelper.getActionTypeOptions(),
      status: ApiHelper.getParticipantStatusOptions({
        getDeniedStatus: true,
        getRefundStatus: true,
        getStartedStatus: true,
        getCreatedStatus: true,
        getUpdatedStatus: true,
        getDeletedStatus: true,
        getTransferedStatus: true
      }),
      unassignedEvent: undefined,
      unassignedProfile: undefined,
      events: [],
      profile: [],
      description: "",
      date:
        (context.root.$route.query.minDate || "") +
        "-" +
        (context.root.$route.query.maxDate || ""),
      minDate: context.root.$route.query.minDate || "",
      maxDate: context.root.$route.query.maxDate || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.event || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(item => ({
        ...item,
        selected: eventIds.includes(item.id)
      }));
      pageData.value.searchEventsLoading = false;
    },
    searchProfilesLoading: false,
    searchProfilesValue: {
      value: ""
    },
    searchProfiles: async (searchValue: string) => {
      pageData.value.searchProfilesLoading = true;
      const profileIds = (context.root.$route.query.profile || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.profile = await ApiHelper.getProfileOptions({
          getAll: 1,
          includeDenied: 1,
          inIds: context.root.$route.query.profiles,
          eventIdFilter: parseInt(context.root.$route.params.eventId)
        });
        pageData.value.filter.unassignedProfile = {
          id: "0",
          text: "UN-ASSIGNED TO PROFILES",
          selected: profileIds.includes(0)
        };
      } else {
        pageData.value.filter.profile = await ApiHelper.getProfileOptions({
          includeDenied: 1,
          key: searchValue,
          eventIdFilter: parseInt(context.root.$route.params.eventId)
        });
        if (profileIds.includes(0)) {
          pageData.value.filter.unassignedProfile = {
            id: "0",
            text: "UN-ASSIGNED TO PROFILES",
            selected: profileIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedProfile = null;
        }
      }
      pageData.value.filter.profile = pageData.value.filter.profile.map(
        item => ({
          ...item,
          selected: profileIds.includes(item.id)
        })
      );
      pageData.value.searchProfilesLoading = false;
    }
  });

  const getDashboardData = async () => {
    try {
      const result = await ApiHelper.callApi(
        "get",
        "/events/" + selectedEventId + "/dashboard",
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const eventData = result.data || {};
        if (eventData) {
          pageData.value.applications = eventData.applications || [];
          pageData.value.dashboard.totalParticipants =
            eventData.eventDashboard.totalParticipants;
          pageData.value.dashboard.totalPaid7d =
            eventData.eventDashboard.totalPaid7d || 0;
          pageData.value.dashboard.totalPaid7dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalPaid7d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.totalCost7d =
            eventData.eventDashboard.totalCost7d || 0;
          pageData.value.dashboard.totalCost7dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalCost7d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.totalOwed7d =
            eventData.eventDashboard.totalOwed7d || 0;
          pageData.value.dashboard.totalOwed7dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalOwed7d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.totalPaid30d =
            eventData.eventDashboard.totalPaid30d || 0;
          pageData.value.dashboard.totalPaid30dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalPaid30d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.totalCost30d =
            eventData.eventDashboard.totalCost30d || 0;
          pageData.value.dashboard.totalCost30dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalCost30d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.totalOwed30d =
            eventData.eventDashboard.totalOwed30d || 0;
          pageData.value.dashboard.totalOwed30dFormatted = ApiHelper.dollarFormat(
            eventData.eventDashboard.totalOwed30d,
            false,
            {
              useBrackets: true
            }
          );
          pageData.value.dashboard.startDate = eventData.tsStartFormatted;
          pageData.value.dashboard.endDate = eventData.tsEndFormatted;
        }
        pageData.value.dashboard.loaded = true;
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const errors = ref<AlertMessageError[]>([]);

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    const profileOptions = [];
    if (pageData.value.filter.unassignedProfile) {
      profileOptions.push(pageData.value.filter.unassignedProfile);
    }
    pageData.value.filter.profile.map((item: any) => {
      profileOptions.push(item);
    });
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          profile:
            ApiHelper.convertSelectedOptionsToString(profileOptions) ||
            undefined,
          event:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          status:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.status
            ) || undefined,
          type:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.type
            ) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          searchProfiles: pageData.value.searchProfilesValue.value || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    const filterValues = {
      skip: pageData.value.skip,
      take: pageData.value.take,
      order: parseInt(pageData.value.sort.order),
      direction: parseInt(pageData.value.sort.direction),
      eventId: selectedEventId,
      profile: context.root.$route.query.profile || "",
      event: context.root.$route.query.event || "",
      type: context.root.$route.query.type || "",
      status: context.root.$route.query.status || "",
      minDate: pageData.value.filter.minDate,
      maxDate: pageData.value.filter.maxDate
    };

    const result = await ApiHelper.callApi(
      "get",
      "/timelines",
      {},
      filterValues
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = result.data.totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      const list = result.data.timelines.map((item: any) => {
        return {
          id: item.actiontype + "" + item.profileId + "" + item.participantId,
          isChecked: false,
          atid: item.atid || 0,
          actiontype: item.actiontype || "",
          actiondesc: item.actiondesc || "",
          actionname: item.actionname,
          fullname: ApiHelper.getFullName(item.firstName, item.lastName),
          profileId: item.profileId || 0,
          userId: item.profileId || 0,
          username: item.username || "",
          userFullName: ApiHelper.getFullName(
            item.userFirstName,
            item.userLastName
          ),
          participantId: item.participantId || "",
          participantStatus: item.participantStatus || 0,
          actionTs: item.actionTs
            ? moment(item.actionTs).format("MM/DD/YYYY")
            : "",
          actionTsFormatted: item.actionTsFormatted,
          isNew: item.isNew,
          actionId: item.actionId || 0
        };
      });
      pageData.value.items = list;
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page) || 1;
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return [];
  };

  // filter functions
  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "description":
        pageData.value.filter.description = value;
        break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate =
          dateArr[0] !== undefined ? dateArr[0] : "";
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedProfiles = pageData.value.filter.profile
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedProfiles.length > 0) {
      filters.push({
        label: "PROFILE",
        key: selectedProfiles.join("-") + Math.random(),
        value: selectedProfiles.join(", "),
        reset: () => {
          pageData.value.filter.profile.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes = pageData.value.filter.type
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.filter.type.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.description !== "") {
      filters.push({
        label: "DESCRIPTION",
        key: pageData.value.filter.description + Math.random(),
        value: pageData.value.filter.description,
        reset: () => {
          pageData.value.filter.description = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate !== ""
    ) {
      let date = "";
      if (pageData.value.filter.minDate && pageData.value.filter.maxDate) {
        date =
          pageData.value.filter.minDate + "-" + pageData.value.filter.maxDate;
      } else if (pageData.value.filter.minDate) {
        date = pageData.value.filter.minDate + "-";
      } else if (pageData.value.filter.maxDate) {
        date = pageData.value.filter.maxDate + "-";
      }
      filters.push({
        label: "DATE",
        key: date + Math.random(),
        value: date,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }

    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.unassignedEvent = null;
          pageData.value.filter.events = [];
          pageData.value.searchEventsValue.value = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
    if (pageData.value.activeTab == "profile") {
      if (pageData.value.filter.profile.length === 0) {
        await pageData.value.searchProfiles(
          pageData.value.searchProfilesValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  // init data
  (async () => {
    loadData(1);
    getDashboardData();
  })();

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.event) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  (async () => {
    pageData.value.searchProfilesValue.value =
      context.root.$route.query.searchProfiles || "";
    if (context.root.$route.query.profile) {
      await pageData.value.searchProfiles(
        pageData.value.searchProfilesValue.value
      );
    }
  })();

  (async () => {
    const statusIds = (context.root.$route.query.status || "").split(",");
    pageData.value.filter.status.forEach((item: any) => {
      if (statusIds.includes(item.id)) {
        item.selected = true;
      }
    });
  })();

  (async () => {
    const typeIds = (context.root.$route.query.type || "").split(",");
    pageData.value.filter.type.forEach((item: any) => {
      if (typeIds.includes(item.id)) {
        item.selected = true;
      }
    });
  })();

  return {
    updateSortValue,
    updateRouters,
    errors,
    pageData,
    // filters
    updateFilterValue,
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange
  };
}
