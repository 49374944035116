import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { ApiType } from "@/helpers/ApiHelper";

export function useMessagingTemplatesDetailsStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const pageData = ref({
    isLoading: true,
    details: {
      id: "",
      name: "",
      content: "",
      css: "",
      params: ""
    }
  });
  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/campaigns/templates/" + selectedId,
      {},
      {},
      ApiType.CAMPAIGN
    );
    if (result.status === 1) {
      const item = result.data || {};
      pageData.value.details.id = item.id || "...";
      pageData.value.details.name = item.name || "...";
      pageData.value.details.content = item.content || "...";
      pageData.value.details.css = item.css || "";
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.gotoPage(context, { name: "MessagingTemplates" });
    }
    ApiHelper.setDataLoading(false);
  })();
  return {
    pageData
  };
}
