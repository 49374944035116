/* eslint-disable @typescript-eslint/no-use-before-define */
import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { PagerItem, SelectOption } from "@/types";

type BarData = {
  isLoaded: boolean;
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      stack: string;
      data: number[];
    }[];
  };
  options: object;
};

export function useEventFinancialStore(context: any) {
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const barData = ref<BarData>({
    isLoaded: false,
    data: {
      labels: [],
      datasets: []
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        intersect: false
      },
      plugins: {
        title: {
          display: false
        },
        tooltip: {
          display: false,
          mode: "index",
          intersect: false
        }
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            barPercentage: 0.1,
            ticks: {
              fontColor: "#b9b9b8",
              fontSize: 9,
              padding: 16,
              fontStyle: "bold"
            },
            gridLines: {
              lineWidth: 0,
              drawBorder: false,
              display: false
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  });

  const chartData = ref<{
    isLoaded: boolean;
    data: {
      labels: any[];
      datasets: {
        label: string;
        stack: string;
        type: string;
        backgroundColor: string;
        data: any[];
      }[];
    };
  }>({
    isLoaded: false,
    data: {
      datasets: [],
      labels: []
    }
  });

  const pageData = ref<{
    isLoading: boolean;
    event: object;
    totalParticipants: number;
    tab: string;
    activeTab: string;
    percentTransactions: number;
    percentPlans: number;
    transactions: {
      transactionId: number;
      description?: string;
      profileId?: number;
      firstName?: string;
      lastName?: string;
      planId?: number;
      eventId?: number;
      eventName?: string;
      transactionAmount?: number;
      transactionTs?: Date;
      typeName?: string;
      sourceId?: number;
      serviceName?: string;
    }[];
    totalTransactions: number;
    paymentPlans: any[];
    totalPlans: number;
    paying: boolean;
    discounts: any;
    totalDiscounts: number;
    totalFundBucket: number;
    fundBuckets: {
      skip: number;
      take: number;
      items: any;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        camperName: string;
        services: SelectOption[];
        totalTransactions: string;
        minTransactions: string;
        maxTransactions: string;
        totalFunds: string;
        minTotalFunds: string;
        maxTotalFunds: string;
      };
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    searchServicesValue: {
      value: string;
    };
    searchServicesLoading: boolean;
    selectedServices: SelectOption[];
    discountDetails: any;
    refundInfo: any;
    selectedFundbuckets: any;
    totalPaids: {
      paidAmount: number;
      plansPaidAmount: number;
      fundBucketPaidAmount: number;
      paidAmountFormatted: string;
      plansPaidAmountFormatted: string;
      fundBucketPaidAmountFormatted: string;
    };
    detailsVisible: number;
  }>({
    isLoading: true,
    tab: "plan",
    activeTab: "",
    event: {
      status: 2
    },
    totalParticipants: 0,
    percentTransactions: 0,
    percentPlans: 0,
    totalTransactions: 0,
    totalPlans: 0,
    transactions: [],
    paymentPlans: [],
    paying: false,
    discounts: [],
    totalDiscounts: 0,
    fundBuckets: {
      skip: 0,
      take: 8,
      items: [],
      sort: {
        order: "7",
        direction: "2"
      },
      filter: {
        camperName: "",
        services: [],
        totalTransactions: "",
        minTransactions: "",
        maxTransactions: "",
        totalFunds: "",
        minTotalFunds: "",
        maxTotalFunds: ""
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    totalFundBucket: 0,
    searchServicesValue: {
      value: ""
    },
    searchServicesLoading: false,
    selectedServices: [],
    discountDetails: {
      show: false,
      discountId: 0,
      details: null
    },
    refundInfo: [],
    selectedFundbuckets: [],
    totalPaids: {
      paidAmount: 0,
      plansPaidAmount: 0,
      fundBucketPaidAmount: 0,
      paidAmountFormatted: "",
      plansPaidAmountFormatted: "",
      fundBucketPaidAmountFormatted: ""
    },
    detailsVisible: -1
  });

  const loadData = () => {
    ApiHelper.setDataLoading(true);
    (async () => {
      try {
        ApiHelper.callApi(
          "get",
          "/finances/dashboard",
          {},
          {
            eventId: selectedEventId,
            getInfo: "totalDiscounts,totalFundBucket"
          }
        ).then(response => {
          if (!response.status) {
            ApiHelper.showErrorMessage("Event is not found");
            ApiHelper.gotoPage(context, { name: "Events" });
            return;
          }
          const last12months = response.data.chartData || [];
          chartData.value.data.labels = last12months.map((value: any) => {
            return value.label;
          });
          chartData.value.data.datasets.push({
            label: "New Charges",
            backgroundColor: "#000000",
            stack: "New Charges",
            type: "bar",
            data: last12months.map((value: any) => {
              return value.newChargeTotal;
            })
          });
          chartData.value.data.datasets.push({
            label: "Payments",
            backgroundColor: "#5e886d",
            stack: "Payments",
            type: "bar",
            data: last12months.map((value: any) => {
              return value.paymentTotal;
            })
          });
          chartData.value.data.datasets.push({
            label: "Balance",
            backgroundColor: "#000000",
            stack: "Balance",
            type: "line",
            data: last12months.map((value: any) => {
              return value.totalOwed;
            })
          });

          chartData.value.isLoaded = true;

          pageData.value.percentTransactions = Math.ceil(
            response.data.percentTransactions || 0
          );
          pageData.value.percentPlans = Math.floor(
            response.data.percentPlans || 0
          );
          // load transactions
          pageData.value.transactions = (response.data.transactions || []).map(
            (item: any) => {
              item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
              item.transactionAmountFormatted = ApiHelper.dollarFormat(
                item.transactionAmount
              );
              return item;
            }
          );
          // load payment plans
          pageData.value.paymentPlans = (response.data.plans || []).map(
            (item: any) => {
              item.amountFormatted = ApiHelper.dollarFormat(item.amount);
              item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
              return item;
            }
          );
          pageData.value.totalTransactions =
            response.data.totalTransactions || 0;
          pageData.value.totalPlans = response.data.totalPlans || 0;
          // load chart
          const item = response.data || {};
          const eventId = item.id || 0;
          if (!eventId) {
            ApiHelper.showErrorMessage(
              "Event #" + selectedEventId + " is not found"
            );
            ApiHelper.gotoPage(context, { name: "Events" });
            return;
          }

          const tsStart = new Date(item.tsStart);
          const tsEnd = new Date(item.tsEnd);
          const linkEventParticipants = item.linkEventParticipants || [];
          let pCurrent = 0;
          let pTotal = 0;
          let pPercent = 0;
          // let totalEventIncome = 0;
          // let totalEventOwed = 0;
          // let totalEventCost = 0;
          // let totalEventPercent = 0;
          let eAges = "";
          if (linkEventParticipants.length > 0) {
            pCurrent = 0;
            pPercent = 0;
            pTotal = item.linkEventParticipants[0].capacity || 0;
            eAges = item.linkEventParticipants[0].age || "";
          }
          // if (item.financial) {
          //   totalEventIncome = item.financial.totalIncome || 0;
          //   totalEventOwed = item.financial.totalOwed || 0;
          //   totalEventCost = item.financial.totalCost || 0;
          //   totalEventPercent = item.financial.percent || 0;
          // }
          const totalIncomes: {
            transM: number;
            transY: number;
            totalM: string;
            eventTotalInc: number;
          }[] = item.totalIncomes.map((value: any) => {
            return {
              transM: value.transM || 0,
              transY: value.transY || 0,
              totalM: value.totalM || "",
              totalInc: value.totalInc || 0
            };
          });
          barData.value.data.labels = totalIncomes.map((value: any) => {
            return value.totalM;
          });
          barData.value.data.datasets.push({
            label: "Revenue",
            backgroundColor: "#5e886d",
            stack: "Stack 0",
            data: totalIncomes.map((value: any) => {
              return value.totalInc;
            })
          });
          barData.value.isLoaded = true;

          const eventData = {
            id: item.id,
            isChecked: false,
            name: item.name,
            status: item.status || 0,
            participants: {
              current: pCurrent,
              total: pTotal,
              percent: pPercent
            },
            ages: eAges,
            date:
              tsStart.toLocaleDateString() + " - " + tsEnd.toLocaleDateString(),
            totalEventIncomes: totalIncomes,
            financial: {
              ...item.financial,
              totalPaidFormatted: ApiHelper.dollarFormat(
                item.financial.totalPaid || 0,
                false,
                { useBrackets: true }
              ),
              totalBalanceFormatted: ApiHelper.dollarFormat(
                item.financial.totalBalance || 0,
                false,
                { useBrackets: true }
              )
            }
            // financial: {
            //   totalEventIncome: ApiHelper.dollarFormat(totalEventIncome),
            //   totalEventOwed: ApiHelper.dollarFormat(totalEventOwed),
            //   totalEventCost: ApiHelper.dollarFormat(totalEventCost),
            //   percent: totalEventPercent.toFixed(0)
            // }
          };

          pageData.value.event = eventData;
          pageData.value.totalDiscounts = response.data.totalDiscounts || 0;
          pageData.value.totalFundBucket = response.data.totalFundBucket || 0;
          pageData.value.totalPaids = response.data.totalPaids;
          ApiHelper.setDataLoading(false);
        });
      } catch (err) {
        alert(JSON.stringify(err));
        ApiHelper.setDataLoading(false);
      }
    })();
  };

  const getDiscounts = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/events/${selectedEventId}/discountsDetails`,
        {},
        {}
      );
      if (result.status == 1) {
        pageData.value.discounts = result.data.discounts || [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const getFundBucket = async (page: number) => {
    try {
      ApiHelper.setDataLoading(true);
      pageData.value.fundBuckets.pager.page = page || 1;
      pageData.value.fundBuckets.skip =
        (pageData.value.fundBuckets.pager.page - 1) *
        pageData.value.fundBuckets.take;

      const result = await ApiHelper.callApi(
        "get",
        `/finances/fundtransactions`,
        {},
        {
          skip: pageData.value.fundBuckets.skip,
          take: pageData.value.fundBuckets.take,
          order: parseInt(pageData.value.fundBuckets.sort.order),
          direction: parseInt(pageData.value.fundBuckets.sort.direction),
          eventId: selectedEventId,
          camperName: pageData.value.fundBuckets.filter.camperName,
          minTransactions: pageData.value.fundBuckets.filter.minTransactions,
          maxTransactions: pageData.value.fundBuckets.filter.maxTransactions,
          minTotalFunds: pageData.value.fundBuckets.filter.minTotalFunds,
          maxTotalFunds: pageData.value.fundBuckets.filter.maxTotalFunds,
          servicesIds: pageData.value.selectedServices
            .map(item => item.id)
            .join(","),
          getInfo: "transactions"
        }
      );
      if (result.status == 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.fundBuckets.pager.totalPages = Math.ceil(
          totalCount / pageData.value.fundBuckets.take
        );
        pageData.value.fundBuckets.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.fundBuckets.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.fundBuckets.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.fundBuckets.pager.items = pagerList;

        pageData.value.fundBuckets.items = result.data.fundBuckets || [];
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        pageData.value.fundBuckets.items = [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const gotoPage = (page: string) => {
    pageData.value.fundBuckets.pager.page = parseInt(page);
    // updateRouters();
    getFundBucket(pageData.value.fundBuckets.pager.page);
  };

  const onPagerChange = (event: any) => {
    pageData.value.fundBuckets.pager.page = event.target.value;
    // updateRouters();
    getFundBucket(pageData.value.fundBuckets.pager.page);
  };

  const onClickPrev = () => {
    if (pageData.value.fundBuckets.pager.page > 1) {
      pageData.value.fundBuckets.pager.page -= 1;
      // updateRouters();
      getFundBucket(pageData.value.fundBuckets.pager.page);
    }
  };

  const onClickNext = () => {
    if (
      pageData.value.fundBuckets.pager.page <
      pageData.value.fundBuckets.pager.totalPages
    ) {
      pageData.value.fundBuckets.pager.page += 1;
      // updateRouters();
      getFundBucket(pageData.value.fundBuckets.pager.page);
    }
  };

  const togglePagerItems = () => {
    pageData.value.fundBuckets.pager.showPagerItems = !pageData.value
      .fundBuckets.pager.showPagerItems;
  };

  const closeFilterTab = () => {
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "services") {
      if (pageData.value.searchServicesValue.value != "") return;
      pageData.value.searchServicesLoading = true;
      pageData.value.fundBuckets.filter.services = [];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await searchServices("");
      pageData.value.searchServicesLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedServices();
    }
  };

  const getFiltersData = () => {
    const filters: any = [];
    // if (pageData.value.filter.followupPName !== "") {
    //   filters.push({
    //     label: "FOLLOW UP PARTICIPANT",
    //     key: pageData.value.filter.followupPName + Math.random(),
    //     value: pageData.value.filter.followupPName,
    //     reset: () => {
    //       pageData.value.filter.followupPName = "";
    //       // updateRouters();
    //       getFollowUp(1);
    //     }
    //   });
    // }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "camperName":
        pageData.value.fundBuckets.pager.page = 1;
        pageData.value.fundBuckets.filter.camperName = value;
        break;
      case "totalTransactions": {
        pageData.value.fundBuckets.filter.totalTransactions = value;
        const tmpArr = value.split("-");
        pageData.value.fundBuckets.filter.minTransactions =
          tmpArr[0] != undefined ? tmpArr[0] : "";
        pageData.value.fundBuckets.filter.maxTransactions =
          tmpArr[1] != undefined ? tmpArr[1] : "";
        break;
      }
      case "totalFunds": {
        pageData.value.fundBuckets.filter.totalFunds = value;
        const tmpArr = value.split("-");
        pageData.value.fundBuckets.filter.minTotalFunds =
          tmpArr[0] != undefined ? tmpArr[0] : "";
        pageData.value.fundBuckets.filter.maxTotalFunds =
          tmpArr[1] != undefined ? tmpArr[1] : "";
        break;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRouters();
  };

  const selectAllServices = () => {
    pageData.value.fundBuckets.filter.services = pageData.value.fundBuckets.filter.services.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedServices = [
      ...pageData.value.fundBuckets.filter.services
    ].filter(item => item.selected);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRouters();
    getFundBucket(1);
  };

  const resetServices = () => {
    pageData.value.fundBuckets.filter.services = pageData.value.fundBuckets.filter.services.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.selectedServices = [];
    pageData.value.fundBuckets.pager.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRouters();
    getFundBucket(1);
  };

  const searchServices = async (searchValue: string) => {
    pageData.value.searchServicesLoading = true;
    let list: any = [];
    if (!pageData.value.fundBuckets.filter.services.length) {
      const result = await ApiHelper.callApi(
        "get",
        `/finances/fundNames`,
        {},
        {
          getAll: 1,
          eventId: selectedEventId
        }
      );
      list = (result.fundNames || []).map((item: any) => ({
        id: item.addonServiceId,
        text: item.serviceName
      }));
    } else {
      list = [...pageData.value.fundBuckets.filter.services];
    }
    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.fundBuckets.filter.services = list;
    const serviceIds = pageData.value.selectedServices.map(item => item.id);
    pageData.value.fundBuckets.filter.services = pageData.value.fundBuckets.filter.services.map(
      item => ({
        ...item,
        selected: serviceIds.includes(item.id)
      })
    );
    pageData.value.searchServicesLoading = false;
  };

  const updateSelectedServices = async (init = false) => {
    if (init) {
      const searchValue = pageData.value.searchServicesValue.value || "";
      if (searchValue != "") {
        await searchServices(searchValue);
      }
    }
    pageData.value.selectedServices = [
      ...pageData.value.fundBuckets.filter.services
    ].filter(item => item.selected);
  };

  const updateRouters = async () => {
    // context.root.$router
    //   .replace({
    //     name: "EventFinancial",
    //     query: {
    //       fbPage: pageData.value.fundBuckets.pager.page + "",
    //       fbOrder: pageData.value.fundBuckets.sort.order,
    //       fbDirection: pageData.value.fundBuckets.sort.direction,
    //       camperName: pageData.value.fundBuckets.filter.camperName,
    //       minTransactions: pageData.value.fundBuckets.filter.minTransactions,
    //       maxTransactions: pageData.value.fundBuckets.filter.maxTransactions,
    //     }
    //   })
    //   .catch((err: any) => {
    //     // console.log("update routers error");
    //   });
    const obj = {
      name: "EventFinancial",
      query: {
        fbPage: pageData.value.fundBuckets.pager.page + "",
        fbOrder: pageData.value.fundBuckets.sort.order,
        fbDirection: pageData.value.fundBuckets.sort.direction,
        camperName: pageData.value.fundBuckets.filter.camperName,
        minTransactions: pageData.value.fundBuckets.filter.minTransactions,
        maxTransactions: pageData.value.fundBuckets.filter.maxTransactions,
        minTotalFunds: pageData.value.fundBuckets.filter.minTotalFunds,
        maxTotalFunds: pageData.value.fundBuckets.filter.maxTotalFunds,
        servicesIds: pageData.value.selectedServices
          .map(item => item.id)
          .join(",")
      }
    };
    const fullPath = context.root.$router.resolve(obj);
    window.history.replaceState("", "", fullPath.href);
  };

  const updateSortValue = async (
    sort: string,
    direction: string,
    type = ""
  ) => {
    if (type == "fund_bucket") {
      pageData.value.fundBuckets.sort.order = sort;
      pageData.value.fundBuckets.sort.direction = direction;
      updateRouters();
      await getFundBucket(pageData.value.fundBuckets.pager.page);
    }
  };

  const showDiscountDetailsModal = async (item: any) => {
    const discountId = item.discountId || 0;
    if (!discountId) return;

    pageData.value.discountDetails.discountId = discountId;
    pageData.value.discountDetails.details = item;
    pageData.value.discountDetails.show = true;
  };

  const toggleTransactionDetails = async (item: any, index: number) => {
    if (pageData.value.detailsVisible == index) {
      pageData.value.detailsVisible = -1;
    } else {
      pageData.value.detailsVisible = index;
    }
    if (pageData.value.detailsVisible != -1 && !("details" in item)) {
      // get item details
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/transactions/details",
        {},
        {
          transactionId: item.transactionId,
          transactionTypeId: item.transactionTypeId || 0,
          // tieEvent: isTieEvent(item.eventId)
          transactionIds: item.transactionIds,
          participantIds: item.participantIds
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        item.details = {
          transactionDetails: result.data.transactionDetails || [],
          transactionLines: result.data.transactionLines || [],
          transactionInfos: result.data.transactionInfos || [],
          refundLines: result.data.refundLines || [],
          events: result.data.events || []
        };
      } else {
        ApiHelper.showErrorMessage("Transaction not found", "Oops");
      }
    }
  };

  const isTieEvent = (eventId: string) => {
    let ids = eventId.split(",").filter(id => parseInt(id) > 0);
    ids = [...new Set(ids)];
    if (!ids.length) {
      return 0;
    }

    return 1;
  };

  // init data
  (async () => {
    loadData();
  })();

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/participants",
      {},
      {
        skip: 0,
        take: 8,
        order: "1",
        direction: "1",
        eventId: selectedEventId
      }
    );
    if (result.status == 1) {
      pageData.value.totalParticipants = result?.data.totalCount || 0;
    }
  })();

  return {
    chartData,
    loadData,
    barData,
    pageData,
    getDiscounts,
    getFundBucket,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    updateFilterValue,
    selectAllServices,
    resetServices,
    searchServices,
    updateSortValue,
    updateSelectedServices,
    updateRouters,
    showDiscountDetailsModal,
    toggleTransactionDetails,
    isTieEvent
  };
}
