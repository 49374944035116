var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HeaderProfiles",attrs:{"id":"headerContainer"}},[_c('div',{staticClass:"content-head have__tabs"},[_c('div',{staticClass:"head_left hide__ipad"},[_c('h2',{staticClass:"head_heading"},[_vm._v(" "+_vm._s(_vm.title || "People")+" "),(_vm.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(_vm._s(_vm.total))]):_vm._e()])]),_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__box position-relative"},[_vm._t("head_achecked"),(_vm.filters && _vm.filters.list)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideFilters),expression:"hideFilters"}],staticClass:"head__filter hide__ipad",class:{
            active:
              _vm.isShowFilters && (_vm.filters.list.length > 0 || !_vm.hideTagFilter)
          }},[_c('div',{staticClass:"filter__label d-flex align-items-center",on:{"click":_vm.toggleFilters}},[_vm._v(" Filters "),_c('span',{staticClass:"filter__number"},[_vm._v(_vm._s((_vm.filters.list.length || 0) + (_vm.tagFilter.value.length > 0 ? 1 : 0)))])]),_c('div',{staticClass:"filter__dropdown"},[(_vm.tagFilter && !_vm.hideTagFilter)?_c('div',{staticClass:"item__content",staticStyle:{"width":"100%","font-size":"10.3px","display":"flex"}},[_c('span',{staticClass:"lbl_tags"},[_vm._v("TAGS: ")]),_c('FormTagAutoComplete',{staticClass:"filter__tags",staticStyle:{"min-width":"90%"},attrs:{"placeholder":'Type a tag name...',"options":_vm.foundTags,"tags":_vm.tagFilter.options,"emptySearchOnSelect":true,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.suggestTags(key); },"select":function (ev) { return _vm.selectTag(ev.data); },"remove":function (item) { return _vm.removeTag(item); }}})],1):_vm._e(),_c('ul',{staticClass:"filter__items"},_vm._l((_vm.filters.list),function(item){return _c('li',{key:item.key},[_vm._v(" "+_vm._s(item.label || "")+": "),_c('span',{staticClass:"item__content",attrs:{"title":item.value}},[_vm._v(_vm._s(item.value || ""))]),_c('span',{staticClass:"item__delete",on:{"click":item.reset}})])}),0)])]):_vm._e(),_c('HeaderTabs',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideCustomize),expression:"hideCustomize"}],attrs:{"tabs":[
            {
              label: 'PERSON VIEW',
              route: '/profiles/person'
            },
            {
              label: 'FAMILY VIEW',
              route: '/profiles/family'
            },
            {
              label: 'CUSTOM VIEW',
              route: '/profiles/custom',
              img: _vm.$route.name == 'ProfilesCustom'
            }
          ]},on:{"toggleCustomize":_vm.toggleCustomize}}),(
            _vm.$route.name == 'ProfilesCustom' &&
              (_vm.userSettings.profilesCustomView || []).length
          )?[_c('div',{staticClass:"customize-columns-container"},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.customizeVisible),expression:"customizeVisible"}],staticClass:"customize-columns",class:{ active: _vm.customizeVisible }},_vm._l((_vm.userSettings.profilesCustomView),function(item,index){return _c('li',{key:index},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.checked),expression:"item.checked"}],staticClass:"input green",attrs:{"type":"checkbox","disabled":item.optional == false},domProps:{"checked":Array.isArray(item.checked)?_vm._i(item.checked,null)>-1:(item.checked)},on:{"change":[function($event){var $$a=item.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "checked", $$c)}},function($event){return _vm.$emit('updateProfilesCustomView')}]}}),_vm._v(" "+_vm._s(item.name.toUpperCase())+" ")])])}),0)])]:_vm._e(),_c('HeaderActions',{attrs:{"type":"profile"}})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }