import { ref } from "@vue/composition-api";
import { ApiHelper, EventHelper, FamilyHelper } from "@/helpers";
import { AlertMessageError, PagerItem, SelectOption } from "@/types";
import moment from "moment";
import { EventListRows } from "@/helpers/estimateNoOfListRows";

type Profile = {
  id: number;
  status: number;
  isChecked: boolean;
  logo: string;
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  balance: string;
  events: string;
  familyName: string;
  newRelationType: string;
};

type PageData = {
  isLoading: boolean;
  skip: number;
  take: number;
  items: Profile[];
  activeTab: string;
  arrTags: SelectOption[];
  arrProfiles: SelectOption[];
  arrBalances: SelectOption[];
  openItemId: number;
  sort: {
    order: string;
    direction: string;
  };
  filter: {
    profile: string;
    family: SelectOption[];
    age: string;
    date: string;
    gender: SelectOption[];
    events: SelectOption[];
    balance: string;
    minAge: string;
    maxAge: string;
    minBalance: string;
    maxBalance: string;
  };
  showHeadActions: boolean;
  pager: {
    showPagerItems: boolean;
    page: number;
    totalPages: number;
    total: number;
    items: PagerItem[];
  };
};

export function useFamilyEventsStore(context: any) {
  const selectedFamilyId = parseInt(context.root.$route.params.Id) || 0;
  const familyTypeOptions = ref<any[]>([]);
  const headerData = ref({
    title: "Events",
    subTitle: ""
  });

  const pageData = ref<PageData>({
    isLoading: true,
    skip: 0,
    take: EventListRows(),
    activeTab: "",
    arrTags: [],
    arrProfiles: [],
    arrBalances: [],
    openItemId: 0,
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      profile: "",
      family: [],
      age: "",
      date: "",
      gender: [],
      events: [],
      balance: "",
      minAge: "",
      maxAge: "",
      minBalance: "",
      maxBalance: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const errors = ref<AlertMessageError[]>([]);

  const loadData = async (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    const event =
      context.root.$route.query.event ||
      ApiHelper.convertSelectedOptionsToString(pageData.value.filter.events);

    try {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/registrations",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          event: event,
          key: pageData.value.filter.profile || "",
          familyId: selectedFamilyId,
          gender: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.gender
          ),
          minAge: pageData.value.filter.minAge || "",
          maxAge: pageData.value.filter.maxAge || "",
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || ""
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = (result.data.registrations || []).map(
          (item: any) => {
            const payPercent =
              item.participantCost > 0
                ? (item.paidAmount / item.participantCost) * 100
                : 0;
            return {
              ...item,
              balanceFormatted: ApiHelper.dollarFormat(item.balance, false, {
                useBrackets: true
              }),
              paidAmount: ApiHelper.dollarFormat(item.paidAmount),
              participantCost: ApiHelper.dollarFormat(item.participantCost),
              payPercent: payPercent > 100 ? 100 : payPercent
            };
          }
        );
      } else {
        const errorCode = result.errorCode || "";
        if (
          ["FAMILY_NOT_FOUND", "NOT_IN_FAMILY"].includes(errorCode) &&
          result.message
        ) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          ApiHelper.gotoPage(context, { name: "ProfilesFamily" });
        }
      }
    } catch (err) {
      console.log(err);
      pageData.value.items = [];
      ApiHelper.setDataLoading(false);
    }
  };
  const loadEvents = () => {
    (async () => {
      try {
        pageData.value.filter.events = await EventHelper.getEventOptions();
      } catch (err) {
        console.log("err", err);
      }
    })();
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  // init data
  (async () => {
    await loadData(1);
    loadEvents();
    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    familyTypeOptions.value = await ApiHelper.getFamilyTypeOptions();
    const family = await FamilyHelper.getFamilyInfo(selectedFamilyId);
    headerData.value.subTitle = family.familyName || "";
  })();

  return {
    headerData,
    pageData,
    // pager
    gotoPage,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext
  };
}
