import { ref } from "@vue/composition-api";
import { ApiHelper, EventHelper } from "@/helpers";
import { AlertMessageError, PagerItem, SelectOption } from "@/types";
import moment from "moment";
import { EventListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useEventsStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    sort: {
      order: string;
      direction: string;
    };
    items: {
      id: number;
      isChecked: boolean;
      name: string;
      participants: {
        current: number;
        total: number;
        percent: number;
      };
      ages: string;
      date: string;
      financials: {
        income: number;
        incomeFormatted: string;
        outstanding: number;
        outstandingFormatted: string;
        total: number;
        totalFormatted: string;
      };
    }[];
    activeTab: string;
    arrFromAges: SelectOption[];
    arrToAges: SelectOption[];
    arrStatuses: SelectOption[];
    filter: {
      status: string;
      event: string;
      participants: string;
      age: string;
      date: string;
      financial: string;
      minParticipant: string;
      maxParticipant: string;
      minAge: string;
      maxAge: string;
      minTotalIncome: string;
      maxTotalIncome: string;
      minDate: string;
      maxDate: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      total: number;
      totalCount: number;
      totalPages: number;
      items: PagerItem[];
    };
    syncVisible: number;
  }>({
    isLoading: true,
    skip: 0,
    take: EventListRows(),
    activeTab: "",
    arrFromAges: [],
    arrToAges: [],
    arrStatuses: [],
    filter: {
      status: "",
      event: "",
      age: "",
      date: "",
      participants: "",
      financial: "",
      minParticipant: "",
      maxParticipant: "",
      minAge: "",
      maxAge: "",
      minTotalIncome: "",
      maxTotalIncome: "",
      minDate: "",
      maxDate: ""
    },
    // sort defaut by event start date
    sort: {
      order: "4",
      direction: "2"
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalCount: 0,
      totalPages: 1,
      items: []
    },
    syncVisible: -1
  });

  const errors = ref<AlertMessageError[]>([]);
  const loadData = async (page: number, routeReplace = true) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;

    try {
      const requestObj = {
        skip: pageData.value.skip || 0,
        take: pageData.value.take || 8,
        order: parseInt(pageData.value.sort.order) || 1,
        direction: parseInt(pageData.value.sort.direction) || 1,
        key:
          pageData.value.filter.event !== "" ? pageData.value.filter.event : "",
        participantRange: pageData.value.filter.participants,
        financialRange: pageData.value.filter.financial,
        ageRange: pageData.value.filter.age,
        dateRange:
          pageData.value.filter.date !== "" ? pageData.value.filter.date : "",
        status: pageData.value.arrStatuses
          .filter((item: any) => item.selected)
          .map((item: any) => item.id)
          .join("-"),
        minAge: pageData.value.filter.minAge || "",
        maxAge: pageData.value.filter.maxAge || "",
        minParticipant: pageData.value.filter.minParticipant || "",
        maxParticipant: pageData.value.filter.maxParticipant || "",
        minTotalIncome: pageData.value.filter.minTotalIncome || "",
        maxTotalIncome: pageData.value.filter.maxTotalIncome || "",
        minDate: pageData.value.filter.minDate || "",
        maxDate: pageData.value.filter.maxDate || "",
        callFrom: "events_page"
      };

      // update route path
      if (routeReplace) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateRouteQuery(page, requestObj);
      }
      const result = await ApiHelper.callApi("get", "/events", {}, requestObj);

      ApiHelper.setDataLoading(false);

      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;

        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        pageData.value.pager.totalCount = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.events;
      } else if (result.status === 401) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    } catch (err) {
      console.log("loadData:err", err);
      ApiHelper.setDataLoading(false);
      // errors.value = [err];
    }
  };

  const updateRouteQuery = (page: number, requestObj: any) => {
    const queryObject: any = { page, ...requestObj };
    delete queryObject.skip;
    delete queryObject.take;
    delete queryObject.participantRange;
    delete queryObject.ageRange;
    delete queryObject.dateRange;
    delete queryObject.financialRange;
    delete queryObject.callFrom;
    for (const i in queryObject) {
      if (queryObject[i] == "") {
        delete queryObject[i];
      }
    }
    context.root.$router
      .replace({
        query: queryObject
      })
      .catch(() => {
        // nothing
      });
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "event":
        pageData.value.filter.event = value;
        break;
      case "participants": {
        pageData.value.filter.participants = value;
        const participantsArr = value.split("-");
        pageData.value.filter.minParticipant = participantsArr[0];
        pageData.value.filter.maxParticipant =
          participantsArr[1] !== undefined ? participantsArr[1] : "";
        break;
      }
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        break;
      }
      case "financial": {
        pageData.value.filter.financial = value;
        const totalArr = value.split("-");
        pageData.value.filter.minTotalIncome = totalArr[0];
        pageData.value.filter.maxTotalIncome =
          totalArr[1] !== undefined ? totalArr[1] : "";
        break;
      }
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.arrStatuses
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.arrStatuses.map((item: any) => {
            item.selected = false;
          });
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minParticipant !== "" ||
      pageData.value.filter.maxParticipant != ""
    ) {
      filters.push({
        label: "PARTICIPANTS",
        key: pageData.value.filter.participants + Math.random(),
        value: pageData.value.filter.participants,
        reset: () => {
          pageData.value.filter.participants = "";
          pageData.value.filter.minParticipant = "";
          pageData.value.filter.maxParticipant = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.event !== "") {
      filters.push({
        label: "EVENT",
        key: pageData.value.filter.event + Math.random(),
        value: pageData.value.filter.event,
        reset: () => {
          pageData.value.filter.event = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minAge !== "" ||
      pageData.value.filter.maxAge != ""
    ) {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.age = "";
          pageData.value.filter.minAge = "";
          pageData.value.filter.maxAge = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate != ""
    ) {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minTotalIncome !== "" ||
      pageData.value.filter.maxTotalIncome != ""
    ) {
      const displayValue =
        (pageData.value.filter.minTotalIncome != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minTotalIncome, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxTotalIncome != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxTotalIncome, 10),
              false,
              { useBrackets: true }
            )
          : "");

      filters.push({
        label: "FINANCIALS",
        key: pageData.value.filter.financial + Math.random(),
        value: displayValue,

        reset: () => {
          pageData.value.filter.financial = "";
          pageData.value.filter.minTotalIncome = "";
          pageData.value.filter.maxTotalIncome = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  const toggleSync = async (index: number) => {
    if (pageData.value.syncVisible == index) {
      pageData.value.syncVisible = -1;
    } else {
      pageData.value.syncVisible = index;
    }
  };

  // init data
  (async () => {
    pageData.value.arrFromAges = ApiHelper.getFromAgeOptions();
    pageData.value.arrToAges = ApiHelper.getToAgeOptions();
    pageData.value.arrStatuses = EventHelper.getEventStatusOptions();

    // set init value if set on url
    const queryObject = context.root.$route.query;
    const currentPage = parseInt(`${queryObject.page || 1}`);
    pageData.value.filter.event = queryObject.key || "";
    if (queryObject.order) {
      pageData.value.sort.order = queryObject.order;
    }
    if (queryObject.direction) {
      pageData.value.sort.direction = queryObject.direction;
    }
    pageData.value.filter.minParticipant = queryObject.minParticipant || "";
    pageData.value.filter.maxParticipant = queryObject.maxParticipant || "";
    pageData.value.filter.minTotalIncome = queryObject.minTotalIncome || "";
    pageData.value.filter.maxTotalIncome = queryObject.maxTotalIncome || "";
    pageData.value.filter.minAge = queryObject.minAge || "";
    pageData.value.filter.maxAge = queryObject.maxAge || "";
    pageData.value.filter.minDate = queryObject.minDate || "";
    pageData.value.filter.maxDate = queryObject.maxDate || "";

    loadData(currentPage, false);
  })();

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const updateEventStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    if (statusOption.value === 0 || statusOption.value === 2) {
      const actionName = statusOption.value === 0 ? "inactive" : "archive";
      const confirmMessage = `Are you sure you want to ${actionName} this event?`;
      const isConfirm = await Vue.swal({
        html: confirmMessage,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
    }

    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/events/" + item.id + "/status",
      {
        status: statusOption.value
      },
      {}
    );
    if (result.status === 1) {
      ApiHelper.setDataLoading(false);
      // if (statusOption.value === 2) {
      //   loadData(1);
      // } else if (callBack) {
      //   callBack();
      // }
      if (callBack) {
        callBack();
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    errors,
    pageData,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    getFiltersData,
    // sort
    updateSortValue,
    updateEventStatus,
    toggleSync
  };
}
