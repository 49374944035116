import { ref } from "@vue/composition-api";
import { AlertMessageError } from "@/components/VAlertMessage.vue";
import { PagerItem, SelectOption } from "@/types";
import moment from "moment";
import { MessagingListRows } from "@/helpers/estimateNoOfListRows";
import { ApiHelper } from "@/helpers";

export function useMessagingCampaignsStore(context: any) {
  const headerData = ref({
    title: "Campaigns",
    subTitle: "Messaging"
  });

  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: {
      id: number;
      isChecked: boolean;
      name: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {};
    showHeadActions: boolean;
    openItemId: number;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: MessagingListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {},
    items: [],
    showHeadActions: false,
    openItemId: 0,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "SettingsEventApplications",
        query: {
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadData = (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/campaigns",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take
        },
        "campaign"
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        const list = result.data.campaigns.map((item: any) => {
          return {
            id: parseInt(item.id),
            isChecked: false,
            campaign: item.name || "",
            params: item.params || "",
            recipients: item.sendTo || "",
            date: item.tsFormatted,
            rate: {
              current: item.totalSent || 0,
              total: item.total || 0,
              percent:
                ApiHelper.getPercent(item.totalSent || 0, item.total || 0) + "%"
            },
            status: item.status
          };
        });
        pageData.value.items = list;
      }
    })();
  };
  // pager
  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };
  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };
  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    // const filters = [];

    return {
      list: []
    };
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  // init data
  (async () => {
    loadData(1);
  })();

  return {
    updateRouters,
    // sort
    updateSortValue,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab
  };
}
