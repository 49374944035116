import { computed, ref } from "@vue/composition-api";
import moment from "moment";
import { ApiHelper } from "@/helpers";
import { ceil } from "lodash";
import { PagerItem } from "@/types";

type BarData = {
  isLoaded: boolean;
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      stack: string;
      data: number[];
    }[];
  };
  options: object;
};
export function useDashboardStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    tab: string;
    dashboard: any;
    totalRevenue: {
      total: number;
      percent: string;
    };
    yoYGrowth: {
      total: number;
      percent: string;
    };
    avgCostPerParticipant: {
      total: number;
      percent: string;
    };
    discountsGiven: {
      total: number;
      percent: string;
    };
    missedOpportunities: {
      total: number;
      percent: string;
    };
    totalMostOwedItems: number;
    mostOwedItems: {
      title: string;
      total: string;
    }[];
    delinquentItems: {
      title: string;
      total: string;
    }[];
    last12months: {
      month: string;
      year: string;
      label: string;
      total: number;
    }[];
    filter: {
      filterBy: string;
    };
    filterSelectVisible: boolean;
    topTags: object[];
    totalTransactions: number;

    // new data points
    viewOptions: any;
    controls: {
      summaryDropdown: {
        hideOptions: Function;
        toggleOptions: Function;
        selectedText: Function;
        value: number | string;
        optionsVisible: boolean;
        options: { id: number | string; text: string }[];
      };
    };
    activities: {
      skip: number;
      take: number;
      items: any[];
      sort: {
        order: string;
        direction: string;
      };
      // filter: {};
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    dashboardStats: {
      data24h: any;
    };
  }>({
    isLoading: false,
    tab: "plan",
    dashboard: {
      financial: {
        avgCost: {
          total: 0,
          percent: 0
        },
        discountGiven: {
          percent: 0,
          total: 0
        },
        yoyGrowth: {
          percent: 0,
          total: 0
        }
      }
    },
    totalRevenue: {
      total: 0,
      percent: "-"
    },
    yoYGrowth: {
      total: 0,
      percent: "-"
    },
    avgCostPerParticipant: {
      total: 0,
      percent: "-"
    },
    discountsGiven: {
      total: 0,
      percent: "-"
    },
    missedOpportunities: {
      total: 0,
      percent: "-"
    },
    totalMostOwedItems: 0,
    mostOwedItems: [],
    delinquentItems: [],
    last12months: [],
    filterSelectVisible: false,
    filter: {
      filterBy: "camp_view"
    },
    topTags: [],
    totalTransactions: 0,

    // new data points
    viewOptions: [],
    controls: {
      summaryDropdown: {
        hideOptions: () => {
          pageData.value.controls.summaryDropdown.optionsVisible = false;
        },
        toggleOptions: () => {
          pageData.value.controls.summaryDropdown.optionsVisible = !pageData
            .value.controls.summaryDropdown.optionsVisible;
        },
        selectedText: () => {
          const selectedId = pageData.value.controls.summaryDropdown.value || 0;
          const options = pageData.value.controls.summaryDropdown.options || [];
          const related = options.find(t => t.id == selectedId);
          if (related) {
            return related.text;
          }
          return "";
        },
        value: "YTD",
        optionsVisible: false,
        options: [
          {
            id: "90",
            text: "90 DAY"
          },
          {
            id: "YTD",
            text: "YEAR TO DATE"
          },
          {
            id: "365",
            text: "12 months"
          }
        ]
      }
    },
    activities: {
      skip: 0,
      take: 8,
      sort: {
        order: "1",
        direction: "2"
      },
      // filter: {},
      items: [],
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    dashboardStats: {
      data24h: {}
    }
  });
  const barData = ref<BarData>({
    isLoaded: false,
    data: {
      labels: [],
      datasets: []
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        intersect: false
      },
      plugins: {
        title: {
          display: false
        },
        tooltip: {
          display: false,
          mode: "index",
          intersect: false
        }
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            barPercentage: 0.1,
            ticks: {
              fontColor: "#b9b9b8",
              fontSize: 9,
              padding: 16,
              fontStyle: "bold"
            },
            gridLines: {
              lineWidth: 0,
              drawBorder: false,
              display: false
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  });

  const chartData = ref<{
    isLoaded: boolean;
    data: {
      labels: any[];
      datasets: {
        name: string;
        stack: string;
        type: string;
        backgroundColor: string;
        data: any[];
      }[];
    };
  }>({
    isLoaded: false,
    data: {
      datasets: [],
      labels: []
    }
  });

  const loadData = () => {
    // ApiHelper.setDataLoading(true);
    // barData.value.isLoaded = false;
    // (async () => {
    //   ApiHelper.callApi(
    //     "get",
    //     "/finances/dashboard",
    //     {},
    //     { callFrom: "FinancialDashboard" }
    //   ).then(response => {
    //     const last12months = response.data.chartData || [];
    //     chartData.value.data.labels = last12months.map((value: any) => {
    //       return value.label;
    //     });
    //     chartData.value.data.datasets.push({
    //       label: "New Charges",
    //       backgroundColor: "#000000",
    //       stack: "New Charges",
    //       type: "bar",
    //       data: last12months.map((value: any) => {
    //         return value.newChargeTotal;
    //       })
    //     });
    //     chartData.value.data.datasets.push({
    //       label: "Payments",
    //       backgroundColor: "#5e886d",
    //       stack: "Payments",
    //       type: "bar",
    //       data: last12months.map((value: any) => {
    //         return value.paymentTotal;
    //       })
    //     });
    //     chartData.value.data.datasets.push({
    //       label: "Balance",
    //       backgroundColor: "#000000",
    //       stack: "Balance",
    //       type: "line",
    //       data: last12months.map((value: any) => {
    //         return value.totalOwed;
    //       })
    //     });
    //     chartData.value.isLoaded = true;
    //     // load transactions
    //     pageData.value.transactions = (response.data.transactions || []).map(
    //       (item: any) => {
    //         item.transactionTsFormatted = moment(item.transactionTs).format(
    //           "MMM DD, YYYY"
    //         );
    //         item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
    //         item.transactionAmountFormatted = ApiHelper.dollarFormat(
    //           item.transactionAmount
    //         );
    //         return item;
    //       }
    //     );
    //     pageData.value.totalTransactions = response.data.totalTransactions || 0;
    //     // load payment plans
    //     pageData.value.paymentPlans = (response.data.plans || []).map(
    //       (item: any) => {
    //         item.amountFormatted = ApiHelper.dollarFormat(item.amount);
    //         item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
    //         item.transactionTSFormatted = moment(item.transactionTS).format(
    //           "MMM DD, YYYY"
    //         );
    //         return item;
    //       }
    //     );
    //     // load chart
    //     const item = response.data || {};
    //     const tsStart = new Date(item.tsStart);
    //     const tsEnd = new Date(item.tsEnd);
    //     const linkEventParticipants = item.linkEventParticipants || [];
    //     let pCurrent = 0;
    //     let pTotal = 0;
    //     let pPercent = 0;
    //     let eAges = "";
    //     if (linkEventParticipants.length > 0) {
    //       pCurrent = 0;
    //       pPercent = 0;
    //       pTotal = item.linkEventParticipants[0].capacity || 0;
    //       eAges = item.linkEventParticipants[0].age || "";
    //     }
    //     pageData.value.totalRevenue.total = item.financial?.totalPaid || 0;
    //     const totalIncomes: {
    //       transM: number;
    //       transY: number;
    //       totalM: string;
    //       eventTotalInc: number;
    //     }[] = item.totalIncomes.map((value: any) => {
    //       return {
    //         transM: value.transM || 0,
    //         transY: value.transY || 0,
    //         totalM: value.totalM || "",
    //         totalInc: value.totalInc || 0
    //       };
    //     });
    //     barData.value.data.labels = totalIncomes.map((value: any) => {
    //       return value.totalM;
    //     });
    //     barData.value.data.datasets.push({
    //       label: "Revenue",
    //       backgroundColor: "#5e886d",
    //       stack: "Stack 0",
    //       data: totalIncomes.map((value: any) => {
    //         return value.totalInc;
    //       })
    //     });
    //     barData.value.isLoaded = true;
    //     const dashboardData = {
    //       id: item.id,
    //       isChecked: false,
    //       name: item.name,
    //       participants: {
    //         current: pCurrent,
    //         total: pTotal,
    //         percent: pPercent
    //       },
    //       ages: eAges,
    //       date:
    //         tsStart.toLocaleDateString() + " - " + tsEnd.toLocaleDateString(),
    //       totalEventIncomes: totalIncomes,
    //       financial: item.financial
    //     };
    //     pageData.value.dashboard = dashboardData;
    //     ApiHelper.setDataLoading(false);
    //   });
    // })();
    // (async () => {
    //   const result = await ApiHelper.callApi(
    //     "get",
    //     "/balances/outstanding",
    //     {},
    //     {
    //       take: 5,
    //       skip: 0,
    //       order: 4,
    //       direction: 2
    //     }
    //   );
    //   if (result.status === 1) {
    //     pageData.value.mostOwedItems = result.data.items || [];
    //     pageData.value.totalMostOwedItems = result.data.totalCount || 0;
    //   }
    // })();
  };

  const toggleFilterSelect = () => {
    pageData.value.filterSelectVisible = !pageData.value.filterSelectVisible;
  };

  const closeFilterSelect = () => {
    pageData.value.filterSelectVisible = false;
  };

  const updateFilterView = (filterBy: string) => {
    pageData.value.filter.filterBy = filterBy;
    loadData();
  };

  const getFilterView = (): string => {
    let ret = "";
    if (pageData.value.filter.filterBy == "camp_view") {
      ret = "Camp view";
    } else if (pageData.value.filter.filterBy == "events_view") {
      ret = "My events view";
    } else if (!isNaN(parseInt(pageData.value.filter.filterBy))) {
      const tagName: any = pageData.value.topTags.find(
        (item: any) => item.id == parseInt(pageData.value.filter.filterBy)
      );
      if (tagName) {
        ret = tagName.name || "";
      }
    }
    return ret;
  };

  const getViews = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi("get", "/entities/views");
      if (result.status == 1) {
        pageData.value.viewOptions = result.data.views || [];

        // add more empty columns to fix design
        const total = pageData.value.viewOptions.length;
        const rows = ceil(total / 3);
        const more = rows * 3 - total;
        if (more > 0) {
          for (let i = 0; i < more; i++) {
            pageData.value.viewOptions.push({
              viewId: 0
            });
          }
        }
        pageData.value.viewOptions = pageData.value.viewOptions.map(
          (item: any) => ({
            ...item,
            show: false
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const loadView = async (viewId: number) => {
    if (viewId == 0) return;

    const inList = pageData.value.viewOptions.find(
      (item: any) => item.viewId == viewId
    );
    if (inList) {
      const savedView = JSON.parse(inList.params || "{}");
      if (savedView.name) {
        context.root.$router.push(savedView).catch((error: any) => {
          // do nothing
        });
      }
    }
  };

  const showViewItems = (take: number) => {
    let count = 0;
    for (const item of pageData.value.viewOptions) {
      if (item.show) continue;
      item.show = true;
      count = count + 1;
      if (count >= take) break;
    }
  };

  const totalHiddenViewOptions = computed(() => {
    const hiddens = pageData.value.viewOptions.filter(
      (item: any) => item.show == false && item.viewId > 0
    );
    return hiddens.length;
  });

  const selectedSummaryOptions = (selectedId: number | string) => {
    if (pageData.value.controls.summaryDropdown.value == selectedId) return;
    pageData.value.controls.summaryDropdown.value = selectedId;
    pageData.value.controls.summaryDropdown.hideOptions();
    chartData.value = {
      isLoaded: false,
      data: {
        datasets: [],
        labels: []
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getDashboardStats();
  };

  const getActivities = async (page: number) => {
    pageData.value.activities.pager.page = page || 1;
    pageData.value.activities.skip =
      (pageData.value.activities.pager.page - 1) *
      pageData.value.activities.take;
    const result = await ApiHelper.callApi(
      "get",
      "/timelines/activities",
      {},
      {
        skip: pageData.value.activities.skip,
        take: pageData.value.activities.take,
        order: 2,
        direction: 2
      }
    );
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.activities.pager.totalPages = Math.ceil(
        totalCount / pageData.value.activities.take
      );
      pageData.value.activities.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.activities.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.activities.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.activities.pager.items = pagerList;
      pageData.value.activities.items = result.data.timelines || [];
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }

    ApiHelper.setDataLoading(false);
  };

  const getDashboardStats = async () => {
    ApiHelper.setDataLoading(true);
    // chartData.value.data.datasets = [];
    const result = await ApiHelper.callApi(
      "get",
      "/entities/dashboardStats",
      {},
      {
        activityDays: pageData.value.controls.summaryDropdown.value
      }
    );
    if (result.status === 1) {
      pageData.value.dashboardStats = result.data || {};
      pageData.value.dashboardStats.data24h.revenueFormatted = ApiHelper.dollarFormat(
        pageData.value.dashboardStats.data24h.revenue || 0,
        false,
        {
          useBrackets: true
        }
      );
      // ChartData
      // Reset data chart
      const last12months = result.data.chartData || [];

      chartData.value.data.labels = last12months.map((value: any) => {
        return value.labelMY;
      });
      chartData.value.data.datasets.push({
        name: "Revenue",
        backgroundColor: "#5e886d",
        stack: "Revenue",
        type: "line",
        data: last12months.map((value: any) => {
          return parseFloat(value.totalRevenue.toFixed(2));
        })
      });
      chartData.value.data.datasets.push({
        name: "New Registrations",
        backgroundColor: "#000000",
        stack: "New Registrations",
        type: "line",
        data: last12months.map((value: any) => {
          return value.totalRegister * 100;
        })
      });
      chartData.value.isLoaded = true;
    }
    ApiHelper.setDataLoading(false);
  };

  const exportCSV = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const activities = pageData.value.activities.items || [];
      const columns = ["Full Name", "Activity Date", "Description"];

      const exportData = activities.map((item: any) => {
        const itemData = [];
        itemData.push(item.fullName || "");
        itemData.push(item.actionTsFormatted || "");
        itemData.push(item.logDescription || "");

        return itemData;
      });

      const headers: any = [];
      for (const column of columns) {
        headers.push(column);
      }

      const fileName = `activitive_${moment().format("YYYYMMDDHHmmSS")}`;
      await ApiHelper.generateCsv(exportData, headers, fileName);
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData();

    // get view options
    // remove favorites
    // await getViews();
    showViewItems(6);

    getActivities(1);
    getDashboardStats();
  })();

  return {
    pageData,
    chartData,
    exportCSV,
    barData,
    toggleFilterSelect,
    closeFilterSelect,
    updateFilterView,
    getFilterView,
    loadView,
    showViewItems,
    totalHiddenViewOptions,
    selectedSummaryOptions,
    getActivities
  };
}
