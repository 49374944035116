








































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import { useSettingsApplicationsEditStore } from "@/stores/Settings/Applications/SettingsApplicationsEditStore";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import draggable from "vuedraggable";
import ProfileDetails from "@/components/modules/ProfileDetails.vue";
import RoommateRequest from "@/components/modules/RoommateRequest.vue";
import CampStoreFund from "@/components/modules/CampStoreFund.vue";
import PopupPreviewApplication from "@/components/App/PopupPreviewApplication.vue";
import ParentGuardian from "@/components/modules/ParentGuardian.vue";
import AddOns from "@/components/modules/AddOns.vue";
import SignaturePad from "signature_pad";
import FormSelect from "@/components/Form/FormSelect.vue";
import { v4 as uuidv4 } from "uuid";
import Loading from "@/components/Common/Loading.vue";

declare const window: any;
require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

export default defineComponent({
  name: "SettingsApplicationsEditPage",
  components: {
    Modal,
    FormButton,
    AutoCompleteInput,
    VScroller,
    BackButton,
    draggable,
    // modules
    ProfileDetails,
    RoommateRequest,
    CampStoreFund,
    ParentGuardian,
    AddOns,
    PopupPreviewApplication,
    FormSelect,
    Loading
  },
  setup(props, context) {
    const {
      saveApp,
      onSaveModule,
      headerData,
      pageData,
      loadData,
      removeFieldError,
      modalLinkEvent,
      linkEvents,
      loadInApps,
      addEventPTypeRow,
      getPresetFields,
      isACILoading
    } = useSettingsApplicationsEditStore(context);
    const siderbar = ref<any[]>([]);
    const selectedAppId = parseInt(context.root.$route.params.appId) || 0;

    const renderModule = (module: any, usedElementsData: any = []) => {
      if ((module.moduleId || "") == "" || pageData.value.isRenderingForm) {
        return;
      }
      // update module jsonData
      pageData.value.modules = pageData.value.modules.map((module: any) => {
        if (module.formBuilder) {
          module.jsonData = module.formBuilder.actions.getData("json");
          if (typeof module.jsonData == "string") {
            module.jsonData = JSON.parse(module.jsonData);
          }
        }
        return module;
      });

      const moduleContainer = $(`#module-${module.moduleId}`);
      const moduleFormBuilder: any = moduleContainer.find(".formBuilder");

      if ((module.isSystem || false) == false) {
        // normal module
        if (moduleFormBuilder.length) {
          // reset
          moduleContainer.find("#newElements").html("");
          moduleContainer.find(".panel-fields .top-used-elements").html("");
          moduleContainer.find(".used-elements-data").html("");
          moduleFormBuilder.html("");
          moduleFormBuilder
            .parent()
            .find(".module-buttons")
            .remove();

          pageData.value.isRenderingForm = true;
          let presetFieldsData = [...pageData.value.presetFieldsData];

          // render used elements
          if (usedElementsData.length) {
            presetFieldsData = [...presetFieldsData, ...usedElementsData];
          }

          const fields = presetFieldsData.map((item: any) => {
            const field = JSON.parse(item.eAppFieldParams || "[]");
            // const fieldID = `preset-field-${item.eAppFieldId}`;
            // add more custom classes
            // if (typeof field.className != "undefined") {
            //   if (field.className.indexOf("my-cf") == -1) {
            //     field.className += " my-cf";
            //   }
            // } else {
            //   field.className = `form-control my-cf`;
            // }

            // add field id into className
            // if (field.className.indexOf(fieldID) == -1) {
            //   field.className += ` ${fieldID}`;
            // }

            // remove name
            field.name = "";

            // tmp info put to icon
            const tmpInfo = {
              fieldId: item.eAppFieldId,
              fieldInSearch: item.inSearch || false
            };
            field.icon = JSON.stringify(tmpInfo);

            return field;
          });

          fields.push({
            label: "Signature",
            attrs: {
              type: "signature"
            }
          });
          fields.push({
            label: "Birthdate",
            attrs: {
              type: "dob"
            }
          });

          const templates: any = {
            signature: (fieldData: any) => {
              return {
                field: `
                <div>
                  <div style="background-color: #eee; padding: 10px;">
                    <canvas id="${fieldData.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
                  </div>
                </div>`,
                onRender: function() {
                  const canvas: any = document.getElementById(fieldData.name);
                  const signaturePad = new SignaturePad(canvas, {
                    backgroundColor: "rgb(255, 255, 255)"
                  });
                }
              };
            },
            dob: (fieldData: any) => {
              const months = [];
              for (let i = 1; i < 13; i++) {
                months.push(i);
              }
              const days = [];
              for (let i = 1; i < 32; i++) {
                days.push(i);
              }
              const years = [];
              for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
                years.push(i);
              }
              return {
                field: `
                <div class="row form-group ${
                  fieldData.className
                } shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
                  <div class="col-lg-4 col-12 pl-0">
                    <select type="text" class="form-control px-3 text-center">
                      <option value="">Month</option>
                      ${months.map(value => {
                        return `<option value="${value}">${value}</option>`;
                      })}
                    </select>
                  </div>
                  <div class="col-lg-3 col-12 p-0">
                    <select class="form-control px-3 text-center input__full">
                      <option value="">Day</option>
                      ${days.map(value => {
                        return `<option value="${value}">${value}</option>`;
                      })}
                    </select></div>
                  <div class="col-lg-4 col-12 pr-0">
                    <select type="text" class="form-control px-3 text-center">
                      <option value="">Year</option>
                      ${years.map(value => {
                        return `<option value="${value}">${value}</option>`;
                      })}
                    </select>
                  </div>
                </div>`
              };
            }
          };

          module.formBuilder = moduleFormBuilder.formBuilder({
            fields,
            templates,
            formData: JSON.stringify(module.jsonData),
            onSave: async (evt: any, formData: any) => {
              module.jsonData = module.formBuilder.actions.getData("json");
              await onSaveModule(evt, formData, module);
              $(`#module-name-txt-${module.moduleId}`)
                .focus()
                .blur();
            },
            actionButtons: [
              {
                id: "copy",
                className: "btn btn-success btn-duplicate",
                label: "Duplicate",
                type: "button",
                events: {
                  click: function() {
                    /*action goes here*/
                  }
                }
              }
            ],
            disabledActionButtons: ["data"],
            disabledAttrs: [
              "access",
              "className",
              "value",
              "placeholder",
              "description",
              "name"
            ],
            disableFields: ["autocomplete", "button", "hidden", "number"],
            disabledSubtypes: {
              button: ["reset", "submit"],
              file: ["fineuploader"],
              paragraph: ["blockquote", "canvas", "output"],
              text: ["color", "tel"],
              textarea: ["tinymce", "quill"]
            },
            editOnAdd: true,
            onOpenFieldEdit: function(editPanel: any) {
              const elements = module.formBuilder.actions.getData();
              const totalElements = elements.filter((item: any) => item.type)
                .length;
              $("#moduleContainer.active .elementCount").text(
                totalElements + " Elements"
              );

              // sub label should after label
              const label = $(editPanel).find(".form-group.label-wrap");
              const subLabel = $(editPanel).find(".form-group.subLabel-wrap");
              if (label.length && subLabel.length) {
                subLabel.insertAfter(label);
              }
            },
            fieldRemoveWarn: true,
            replaceFields: [
              {
                type: "checkbox-group",
                label: "Checkbox",
                values: [{ label: "", value: "" }]
              },
              {
                type: "file",
                label: "Upload"
              },
              // {
              //   type: "paragraph",
              //   label: "Text Block"
              // },
              {
                type: "select",
                label: "Drop Down"
              }
            ],
            scrollToFieldOnAdd: true,
            sortableControls: true,
            stickyControls: {
              enable: true
            },
            typeUserDisabledAttrs: {
              button: ["style"],
              "checkbox-group": ["toggle"]
            },
            typeUserAttrs: {
              button: {
                className: {
                  label: "Width",
                  options: {
                    "col-3": "25%",
                    "col-4": "33%",
                    "col-6": "50%",
                    "col-8": "66%",
                    "col-9": "75%",
                    "col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              "checkbox-group": {
                className: {
                  label: "Width",
                  options: {
                    "custom-w-col-3": "25%",
                    "custom-w-col-4": "33%",
                    "custom-w-col-6": "50%",
                    "custom-w-col-8": "66%",
                    "custom-w-col-9": "75%",
                    "custom-w-col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              date: {
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                }
              },
              file: {
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                }
              },
              header: {
                className: {
                  label: "Width",
                  options: {
                    "custom-w-col-3 col-3": "25%",
                    "custom-w-col-4 col-4": "33%",
                    "custom-w-col-6 col-6": "50%",
                    "custom-w-col-8 col-8": "66%",
                    "custom-w-col-9 col-9": "75%",
                    "custom-w-col-12 col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              number: {
                className: {
                  label: "Width",
                  options: {
                    "custom-w-col-3 col-3": "25%",
                    "custom-w-col-4 col-4": "33%",
                    "custom-w-col-6 col-6": "50%",
                    "custom-w-col-8 col-8": "66%",
                    "custom-w-col-9 col-9": "75%",
                    "custom-w-col-12 col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              paragraph: {
                className: {
                  label: "Width",
                  options: {
                    "custom-w-col-3 col-3": "25%",
                    "custom-w-col-4 col-4": "33%",
                    "custom-w-col-6 col-6": "50%",
                    "custom-w-col-8 col-8": "66%",
                    "custom-w-col-9 col-9": "75%",
                    "custom-w-col-12 col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              select: {
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                }
              },
              text: {
                subLabel: {
                  label: "Sub Label",
                  value: "",
                  placeholder: ""
                },
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                },
                inputmask: {
                  label: "Mask",
                  options: {
                    none: "None",
                    zipcode: "Zip Code",
                    phonenumber: "Phone Number"
                    // date: "Date",
                    // email: "Email"
                  }
                }
              },
              textarea: {
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                }
              },
              "radio-group": {
                className: {
                  label: "Width",
                  options: {
                    "custom-w-col-3": "25%",
                    "custom-w-col-4": "33%",
                    "custom-w-col-6": "50%",
                    "custom-w-col-8": "66%",
                    "custom-w-col-9": "75%",
                    "custom-w-col-12": "100%",
                    "": "Auto"
                  }
                }
              },
              dob: {
                className: {
                  label: "Width",
                  options: {
                    "form-control custom-w-col-3 col-3": "25%",
                    "form-control custom-w-col-4 col-4": "33%",
                    "form-control custom-w-col-6 col-6": "50%",
                    "form-control custom-w-col-8 col-8": "66%",
                    "form-control custom-w-col-9 col-9": "75%",
                    "form-control custom-w-col-12 col-12": "100%",
                    "form-control": "Auto"
                  }
                }
              }
            }
          });

          module.formBuilder.promise.then(function() {
            moduleFormBuilder.on(
              "click touchstart",
              `.formbuilder-icon-copy`,
              function(evt: any) {
                evt.preventDefault();
                const elements = module.formBuilder.actions.getData();
                const totalElements = elements.filter((item: any) => item.type)
                  .length;
                $("#moduleContainer.active .elementCount").text(
                  totalElements + " Elements"
                );
              }
            );
            moduleFormBuilder
              .find(".frmb-control")
              .addClass("pl-0")
              .prependTo(moduleContainer.find("#newElements"));
            moduleFormBuilder
              .find(".form-actions.btn-group")
              .addClass("module-buttons")
              .appendTo(moduleFormBuilder.parent());
            moduleContainer
              .find("button.save-template")
              .html("Save Module")
              .addClass("save-module-btn");
            /* $(".app-buttons div").append(moduleContainer.find(".save-module-btn")); */
            moduleFormBuilder
              .find("button.save-template")
              .insertBefore(moduleFormBuilder.find("button.clear-all"));
            $("<div></div>")
              .append($("button.clear-all"))
              .append($("button.btn-duplicate"))
              .appendTo(moduleFormBuilder.find(".form-actions.btn-group"));

            // preset fields
            const formControls = moduleContainer.find(".frmb-control");
            moduleContainer
              .find(".panel-fields .top-used-elements")
              .append(formControls.clone(true));
            moduleContainer
              .find(".panel-fields .used-elements-data")
              .append(formControls.clone(true));
            // remove icons
            moduleContainer
              .find(
                ".panel-fields ul.frmb-control li[class*='formbuilder-icon']"
              )
              .remove();
            moduleContainer
              .find(".panel-fields ul.frmb-control li span:first-child")
              .addClass("my-cf-name");
            moduleContainer
              .find(".panel-fields ul.frmb-control > li")
              .each(function() {
                const controlIcon = $(this).find(".control-icon");
                const tmpInfo = JSON.parse(controlIcon.html());
                const fieldId = tmpInfo.fieldId || 0;
                $(this).attr("data-fieldid", fieldId);
                controlIcon.remove();

                if (tmpInfo.fieldInSearch || false) {
                  $(this).addClass("field-in-search");
                }

                // specify field type
                const type = $("<span class='my-cf-type'></span>");
                const typeStr = $(this).data("type");
                if (typeStr.indexOf("radio-group-") != -1) {
                  type.html("Radio Group");
                } else if (typeStr.indexOf("text-") != -1) {
                  type.html("Text");
                } else if (typeStr.indexOf("checkbox-group-") != -1) {
                  type.html("Checkbox");
                } else if (typeStr.indexOf("header-") != -1) {
                  type.html("Header");
                } else if (typeStr.indexOf("paragraph-") != -1) {
                  type.html("Text Block");
                } else if (typeStr.indexOf("select-") != -1) {
                  type.html("Select");
                }

                // init in apps
                const inApps = $("<span class='my-cf-in-apps'>0</span>");
                const theField: any = presetFieldsData.find(
                  (item: any) => item.eAppFieldId == fieldId
                );
                if (theField) {
                  inApps.html(theField.inApps || 0);
                }
                $(this)
                  .append(type)
                  .append(inApps);
              });

            // just show search result in used-elements-data
            $(
              ".used-elements-data .frmb-control li:not(.field-in-search)"
            ).remove();

            // hover on in apps numbers
            moduleContainer.find(".my-cf-in-apps").mouseover(function() {
              if (
                $(this).html() == "0" ||
                $(this).find(".in-apps-content").length
              ) {
                return;
              }
              clearTimeout(module.loadInAppsContentTimeout);
              module.loadInAppsContentTimeout = setTimeout(async () => {
                const li = $(this).closest("li");
                const fieldId = li.data("fieldid");
                const apps = await loadInApps(fieldId);
                const div = $("<div class='in-apps-content'></div>");
                for (const item of apps) {
                  div.append($(`<div>${item.entityAppName || ""}</div>`));
                }
                $(this).append(div);
                div.unbind("click").click(function(e) {
                  e.stopPropagation();
                });
              }, 300);
            });

            // apply style for custom input mask
            $(".formBuilder select[name=inputmask]").each(function() {
              const element = $(this).closest(".form-elements");
              const maxlength = element.find("input[name=maxlength]");
              const subType = element.find("select[name=subtype]");
              // default maxlength/subtype
              if ($(this).val() == "phonenumber") {
                maxlength.val(14).prop("disabled", true);
                subType.val("text").prop("disabled", true);
              } else if ($(this).val() == "zipcode") {
                maxlength.val(5).prop("disabled", true);
                subType.val("text").prop("disabled", true);
              }
            });

            // finish rendering
            pageData.value.isRenderingForm = false;
          });
        }
      } else {
        // system module
        // specify for component name
        const pathArray = (module.moduleVersionTemplatePath || "").split("/");
        pageData.value.currentSysModuleName = pathArray[
          pathArray.length - 1
        ].replace(".vue", "");
      }
    };

    const addModule = async (module: any = {}) => {
      // validate before add new item to sidebar
      if (
        pageData.value.selectedModuleId == 0 &&
        pageData.value.searchModuleName == "" &&
        module.moduleId == undefined
      ) {
        return;
      }

      let tmpModule: any = null;
      let tmpId = "";
      if (pageData.value.selectedModuleId > 0) {
        // add existed module
        tmpId = `${pageData.value.selectedModuleId}`;
        tmpModule = pageData.value.allModules.find(
          (item: any) => item.moduleId == pageData.value.selectedModuleId
        );

        tmpModule = {
          ...tmpModule,
          formBuilder: null,
          jsonData: JSON.parse(tmpModule.moduleVersionJson || "[]"),
          moduleSetting: {
            minRequirement: "",
            required: false
          },
          searchFieldTimeout: null,
          loadInAppsContentTimeout: null,
          lastUpdated: moment(tmpModule.moduleTs).format("MM/DD/YY")
        };

        // if add a campstore module, init cost center list
        if (
          pageData.value.showCostCenter &&
          tmpModule.isSystem == 1 &&
          tmpModule.moduleKey == "camp_store"
        ) {
          // get cost centers for camp store module
          const result = await ApiHelper.callApi(
            "get",
            "/entities/costcenters",
            {},
            {
              take: 50
            }
          );
          if (result.status === 1) {
            pageData.value.costcenter.options = result.data.costcenters.map(
              (item: any) => ({
                id: item.id,
                text: item.name
              })
            );
          }
        }
      } else if (module.moduleId > 0) {
        tmpId = module.moduleId;
        tmpModule = {
          ...module,
          formBuilder: null,
          jsonData: JSON.parse(module.moduleVersionJson || "[]"),
          moduleSetting: {
            minRequirement: "",
            required: false
          },
          searchFieldTimeout: null,
          loadInAppsContentTimeout: null,
          lastUpdated: moment(module.moduleTs).format("MM/DD/YY")
        };
      } else {
        // add new module
        tmpId = `new-${uuidv4()}`;
        tmpModule = {
          moduleId: tmpId,
          moduleName: pageData.value.searchModuleName,
          formBuilder: null,
          jsonData: [],
          moduleSetting: {
            minRequirement: "",
            required: false
          },
          searchFieldTimeout: null,
          loadInAppsContentTimeout: null,
          lastUpdated: moment().format("MM/DD/YY")
        };
      }

      // get app preset fields
      pageData.value.presetFieldsData = await getPresetFields({
        type: "app",
        getTopElements: 1,
        notInModuleId: parseInt(tmpId) > 0 ? parseInt(tmpId) : 0
      });

      tmpModule.canRemove = true;
      pageData.value.modules.push(tmpModule);
      pageData.value.activeModuleId = tmpId;
      await context.root.$forceUpdate();
      pageData.value.usedElementsKey = "";
      pageData.value.usedElementsData = [];
      renderModule(tmpModule);

      // scroll to bottom
      const moduleList: any = context.refs.moduleList;
      moduleList.$el.scrollTop = moduleList.$el.scrollHeight;
      // reset
      pageData.value.newModuleVisible = false;
      pageData.value.searchModuleName = "";
      pageData.value.selectedModuleId = 0;
    };

    const activeModule = async (module: any) => {
      if (
        pageData.value.activeModuleId == module.moduleId ||
        pageData.value.isRenderingForm
      ) {
        return;
      }

      // get top elements based on moduleId
      if (module.isSystem == 0) {
        pageData.value.presetFieldsData = await getPresetFields({
          type: "app",
          getTopElements: 1,
          notInModuleId: module.moduleId
        });
      }

      if (
        pageData.value.showCostCenter &&
        module.isSystem == 1 &&
        module.moduleKey == "camp_store"
      ) {
        // get cost centers for camp store module
        const result = await ApiHelper.callApi(
          "get",
          "/entities/costcenters",
          {},
          {
            take: 50
          }
        );
        if (result.status === 1) {
          pageData.value.costcenter.options = result.data.costcenters.map(
            (item: any) => ({
              id: item.id,
              text: item.name
            })
          );
        }
      }

      pageData.value.usedElementsKey = "";
      pageData.value.usedElementsData = [];
      pageData.value.activeModuleId = module.moduleId;
      await context.root.$forceUpdate();
      renderModule(module);
    };

    const nextModule = async () => {
      const leftPos = $("#moduleList").scrollLeft() || 0;
      const moduleContainer: any = $("#moduleContainer.active");
      const outerWidth = moduleContainer.outerWidth() / 2.5;

      if (
        $("#moduleContainer.active")
          .next()
          .is("div")
      ) {
        $("#moduleContainer.active")
          .next()
          .click();
        $("#moduleList").animate(
          {
            scrollLeft: leftPos + outerWidth
          },
          800
        );
      }
    };

    const prevModule = async () => {
      const leftPos = $("#moduleList").scrollLeft() || 0;
      const moduleContainer: any = $("#moduleContainer.active");
      const outerWidth = moduleContainer.outerWidth() / 2.5;
      if (
        $("#moduleContainer.active")
          .prev()
          .is("div")
      ) {
        $("#moduleContainer.active")
          .prev()
          .click();
        $("#moduleList").animate(
          {
            scrollLeft: leftPos - outerWidth
          },
          800
        );
      }
    };

    let searchModuleTimeout: any = null;
    const searchModule = () => {
      clearTimeout(searchModuleTimeout);
      searchModuleTimeout = setTimeout(() => {
        pageData.value.selectedModuleId = 0;
        $(".search-module-item").removeClass("hidden");
        if (pageData.value.searchModuleName == "") return;

        for (const module of pageData.value.allModules) {
          const item: any = $(`#search-module-${module.moduleId}`);
          if (
            !module.moduleName
              .toLowerCase()
              .includes(pageData.value.searchModuleName.toLowerCase())
          ) {
            item.addClass("hidden");
          }
        }
      }, 100);
    };

    const onClickChangerecycled = async () => {
      if (headerData.value.recycled) {
        headerData.value.recycled = 0;
      } else {
        headerData.value.recycled = 1;
      }
    };

    const showNewModuleModal = async () => {
      // get app modules list
      const result = await ApiHelper.callApi("get", "/appmodules");
      if (result.status == 1) {
        pageData.value.allModules = result.data.items || [];
      }
      pageData.value.allModules = pageData.value.allModules.filter(
        (item: any) => {
          const selected = pageData.value.modules.find(
            (m: any) => m.moduleId == item.moduleId
          );
          if (selected) {
            return false;
          }
          return true;
        }
      );
      pageData.value.allModules = pageData.value.allModules.map(
        (item: any) => ({
          ...item,
          moduleVersionParam: JSON.parse(item.moduleVersionParam || "{}")
        })
      );

      pageData.value.selectedModuleId = 0;
      pageData.value.searchModuleName = "";
      pageData.value.newModuleVisible = true;
    };

    const toggleSelectedModuleId = (item: any) => {
      if (pageData.value.selectedModuleId == item.moduleId) {
        pageData.value.selectedModuleId = 0;
      } else {
        pageData.value.selectedModuleId = item.moduleId;
      }
    };

    const disabledAddNewModule = () => {
      // disable if no select or enter a module name
      if (
        pageData.value.selectedModuleId == 0 &&
        pageData.value.searchModuleName == ""
      ) {
        return true;
      }

      // disable if existed in selected module on sidebar
      if (pageData.value.searchModuleName != "") {
        const inSelected = pageData.value.modules.find(
          (item: any) =>
            item.moduleName &&
            item.moduleName.toLowerCase() ==
              pageData.value.searchModuleName.toLowerCase()
        );
        if (inSelected) {
          return true;
        }
      }

      // disable if existed in selected module on list
      if (
        pageData.value.searchModuleName != "" &&
        pageData.value.selectedModuleId == 0
      ) {
        const inSelected = pageData.value.allModules.find(
          (item: any) =>
            item.moduleName &&
            item.moduleName.toLowerCase() ==
              pageData.value.searchModuleName.toLowerCase()
        );
        if (inSelected) {
          return true;
        }
      }

      return false;
    };

    const moduleNameInput = (module: any) => {
      if (module.moduleName != "") {
        module.errorMessage = "";
      } else {
        module.errorMessage = "Module name is required";
      }
    };

    const getTotalInApps = (module: any) => {
      if (typeof module.moduleVersionParam == "string") {
        const params = JSON.parse(module.moduleVersionParam);
        return params.totalInApps || 0;
      }
      return module.moduleVersionParam?.totalInApps || 0;
    };

    const getTotalElements = (module: any) => {
      return module.moduleVersionParam?.totalElements || 0;
    };

    const removeModule = async (module: any) => {
      const currModuleID = $("#moduleContainer.active").attr("data-id");
      const confirm = await context.root.$swal.fire({
        text: `Are you sure you want to remove this module?`,
        confirmButtonText: "Confirm",
        showCancelButton: true,
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      });

      if (!confirm.isConfirmed) {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return;
      }

      pageData.value.modules = pageData.value.modules.filter(
        (m: any) => m.moduleId != currModuleID
      );
      if (pageData.value.modules.length > 0) {
        pageData.value.activeModuleId = "";
        // select last module
        await activeModule(
          pageData.value.modules[pageData.value.modules.length - 1]
        );
      }
    };

    const onDragEnd = () => {
      pageData.value.modules = pageData.value.modules.map(
        (module: any, i: number) => ({
          ...module,
          displayOrder: i
        })
      );
      if (pageData.value.modules.length > 1) {
        context.root.$swal.fire({
          text: `Click Save Application to save modules display order`,
          timer: 5000
        });
      }

      // re-render the current active module
      const currentActiveModule = pageData.value.modules.find(
        (item: any) => item.moduleId == pageData.value.activeModuleId
      );
      if ((currentActiveModule.isSystem || false) == false) {
        pageData.value.activeModuleId = "-1";
        activeModule(currentActiveModule);
      }
    };

    const appNameBlur = () => {
      const paramAppId = parseInt(context.root.$route.params.appId) || 0;
      if (
        paramAppId == 0 &&
        pageData.value.modules.length == 0 &&
        headerData.value.title != ""
      ) {
        showNewModuleModal();
      }
    };

    const searchUsedElementsTimeout = ref<any>(null);
    const searchUsedElements = async () => {
      clearTimeout(searchUsedElementsTimeout.value);
      searchUsedElementsTimeout.value = setTimeout(async () => {
        if (pageData.value.usedElementsKey.length > 2) {
          pageData.value.usedElementsData = [];
          pageData.value.usedElementsData = await getPresetFields({
            type: "app",
            usedElementsKey: pageData.value.usedElementsKey
          });

          // render used elements
          if (pageData.value.usedElementsData.length) {
            pageData.value.usedElementsData = pageData.value.usedElementsData.map(
              (item: any) => ({
                ...item,
                inSearch: true
              })
            );
            const currentModule = pageData.value.modules.find(
              (item: any) => item.moduleId == pageData.value.activeModuleId
            );
            if (currentModule) {
              renderModule(currentModule, pageData.value.usedElementsData);
            }
          }
        } else if (pageData.value.usedElementsKey == "") {
          pageData.value.usedElementsData = [];
        }
      }, 300);
    };

    const applyCustomWidth = (renderedForm: any) => {
      renderedForm.find("> div").addClass("col-12");
      // apply custom width for each module
      renderedForm
        .find("[class*='custom-w-']")
        .each(function(i: number, obj: any) {
          const classList = obj.className.split(" ");
          const customW = classList.find(
            (item: any) => item.indexOf("custom-w-") != -1
          );
          const customWClass = customW ? customW.replace("custom-w-", "") : "";
          if (customWClass != "") {
            const type = $(obj).prop("type");
            let parentNode = null;
            if (type == "checkbox" || type == "radio") {
              parentNode = $(obj).closest(".form-group");
            } else {
              parentNode = $(obj).parent();
            }
            if (parentNode.length) {
              parentNode.addClass(customWClass);
              if (customWClass != "col-12") {
                parentNode.removeClass("col-12");
              }
              $(obj).removeClass(customWClass);
            }
          }
        });
    };

    const previewApplication = async () => {
      // update module jsonData
      pageData.value.modules = pageData.value.modules.map((module: any) => {
        if (module.formBuilder) {
          module.jsonData = module.formBuilder.actions.getData("json");
          if (typeof module.jsonData == "string") {
            module.jsonData = JSON.parse(module.jsonData);
          }
          // total elements
          const elements = module.formBuilder.actions.getData();
          module.totalElements = elements.length;
        }
        return module;
      });
      // apply sorting if not yet
      pageData.value.modules = pageData.value.modules.map(
        (module: any, i: number) => ({
          ...module,
          displayOrder: i
        })
      );

      const appmenu = pageData.value.modules.map((item: any, key: number) => {
        return {
          entityAppModuleId: key,
          moduleId: `${item.moduleId}`.includes("new") ? 0 : item.moduleId,
          stepName: item.moduleName,
          stepkeyword: item.moduleKey,
          appModuleJson: JSON.stringify(item.jsonData || []),
          isSystem: item.isSystem
        };
      });
      siderbar.value = appmenu.map((item: any) => {
        item.formRenderInstance = undefined;
        return item;
      });

      pageData.value.previewApplicationVisible = true;
    };

    // init data
    (async () => {
      addEventPTypeRow();
      await loadData();

      // defaut active first module
      if (pageData.value.modules.length) {
        activeModule(pageData.value.modules[0]);
      } else if (selectedAppId == 0) {
        // default attach profile details module when create new app
        const result = await ApiHelper.callApi(
          "get",
          "/appmodules",
          {},
          {
            moduleKey: "profile_details"
          }
        );
        if (result.status == 1) {
          const profileDetailsModule = result.data.items[0] || {};
          addModule(profileDetailsModule);
        }
      }
    })();

    return {
      siderbar,
      previewApplication,
      saveApp,
      onSaveModule,
      headerData,
      pageData,
      loadData,
      removeFieldError,
      modalLinkEvent,
      linkEvents,
      loadInApps,
      addModule,
      activeModule,
      searchModule,
      showNewModuleModal,
      onClickChangerecycled,
      toggleSelectedModuleId,
      disabledAddNewModule,
      moduleNameInput,
      getTotalInApps,
      getTotalElements,
      removeModule,
      onDragEnd,
      appNameBlur,
      searchUsedElements,
      stripTags: ApiHelper.stripTags,
      isACILoading,
      nextModule,
      prevModule
    };
  },
  async mounted() {
    async function checkCharcount(length: any, max: any, e: any) {
      if (e.which != 8 && length >= max) {
        e.preventDefault();
      }
    }

    $(document).on("paste keydown", '[contenteditable="true"]', function(e) {
      // console.log(parseInt($(this).html().length));
      let max = 999999999;
      if (
        $(this)
          .closest(".form-group")
          .next()
          .find("option:selected")
          .val() == "h1"
      ) {
        max = 200;
      }

      setTimeout(() => {
        if (e.which != 8 && parseInt(`${$(this).html().length}`) > max) {
          $(this).html(
            $(this)
              .html()
              .slice(0, max)
          );
        }
      }, 100);

      checkCharcount($(this).html().length, max, e);
    });

    $(document).on("click", ".ui-sortable-handle", function() {
      $("#moduleContainer.active .elementCount").html(
        $(".modulesContainer .form-wrap.form-builder li.form-field").length +
          " Elements"
      );
    });
    $(document).on("click", ".yes.btn", function() {
      $("#moduleContainer.active .elementCount").html(
        $(".modulesContainer .form-wrap.form-builder li.form-field").length -
          1 +
          " Elements"
      );
    });
    $(document).on("change", ".formBuilder select[name=inputmask]", function() {
      const element = $(this).closest(".form-elements");
      const maxlength = element.find("input[name=maxlength]");
      const subType = element.find("select[name=subtype]");
      // default maxlength/subtype
      if ($(this).val() == "phonenumber") {
        maxlength.val(14).prop("disabled", true);
        subType.val("text").prop("disabled", true);
      } else if ($(this).val() == "zipcode") {
        maxlength.val(5).prop("disabled", true);
        subType.val("text").prop("disabled", true);
      } else {
        maxlength.prop("disabled", false);
        subType.prop("disabled", false);
      }
    });

    // apply custom for formbuilder
    // auto set value for options of checkbox-group/select/radio if user let it empty
    $(document).on(
      "blur",
      ".form-builder li[type=checkbox-group] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );
    $(document).on(
      "blur",
      ".form-builder li[type=select] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );
    $(document).on(
      "blur",
      ".form-builder li[type=radio-group] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );
  }
});
