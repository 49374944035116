import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import moment from "moment";
import { MedicalIncidentType } from "@/helpers/ApiHelper";
import FormTags from "@/types/FormTags";
import { ProfileListRows } from "@/helpers/estimateNoOfListRows";
import FormSelect from "@/types/FormSelect";
import FormDate from "@/types/FormDate";

export function useEventMedicalStore(context: any) {
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const headerData = ref({
    title: "",
    subTitle: ""
  });
  const pageData = ref<{
    eventStatus: number;
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      profile: string;
      medication: string;
      dosage: SelectOption[];
      frequency: SelectOption[];
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    exportSymptomVisible: boolean;
    exportDate: string;

    exportDateError: string;
    exportToDate: string;
    exportToDateError: string;
    // symptom: {
    //   value: number[];
    //   options: SelectOption[];
    // };
    symptom: FormTags;
    endItemMessage: string;
    controls: {
      export: FormSelect;
      startDate: FormDate;
      endDate: FormDate;
    };
  }>({
    eventStatus: 1,
    isLoading: true,
    skip: 0,
    take: ProfileListRows(),
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "9",
      direction: context.root.$route.query.direction || "2"
    },
    filter: {
      profile: context.root.$route.query.profile || "",
      medication: context.root.$route.query.medication || "",
      dosage: [],
      frequency: []
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    exportSymptomVisible: false,
    exportDate: "",
    exportToDate: "",
    exportDateError: "",
    exportToDateError: "",
    // symptom: {
    //   value: [],
    //   options: []
    // }
    symptom: {
      error: "",
      label: "Symptoms",
      placeholder: "Select symptoms",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    },
    endItemMessage: "",
    controls: {
      export: {
        error: "",
        label: "",
        name: "export_type",
        placeholder: "Select export",
        type: "select",
        value: "",
        required: false,
        options: [
          {
            id: "Export Medications",
            text: "Medication View"
          },
          {
            id: "Export Symptoms",
            text: "Symptom View"
          },
          {
            id: "Export Medical Logs",
            text: "Log View"
          }
        ]
      },
      startDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "From Date Time",
        type: "date",
        value: "",
        style: "custom"
      },
      endDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "To Date Time",
        type: "date",
        value: "",
        style: "custom"
      }
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          profile: pageData.value.filter.profile || undefined,
          medication: pageData.value.filter.medication || undefined,
          dosage:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.dosage
            ) || undefined,
          frequency:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.frequency
            ) || undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals",
      {},
      {
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        skip: pageData.value.skip,
        take: pageData.value.take,
        eventId: selectedEventId,
        profile: pageData.value.filter.profile || "",
        key: pageData.value.filter.medication || "",
        route: context.root.$route.query.dosage || "",
        frequency: context.root.$route.query.frequency || "",
        ignoreMedicationStatus: "2"
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.items;
    } else if (result.status === 401) {
      ApiHelper.gotoPage(context, { name: "Login" });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const loadData = async (page: number) => {
    pageData.value.pager.page = page;
    await loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "medication":
        pageData.value.filter.medication = value;
        break;
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PARTICIPANT",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.medication !== "") {
      filters.push({
        label: "MEDICATION NAME",
        key: pageData.value.filter.medication + Math.random(),
        value: pageData.value.filter.medication,
        reset: () => {
          pageData.value.filter.medication = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedRoutes = pageData.value.filter.dosage
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedRoutes.length > 0) {
      filters.push({
        label: "DOSAGE",
        key: selectedRoutes.join("-") + Math.random(),
        value: selectedRoutes.join(", "),
        reset: () => {
          pageData.value.filter.dosage.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedFrequencies = pageData.value.filter.frequency
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedFrequencies.length > 0) {
      filters.push({
        label: "FREQUENCY",
        key: selectedFrequencies.join("-") + Math.random(),
        value: selectedFrequencies.join(", "),
        reset: () => {
          pageData.value.filter.frequency.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    return {
      list: filters
    };
  };

  const getEvent = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/events/" + selectedEventId + "/info",
      {},
      {}
    );
    if (result.status === 1) {
      const eventData = result.data || {};
      const eventId = Number.parseInt(eventData.id || "0") || 0;
      if (!eventId) {
        ApiHelper.showErrorMessage(
          "Event #" + selectedEventId + " is not found"
        );
        ApiHelper.gotoPage(context, { name: "Events" });
        return;
      }
      return eventData;
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.gotoPage(context, { name: "Events" });
      return;
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const exportSymptoms = async () => {
    // validate
    const fromDate: any = $(context.refs.fromDate);
    const toDate: any = $(context.refs.toDate);
    let fromDateBadInput = false;
    let toDateBadInput = false;
    if (fromDate.length) {
      fromDateBadInput = fromDate.get(0).validity?.badInput || false;
      if (fromDateBadInput) {
        pageData.value.exportDateError = "From Date is invalid";
      }
    }
    if (toDate.length) {
      toDateBadInput = toDate.get(0).validity?.badInput || false;
      if (toDateBadInput) {
        pageData.value.exportToDateError = "To Date is invalid";
      }
    }
    if (fromDateBadInput || toDateBadInput) {
      return;
    }
    if (
      pageData.value.exportDate &&
      pageData.value.exportToDate &&
      pageData.value.exportToDate < pageData.value.exportDate
    ) {
      pageData.value.exportToDateError = "To Date is invalid";
      return;
    }

    try {
      ApiHelper.setDataLoading(true);
      // let symptomVal: any = [...pageData.value.symptom.value];
      // if (symptomVal.includes("3")) {
      //   // if select sore throat, make sure include cough, and fever
      //   symptomVal = ["1", "2", "3"];
      // }

      const result = await ApiHelper.callApi(
        "get",
        "/medicals",
        {},
        {
          getAll: 1,
          type: 2,
          exportDate: pageData.value.exportDate,
          exportToDate: pageData.value.exportToDate,
          incidentType: MedicalIncidentType.MedicalIncident,
          symptom: pageData.value.symptom.value.map(t => t.id).join(","),
          eventIds: selectedEventId,
          order: 7,
          direction: 1
        }
      );

      if (result.status === 1) {
        const resultData = result.data || {};
        const medicalLogs = resultData.items || [];
        const exportData = medicalLogs.map((item: any) => {
          return [
            ApiHelper.getFullName(item.firstName, item.lastName),
            item.notes || "",
            ApiHelper.getSymptomTexts(item.symptomsList || []),
            item.adminFullName,
            item.startDateFormatted
          ];
        });
        const headers = [
          "PARTICIPANT",
          "SUMMARY",
          "SYMPTOM",
          "CREATED BY",
          "DATE & TIME"
        ];
        const fileName = [`event${selectedEventId}-symptoms`];
        if (pageData.value.exportDate) {
          fileName.push(pageData.value.exportDate.replace(/-/g, ""));
        }
        if (pageData.value.exportToDate) {
          fileName.push(pageData.value.exportToDate.replace(/-/g, ""));
        }
        fileName.push(moment().format("MMDDYYYY_HHMMSS"));
        await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));

        pageData.value.exportSymptomVisible = false;
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const suggestSymptom = async (key: string) => {
    pageData.value.endItemMessage = "";
    const selectedIds = pageData.value.symptom.value.map(item => item.id);
    const take = 25;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals/symptoms",
      {},
      {
        symptomName: key,
        notInIds: selectedIds.join(","),
        take
      }
    );
    if (result.status === 1) {
      pageData.value.symptom.suggestTags = result.data.items.map(
        (item: any) => {
          return {
            id: item.symptomId,
            text: item.symptomName,
            data: item
          };
        }
      );
      if ((result.data.totalCount || 0) > take) {
        pageData.value.endItemMessage = `Top ${take} symptoms are listed, search to see more symptoms`;
      }
    }
  };

  const addSymptom = async (symptomName: string) => {
    if (symptomName == "") return;
    const result = await ApiHelper.callApi("post", "/medicals/symptoms", {
      symptomName
    });
    if (result.status == 1) {
      pageData.value.symptom.value.push({
        id: result.data.symptomId,
        text: symptomName
      });
      pageData.value.symptom.key = "";
      context.refs.symptomList.show = false;
    }
  };

  const removeLastSymptom = (event: any) => {
    //
  };

  const removeSymptom = (index: number) => {
    if (pageData.value.symptom.value.length > index) {
      pageData.value.symptom.value.splice(index, 1);
      context.refs.symptomList.show = false;
    }
  };

  const selectSymptom = (item: any) => {
    pageData.value.symptom.key = "";
    pageData.value.symptom.suggestTags = [];
    pageData.value.symptom.value.push({
      id: item.id,
      text: item.text
    });
  };

  const exportMedications = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/medicals",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          eventIds: selectedEventId,
          ignoreMedicationStatus: "2",
          sortBy: "firstName"
        }
      );

      if (result.status === 1) {
        const resultData = result.data || {};
        const exportData = (resultData.items || []).map((item: any) => {
          return [
            ApiHelper.getFullName(item.firstName, item.lastName),
            item.groupName || "",
            item.medicalName,
            item.eventName || "",
            item.dosage,
            item.frequency
          ];
        });
        const headers = [
          "PARTICIPANT",
          "CABIN",
          "MEDICATION NAME",
          "EVENT NAME",
          "ROUTE",
          "FREQUENCY"
        ];
        const fileName = [`event${selectedEventId}-medications`];
        fileName.push(moment().format("MMDDYYYY_HHMMSS"));
        await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));
        ApiHelper.setDataLoading(false);
        pageData.value.exportSymptomVisible = false;
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  // Function export csv MedicalLogs
  const csvMedicalLogs = async (result: any) => {
    // CSV for export_medical_logs
    const resultData = result.data || {};
    const medicalLogs = resultData.items || [];
    const exportData = medicalLogs.map((item: any) => {
      return [
        ApiHelper.getFullName(item.firstName, item.lastName),
        item.notes || "",
        ApiHelper.getSymptomTexts(item.symptomsList || []),
        item.eventName || "",
        item.startDateFormatted
      ];
    });
    const headers = [
      "PARTICIPANT",
      "SUMMARY",
      "SYMPTOM",
      "EVENT NAME",
      "DATE & TIME"
    ];
    const fileName = [`event${selectedEventId}-medical-logs`];
    fileName.push(moment().format("MMDDYYYY_HHMMSS"));
    await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));
    ApiHelper.setDataLoading(false);
  };

  // functiion export csv Symtoms
  const csvSymtoms = async (result: any) => {
    const resultData = result.data || {};
    const exportData = (resultData.items || []).map((item: any) => {
      return [
        ApiHelper.getFullName(item.firstName, item.lastName),
        item.notes || "",
        ApiHelper.getSymptomTexts(item.symptomsList || []),
        item.eventName || "",
        item.adminFullName,
        item.startDateFormatted
      ];
    });
    const headers = [
      "PARTICIPANT NAME",
      "SUMMARY",
      "SYMPTOM",
      "EVENT NAME",
      "CREATED BY",
      "DATE & TIME"
    ];
    const fileName = [`event${selectedEventId}-symptoms`];
    fileName.push(moment().format("MMDDYYYY_HHMMSS"));
    await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));
    ApiHelper.setDataLoading(false);
  };

  // function export csv medications
  const csvMedications = async (result: any) => {
    const resultData = result.data || {};
    const medicalLogs = resultData.items || [];
    const exportData = medicalLogs.map((item: any) => {
      return [
        ApiHelper.getFullName(item.firstName, item.lastName),
        item.eventName || "",
        item.groupName || "",
        item.medicalName,
        // item.dosage,
        // item.frequency
        item.strength || "",
        item.dispenseName || "",
        item.dosageNames || "",
        item.medicationStartFormatted || "",
        item.medicationEndFormatted || "",
        item.prescribingDoctor || "",
        item.specialInstructions || ""
      ];
    });
    const headers = [
      "PARTICIPANT NAME",
      "EVENT NAME",
      "CABIN",
      "MEDICATION NAME",
      // "ROUTE",
      // "FREQUENCY"
      "STRENGTH",
      "DISPENSE METHOD",
      "DOSAGES",
      "START",
      "END",
      "PRESCRIBING DOCTOR",
      "SPECIAL INSTRUCTIONS"
    ];
    const fileName = [`event${selectedEventId}-medications`];
    if (pageData.value.controls.startDate.value) {
      fileName.push(
        moment(pageData.value.controls.startDate.value).format("MMDDYYYY")
      );
    }
    if (pageData.value.controls.endDate.value) {
      fileName.push(
        moment(pageData.value.controls.endDate.value).format("MMDDYYYY")
      );
    }
    fileName.push(moment().format("MMDDYYYY_HHMMSS"));
    await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));
  };

  const exportByType = async () => {
    // validate
    const fromDate: any = $(context.refs.fromDate);
    const toDate: any = $(context.refs.toDate);
    let fromDateBadInput = false;
    let toDateBadInput = false;
    if (fromDate.length) {
      fromDateBadInput = fromDate.get(0).validity?.badInput || false;
      if (fromDateBadInput) {
        pageData.value.controls.startDate.error = "From Date is invalid";
      }
    }
    if (toDate.length) {
      toDateBadInput = toDate.get(0).validity?.badInput || false;
      if (toDateBadInput) {
        pageData.value.controls.endDate.error = "To Date is invalid";
      }
    }
    if (fromDateBadInput || toDateBadInput) {
      return;
    }

    if (
      pageData.value.controls.startDate.value &&
      pageData.value.controls.endDate.value &&
      pageData.value.controls.endDate.value <
        pageData.value.controls.startDate.value
    ) {
      pageData.value.controls.endDate.error = "To Date is invalid";
      return;
    }

    const start = moment(pageData.value.controls.startDate.value);
    const end = moment(pageData.value.controls.endDate.value);
    const startDateFormat = start.isValid() ? start.format("YYYY-MM-DD") : "";
    const endDateFormat = end.isValid() ? end.format("YYYY-MM-DD") : "";

    try {
      ApiHelper.setDataLoading(true);
      let params;
      // Set par For export_symptoms, export_medications
      if (pageData.value.controls.export.value == "Export Medications") {
        params = {
          getAll: 1,
          order: 1,
          direction: 1,
          eventIds: selectedEventId,
          ignoreMedicationStatus: "2",
          exportDate: startDateFormat,
          exportToDate: endDateFormat,
          sortBy: "firstName"
        };
      } else if (pageData.value.controls.export.value == "Export Symptoms") {
        params = {
          getAll: 1,
          type: 2,
          exportDate: startDateFormat,
          exportToDate: endDateFormat,
          incidentType: MedicalIncidentType.MedicalIncident,
          symptom: pageData.value.symptom.value.map(t => t.id).join(","),
          eventIds: selectedEventId,
          order: 7,
          direction: 1,
          sortBy: "firstName"
        };
      } else {
        // log medical
        params = {
          getAll: 1,
          order: 9,
          exportDate: startDateFormat,
          exportToDate: endDateFormat,
          type: 2,
          eventIds: selectedEventId,
          sortBy: "firstName"
        };
      }

      const result = await ApiHelper.callApi("get", "/medicals", {}, params);
      if (result.status === 1) {
        // CSV for export_symptoms
        if (pageData.value.controls.export.value === "Export Symptoms") {
          csvSymtoms(result);
        } else if (
          pageData.value.controls.export.value == "Export Medications"
        ) {
          // CSV for export_medications
          csvMedications(result);
        } else {
          // CSV for export_medical_logs
          csvMedicalLogs(result);
        }
        pageData.value.exportSymptomVisible = false;
        // Reset select box
        pageData.value.controls.export.value = "";
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    await loadData(1);
  })();
  (async () => {
    const event = await getEvent();
    pageData.value.eventStatus = event.status || 0;
    headerData.value.title = event.name;
  })();
  (async () => {
    pageData.value.filter.dosage = ApiHelper.getMedicationRouteOptions();
    const routeIds = (context.root.$route.query.dosage || "").split(",");
    pageData.value.filter.dosage.forEach((item: any) => {
      if (routeIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    pageData.value.filter.frequency = ApiHelper.getFrequencyOptions();
    const frequencyIds = (context.root.$route.query.frequency || "").split(",");
    pageData.value.filter.frequency.forEach((item: any) => {
      if (frequencyIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });
  })();

  return {
    // sort
    updateRouters,
    updateSortValue,
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    exportSymptoms,
    suggestSymptom,
    addSymptom,
    removeLastSymptom,
    removeSymptom,
    selectSymptom,
    exportMedications,
    exportByType
  };
}
